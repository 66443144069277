@if (thumbnailUrl) {
	<tui-avatar
		class="content-thumbnail__avatar"
		size="m"
		[round]="false"
		[src]="thumbnailUrl | tuiFallbackSrc: icon | async"
	/>
} @else {
	<tui-avatar
		class="content-thumbnail__avatar"
		size="m"
		[round]="false"
		[src]="icon"
	/>
}
