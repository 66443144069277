import { AsyncPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Pipe, INJECTOR, Injector } from '@angular/core';
import { __decorate } from 'tslib';
import { NgControl, ControlContainer } from '@angular/forms';
import { TuiValidationError } from '@taiga-ui/cdk/classes';
import { tuiIsString, tuiPure } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit/tokens';
import { map, of, Observable } from 'rxjs';
const EMPTY_RECORD = {};
function unwrapObservable(content, context) {
  return content.pipe(map(error => new TuiValidationError(error || '', context)));
}
function defaultError(content, context) {
  return of(new TuiValidationError(content || '', context));
}
class TuiFieldErrorPipe {
  constructor() {
    this.order = [];
    this.parent = inject(NgControl, {
      skipSelf: true,
      optional: true
    });
    this.self = inject(NgControl, {
      self: true,
      optional: true
    });
    this.container = inject(ControlContainer, {
      optional: true
    });
    this.validationErrors = inject(TUI_VALIDATION_ERRORS);
    if (this.self && !this.self.valueAccessor) {
      this.self.valueAccessor = this;
    }
  }
  transform(order) {
    this.order = order;
    return this.computedError;
  }
  registerOnChange() {}
  registerOnTouched() {}
  setDisabledState() {}
  writeValue() {}
  get computedError() {
    return this.invalid && this.touched && this.error || of(null);
  }
  get error() {
    const {
      errorId
    } = this;
    if (!errorId) {
      return null;
    }
    const firstError = this.controlErrors[errorId];
    const errorContent = this.validationErrors[errorId];
    return this.getError(firstError, errorContent);
  }
  get invalid() {
    return !!this.control?.invalid;
  }
  get touched() {
    return !!this.control?.touched;
  }
  get control() {
    return this.self?.control || this.parent?.control || this.container?.control;
  }
  get errorId() {
    return this.getErrorId(this.order, this.controlErrors);
  }
  get controlErrors() {
    return this.control?.errors || EMPTY_RECORD;
  }
  getError(context, content) {
    if (context instanceof TuiValidationError) {
      return of(context);
    }
    if (content === undefined && tuiIsString(context)) {
      return of(new TuiValidationError(context));
    }
    if (content instanceof Observable) {
      return unwrapObservable(content, context);
    }
    if (content instanceof Function) {
      const message = content(context);
      return message instanceof Observable ? unwrapObservable(message, context) : defaultError(message, context);
    }
    return defaultError(content, context);
  }
  getErrorId(order, controlErrors) {
    const id = order?.find(errorId => controlErrors[errorId]);
    const fallback = Object.keys(controlErrors)[0];
    return id || fallback || '';
  }
  static {
    this.ɵfac = function TuiFieldErrorPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFieldErrorPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiFieldError",
      type: TuiFieldErrorPipe,
      pure: false,
      standalone: true
    });
  }
}
__decorate([tuiPure], TuiFieldErrorPipe.prototype, "getError", null);
__decorate([tuiPure], TuiFieldErrorPipe.prototype, "getErrorId", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFieldErrorPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiFieldError',
      pure: false
    }]
  }], function () {
    return [];
  }, {
    getError: [],
    getErrorId: []
  });
})();
class TuiFieldErrorContentPipe {
  constructor() {
    this.injector = inject(INJECTOR);
    this.localInjector = Injector.create({
      providers: [{
        provide: AsyncPipe
      }, {
        provide: TuiFieldErrorPipe
      }],
      parent: this.injector
    });
    this.asyncPipe = this.localInjector.get(AsyncPipe);
    this.fieldErrorPipe = this.localInjector.get(TuiFieldErrorPipe);
  }
  transform(order) {
    return this.getErrorContent(order);
  }
  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
  getErrorContent(order) {
    const error = this.asyncPipe.transform(this.fieldErrorPipe.transform(order));
    if (!error) {
      return '';
    }
    return typeof error.message === 'function' ? error.message(error.context) : error.message;
  }
  static {
    this.ɵfac = function TuiFieldErrorContentPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFieldErrorContentPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiFieldErrorContent",
      type: TuiFieldErrorContentPipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFieldErrorContentPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiFieldErrorContent',
      pure: false
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFieldErrorContentPipe, TuiFieldErrorPipe };
