import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlgoliaUser } from '@context/shared/types/search';
import { getInitials } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiFallbackSrcPipe,
	TuiIcon,
	TuiIconPipe,
	TuiTitle,
} from '@taiga-ui/core';
import { TuiAvatar, TuiAvatarOutline } from '@taiga-ui/kit';

@Component({
	standalone: true,
	imports: [
		TuiAvatar,
		TuiAvatarOutline,
		TuiIcon,
		TuiFallbackSrcPipe,
		AsyncPipe,
		TuiIconPipe,
		TuiTitle,
		TuiAvatarOutline,
		TranslocoModule,
	],
	selector: 'ctx-algolia-avatar',
	templateUrl: 'algolia-avatar.component.html',
	styleUrl: 'algolia-avatar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlgoliaAvatarComponent {
	@Input() user: AlgoliaUser | null = null;
	icon = '@tui.user';

	get src() {
		if (!this.user) return null;
		if (this.user.thumbnail) return this.user.thumbnail;
		return this.initials;
	}

	get initials() {
		if (!this.user) return '';
		return getInitials(this.user.name) ?? '';
	}
}
