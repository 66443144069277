<tui-data-list>
	<tui-opt-group>
		@for (item of menuItems; track item.text) {
			<button
				tuiOption
				[disabled]="item.disabled ?? false"
				[iconStart]="item.iconStart"
				(click)="item.handler()"
			>
				{{ item.text | transloco }}
			</button>
		}
	</tui-opt-group>
</tui-data-list>
