import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { tuiIsString } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_BUTTON_OPTIONS } from '@taiga-ui/core/components/button';
import { TuiLoader } from '@taiga-ui/core/components/loader';
import { tuiSizeBigger } from '@taiga-ui/core/utils/miscellaneous';
const _c0 = ["tuiButton", "", "loading", ""];
const _c1 = ["*"];
class TuiButtonLoading {
  constructor() {
    this.options = inject(TUI_BUTTON_OPTIONS);
    this.size = this.options.size;
    this.loading = false;
  }
  get loaderSize() {
    return tuiSizeBigger(this.size) ? 'm' : 's';
  }
  get label() {
    return tuiIsString(this.loading) ? this.loading : '';
  }
  onClick(event) {
    if (this.loading) {
      event.stopPropagation();
    }
  }
  static {
    this.ɵfac = function TuiButtonLoading_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiButtonLoading)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiButtonLoading,
      selectors: [["", "tuiButton", "", "loading", ""], ["", "tuiIconButton", "", "loading", ""]],
      hostVars: 3,
      hostBindings: function TuiButtonLoading_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click.capture", function TuiButtonLoading_click_capture_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-disabled", ctx.loading);
          i0.ɵɵclassProp("_loading", ctx.loading);
        }
      },
      inputs: {
        size: "size",
        loading: "loading"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 2,
      vars: 4,
      consts: [["aria-live", "polite", "role", "status", 1, "t-loader", 3, "inheritColor", "showLoader", "size", "textContent"]],
      template: function TuiButtonLoading_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵelement(1, "tui-loader", 0);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("inheritColor", true)("showLoader", !!ctx.loading)("size", ctx.loaderSize)("textContent", ctx.label);
        }
      },
      dependencies: [TuiLoader],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiButtonLoading, [{
    type: Component,
    args: [{
      standalone: true,
      selector: '[tuiButton][loading],[tuiIconButton][loading]',
      imports: [TuiLoader],
      template: `
        <ng-content />
        <tui-loader
            aria-live="polite"
            role="status"
            class="t-loader"
            [inheritColor]="true"
            [showLoader]="!!loading"
            [size]="loaderSize"
            [textContent]="label"
        />
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.aria-disabled]': 'loading',
        '[class._loading]': 'loading',
        '(click.capture)': 'onClick($event)'
      }
    }]
  }], null, {
    size: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiButtonLoading };
