import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TUI_DEFAULT_IDENTITY_MATCHER } from '@taiga-ui/cdk/constants';
import { tuiTypedFromEvent } from '@taiga-ui/cdk/observables';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { tuiIsPresent } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_DATA_LIST_HOST, TuiOption, TuiDataListComponent } from '@taiga-ui/core/components/data-list';
import { TUI_COMMON_ICONS } from '@taiga-ui/core/tokens';
import { AbstractTuiControl } from '@taiga-ui/legacy/classes';
import { injectContext, PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { Subject, merge, EMPTY, startWith, map, distinctUntilChanged } from 'rxjs';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@taiga-ui/core/components/scrollbar';
import { TuiScrollIntoView } from '@taiga-ui/core/components/scrollbar';
import * as i3 from '@taiga-ui/core/components/icon';
import { TuiIcon } from '@taiga-ui/core/components/icon';
function TuiSelectOptionComponent_tui_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-icon", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r0.icons.check)("tuiScrollIntoView", true);
  }
}
function TuiSelectOptionComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 4);
  }
}
class TuiSelectOptionComponent {
  constructor() {
    this.host = inject(TUI_DATA_LIST_HOST);
    this.el = tuiInjectElement();
    this.changeDetection$ = new Subject();
    this.abstractControl = inject(AbstractTuiControl, {
      optional: true
    });
    this.control = inject(NgControl);
    this.option = inject(TuiOption);
    this.dataList = inject(TuiDataListComponent, {
      optional: true
    });
    this.icons = inject(TUI_COMMON_ICONS);
    this.context = injectContext();
    this.selected$ = merge(this.changeDetection$, this.control.valueChanges || EMPTY, tuiTypedFromEvent(this.el, 'animationstart')).pipe(startWith(null), map(() => this.selected), distinctUntilChanged());
  }
  ngOnInit() {
    /**
     * This would cause changes inside already checked parent component (during the same change detection cycle),
     * and it might cause ExpressionChanged error due to potential HostBinding
     * (for example, inside {@link https://github.com/angular/angular/blob/main/packages/forms/src/directives/ng_control_status.ts#L99 NgControlStatus}).
     * Microtask keeps it in the same frame but allows change detection to run.
     */
    void Promise.resolve().then(() => {
      if (tuiIsPresent(this.option.value) && !this.option.disabled) {
        this.host.checkOption?.(this.option.value);
      }
    });
  }
  ngDoCheck() {
    this.changeDetection$.next();
  }
  get value() {
    return this.abstractControl?.value ?? this.control.value;
  }
  get matcher() {
    return this.host.identityMatcher || TUI_DEFAULT_IDENTITY_MATCHER;
  }
  get selected() {
    return tuiIsPresent(this.option.value) && tuiIsPresent(this.value) && this.matcher(this.value, this.option.value);
  }
  static {
    this.ɵfac = function TuiSelectOptionComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSelectOptionComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiSelectOptionComponent,
      selectors: [["tui-select-option"]],
      decls: 5,
      vars: 5,
      consts: [["dummy", ""], [3, "ngTemplateOutlet"], ["automation-id", "tui-select-option__checkmark", "class", "t-checkmark", 3, "icon", "tuiScrollIntoView", 4, "ngIf", "ngIfElse"], ["automation-id", "tui-select-option__checkmark", 1, "t-checkmark", 3, "icon", "tuiScrollIntoView"], [1, "t-dummy"]],
      template: function TuiSelectOptionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainer(0, 1);
          i0.ɵɵtemplate(1, TuiSelectOptionComponent_tui_icon_1_Template, 1, 2, "tui-icon", 2);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, TuiSelectOptionComponent_ng_template_3_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const dummy_r2 = i0.ɵɵreference(4);
          i0.ɵɵproperty("ngTemplateOutlet", ctx.context.$implicit);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx.selected$))("ngIfElse", dummy_r2);
        }
      },
      dependencies: [i1.NgIf, i1.NgTemplateOutlet, i2.TuiScrollIntoView, i3.TuiIcon, i1.AsyncPipe],
      styles: ["@keyframes _ngcontent-%COMP%_retrigger{0%{left:1px}to{left:2px}}[_nghost-%COMP%]{display:flex;flex:1;align-items:center;max-inline-size:100%;animation:_ngcontent-%COMP%_retrigger 1s}.t-checkmark[_ngcontent-%COMP%]{margin-inline-start:auto;inline-size:1.75rem;border-width:.25rem;border-inline-start-width:.5rem}tui-data-list[data-size=\"s\"][_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%], tui-data-list[data-size=\"s\"]   [_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%]{margin-inline-end:-.375rem}tui-data-list[data-size=\"m\"][_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%], tui-data-list[data-size=\"m\"]   [_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%]{margin-inline-end:-.125rem}.t-dummy[_ngcontent-%COMP%]{inline-size:1.75rem}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectOptionComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container [ngTemplateOutlet]=\"context.$implicit\" />\n<tui-icon\n    *ngIf=\"selected$ | async; else dummy\"\n    automation-id=\"tui-select-option__checkmark\"\n    class=\"t-checkmark\"\n    [icon]=\"icons.check\"\n    [tuiScrollIntoView]=\"true\"\n/>\n<ng-template #dummy>\n    <span class=\"t-dummy\"></span>\n</ng-template>\n",
      styles: ["@keyframes retrigger{0%{left:1px}to{left:2px}}:host{display:flex;flex:1;align-items:center;max-inline-size:100%;animation:retrigger 1s}.t-checkmark{margin-inline-start:auto;inline-size:1.75rem;border-width:.25rem;border-inline-start-width:.5rem}:host-context(tui-data-list[data-size=\"s\"]) .t-checkmark{margin-inline-end:-.375rem}:host-context(tui-data-list[data-size=\"m\"]) .t-checkmark{margin-inline-end:-.125rem}.t-dummy{inline-size:1.75rem}\n"]
    }]
  }], null, null);
})();
const TUI_SELECT_OPTION = new PolymorpheusComponent(TuiSelectOptionComponent);
class TuiSelectOptionModule {
  static {
    this.ɵfac = function TuiSelectOptionModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSelectOptionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiSelectOptionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, TuiIcon]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectOptionModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiScrollIntoView, TuiIcon],
      declarations: [TuiSelectOptionComponent],
      exports: [TuiSelectOptionComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_SELECT_OPTION, TuiSelectOptionComponent, TuiSelectOptionModule };
