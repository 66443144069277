import { Injectable } from '@angular/core';
import { AlertService } from '@context/frontend/alert';
import { CrudService } from '@context/frontend/api-client';
import { Alerts } from '@context/shared/types/alert';
import {
	CreateNotificationDto,
	Notification,
} from '@context/shared/types/notification';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, debounceTime, firstValueFrom, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService extends CrudService<Notification> {
	static readonly CollectionId = 'notifications';

	readonly notifications$ = new BehaviorSubject<Notification[]>([]);
	readonly unreadNotificationCount$ = new BehaviorSubject<number>(0);

	initialized = false;
	lastNotificationId: string | null = null;

	constructor(private readonly alertService: AlertService) {
		super();
		this.initialize();
	}

	override initialize() {
		// the initial call of this function will call without authService being injected
		if (!this.user.value) return;

		// notifications require a reset path after every initializing for new user id
		this.path = this.user.value
			? `users/${this.user.value.id}/${NotificationService.CollectionId}`
			: undefined;

		super.initialize();

		// reset all of the notification values
		this.notifications$.next([]);
		this.lastNotificationId = null;
		this.initialized = false;
		this.unreadNotificationCount$.next(0);

		this.fetchStream({ sortBy: 'createdAt', sortDir: 'desc' })
			.pipe(takeUntil(this.reset$))
			.subscribe((notifications) => {
				const latest = notifications.length ? notifications[0] : null;
				const isNew =
					latest && this.initialized
						? this.notifications$.value.every(
								(n) => n.id !== latest.id,
							)
						: false;

				if (latest && isNew) {
					this.notify(`notification.${latest.type}`, latest.args);
				}

				this.initialized = true;
				this.notifications$.next(notifications);
				this.unreadNotificationCount$.next(
					notifications.filter((v) => !v.acknowledgedAt).length,
				);
			});
	}

	/**
	 * We override the batch update in the notifications service because notifications
	 * do not need activity logs in any use case (at the time of writing this). So we
	 * override it to ensure the adding of logs is disabled.
	 */
	override batchUpdate(payloads: Partial<Notification>[]) {
		return super.batchUpdate(payloads, { addActivityLog: false });
	}

	dismiss(notifications: Notification[]) {
		// we need to reset this to ensure a notification doesn't trigger once it refreshes
		this.lastNotificationId = null;

		const now = Timestamp.now();
		return this.batchUpdate(
			notifications.map((notification) => ({
				id: notification.id,
				// we want to preserve the initial acknowledge at
				acknowledgedAt: notification.acknowledgedAt ?? now,
				deletedAt: now,
			})),
		).catch((error) => console.error('Error from the service', error));
	}

	markAsRead(notifications: Notification[]) {
		const now = Timestamp.now();
		return this.batchUpdate(
			notifications.map((notification) => ({
				id: notification.id,
				// we want to preserve the initial acknowledge at
				acknowledgedAt: notification.acknowledgedAt ?? now,
			})),
		).catch((error) => console.error('Error from the service', error));
	}

	notify(content: string, data?: object) {
		const alert = Alerts['notification'];
		this.alertService
			.open('notification', {
				...alert,
				content,
				data,
			})
			.subscribe();
	}

	override create(
		_payload: Partial<Notification>,
	): Promise<Partial<Notification>> {
		console.error('Payload not used, invalid operation', _payload);
		throw Error(
			'Invalid operation. Use `send` to create a notification and send it to a user',
		);
	}

	send(payload: CreateNotificationDto, recipient: string) {
		const url = `organizations/${this.organizationId.value}/users/${recipient}/notifications`;
		return firstValueFrom(this.http.post(`${this.api}/v1/${url}`, payload));
	}
}
