import { Pipe, PipeTransform } from '@angular/core';

import { Platform } from '@context/shared/types/native';
import { isPlatform } from '../utils';

@Pipe({
	name: 'isPlatform',
	standalone: true,
})
export class IsPlatformPipe implements PipeTransform {
	/**
	 * @param platform the platform to check for
	 */
	transform(platform: Platform) {
		return isPlatform(platform);
	}
}
