type HelpForm = {
	userId: string | null;
	organizationId: string | null;
	organizationName: string | null;
	email: string | null;
	name: string | null;
	message: string | null;
};

export class HelpFormDto implements HelpForm {
	userId!: string;
	organizationId!: string;
	organizationName!: string;
	email!: string;
	name!: string;
	message!: string;

	constructor(readonly data: HelpForm) {
		Object.assign(this, this.data);
	}
}
