import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentReference, getDoc } from '@angular/fire/firestore';
import { Activity, ActivityType } from '@context/shared/types/activity';
import { getName, User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiIcon } from '@taiga-ui/core';

const DefaultActivityIcon = '@tui.activity';
const ActivityIconMap: { [key in ActivityType]: string } = {
	archive: '@tui.archive',
	create: '@tui.circle-plus',
	delete: '@tui.trash',
	disable: '@tui.circle-minus',
	enable: '@tui.circle-check',
	'move-to': '@tui.folder-input',
	ownership: '@tui.user-cog',
	'revoke-ownership': '@tui.user-minus',
	'revoke-share': '@tui.user-round-x',
	share: '@tui.share-2',
	unarchive: '@tui.archive-restore',
	update: '@tui.pen-line',
	status: '@tui.trending-up',
	thumbnail: '@tui.image',
	'set-as-thumbnail': '@tui.image-plus',
	'remove-as-thumbnail': '@tui.image-minus',
	'create-external-link': '@tui.link',
	'remove-external-link': '@tui.unlink',
};

@Component({
	standalone: true,
	imports: [TranslocoModule, DatePipe, TuiIcon],
	selector: 'ctx-activity-row',
	templateUrl: 'activity-row.component.html',
	styleUrl: 'activity-row.component.scss',
})
export class ActivityRowComponent implements OnInit {
	@Input()
	activity!: Activity;

	@Input()
	user: User | null = null;

	@Input()
	label: string | null = null;

	icon!: string;

	userName: string | null = null;

	ngOnInit() {
		this.icon = ActivityIconMap[this.activity.type] ?? DefaultActivityIcon;

		if (this.user) {
			this.userName = getName(this.user as User);
		} else if (this.activity.user) {
			getDoc(this.activity.user as DocumentReference<User>).then(
				(res) => {
					this.user = res.data() as User;
					this.userName = getName(this.user as User);
				},
			);
		}
	}
}
