import { NgComponentOutlet } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { getUaDevice } from '@context/frontend/native/platform';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [NgComponentOutlet],
	selector: 'ctx-page-card',
	styleUrl: 'page-card.component.scss',
	template: `<ng-content></ng-content>`,
})
export class PageCardComponent {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeL | TuiSizeS | 'full' = 'l';
}
