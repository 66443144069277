import { inject, Injectable } from '@angular/core';
import {
	collection,
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
} from '@angular/fire/firestore';
import { CrudService } from '@context/frontend/api-client';
import { NotificationService } from '@context/frontend/notification';
import {
	Content,
	createPath,
	createThumbnail,
	PresetCompressions,
	ThumbnailReferenceDerived,
} from '@context/shared/types/common';
import { ContentFile, createFile } from '@context/shared/types/file';
import { ContentFolder, createFolder } from '@context/shared/types/folder';
import { ContentMedia } from '@context/shared/types/media';
import { getName, User } from '@context/shared/types/user';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';

/**
 * Although the inherited `create` function will work, it is recommended to
 * use the `createFolder` or `createFile` functions instead to ensure the correct
 * type is appended to the entity.
 */
@Injectable({ providedIn: 'root' })
export class ContentService extends CrudService<Content> {
	static readonly CollectionId = 'content';

	/**
	 * If a folder is currently being viewed, that folder's id will be stored here.
	 */
	readonly openFolderId = new BehaviorSubject<string | null>(null);
	readonly notificationService = inject(NotificationService);

	constructor() {
		super(ContentService.CollectionId);
	}

	/**
	 * Although the inherited `create` function will work, it is recommended to
	 * use the `createFolder` or `createFile` functions instead to ensure the correct
	 * type is appended to the entity.
	 */
	override create(payload: Partial<Content>) {
		if (!payload.type)
			throw new Error('A type is required for a content entity');
		return super.create(payload);
	}

	/**
	 * Finds and returns all of the content items that were created by the
	 * specified user.
	 * @param userRef the user doc reference to query the createdBy with
	 */
	fetchCreatedBy = (userRef: DocumentReference<User>) =>
		getDocs(query(this.collection, where('createdBy', '==', userRef)));

	/**
	 * Finds a collection's document record by it's external id
	 *
	 * @param id The unique external id of the content.
	 */
	findByExternalId(id: string, orgId: string) {
		const contentCollection = collection(
			this.firestore,
			`organizations/${orgId}/${ContentService.CollectionId}`,
		);

		return getDocs(
			query(contentCollection, where('externalId', '==', id)),
		).then((snapshot) => {
			if (snapshot.empty) return null;
			return snapshot.docs[0];
		});
	}

	createFile = (payload: Partial<ContentFile>) =>
		this.create(
			createFile(payload, this.userRef as DocumentReference<User>),
		);

	createFolder = (payload: Partial<ContentFolder>) =>
		this.create(
			createFolder(payload, this.userRef as DocumentReference<User>),
		);

	setOpenFolder(value: string | null) {
		const current = this.openFolderId.value;
		if (current !== value) {
			this.openFolderId.next(value);
		}
	}

	async generateFolderPath(
		content: Content,
	): Promise<ContentFolder[] | null> {
		if (!content.path) return null;

		const path: ContentFolder[] = [];
		for (const segment of content.path) {
			let data = this.items.get(segment.id) as ContentFolder;
			if (!data) {
				data = (await getDoc(
					segment as DocumentReference<ContentFolder>,
				).then((res) => res.data() as ContentFolder)) as ContentFolder;
				this.items.set(data.id, data);
			}
			path.push(data);
		}

		return path;
	}

	/**
	 * Moves a piece of content to another folder and updates all of the necessary
	 * paths and references along the way. This has potential to be a taxing operation
	 * so it is recommended to not abuse this. Use sparingly.
	 *
	 * @param content the content to move into another folder
	 * @param moveTo the folder reference to move the provided content to
	 */
	async moveContentToFolder(
		content: Content,
		moveTo: DocumentReference<ContentFolder> | null,
	) {
		const moveToFolder = moveTo
			? ((await getDoc(moveTo)).data() as ContentFolder)
			: null;

		content.parent = moveTo;
		content.path = createPath(content, moveToFolder?.path ?? []);
		const updatePayload = [content];

		// we need the document reference to the content being moved for querying
		const docRef = doc(this.collection, content.id);

		// retrieve all of the content where the moving content is included in
		const moveableContentSnapshot = await getDocs(
			query(this.collection, where('path', 'array-contains', docRef)),
		).catch((error) => {
			console.error(
				'There was an issue finding the moveable content',
				error,
			);
			throw new Error('Could not find moveable content');
		});

		for (const doc of moveableContentSnapshot.docs) {
			const item = doc.data() as Content;

			if (item.path?.length) {
				const parentIndex = item.path?.findIndex(
					({ id }) => id === content.id,
				);

				if (parentIndex >= 0)
					item.path?.splice(0, parentIndex, ...(content.path ?? []));
			}

			updatePayload.push(item);
		}

		return Promise.all([
			this.batchUpdate(updatePayload, {
				addActivityLog: true,
				type: 'move-to',
			}),
			...updatePayload.map((c) =>
				this.shareWithUsers(c, c.shared as DocumentReference<User>[]),
			),
		]).then(() => content);
	}

	archiveFile(id: string) {
		return this.update(
			{ id, archivedAt: Timestamp.now() },
			{ addActivityLog: true, type: 'archive' },
		);
	}

	unarchiveFile(id: string) {
		return this.update(
			{ id, archivedAt: null },
			{ addActivityLog: true, type: 'unarchive' },
		);
	}

	updateFileStatus(payload: Pick<ContentFile, 'status' | 'id'>) {
		return this.update(payload, {
			addActivityLog: true,
			type: 'status',
		});
	}

	/**
	 * Fetches all of the affected content items (children of the content if there are some)
	 * and will archive all of the child as well as the requested content item.
	 *
	 * @param id the id of the content to be archived
	 */
	async archiveFolder(id: string) {
		const now = Timestamp.now();
		const children = await this.fetchArchiveAffectedContent(id, 'archive');
		const payload = children.docs.map((doc) => ({
			id: doc.id,
			archivedAt: now,
		}));
		payload.push({ id, archivedAt: now });

		return this.batchUpdate(payload, {
			addActivityLog: true,
			type: 'archive',
		});
	}

	/**
	 * Fetches all of the affected content items (children of the content if there are some)
	 * and will unarchive all of the child as well as the requested content item.
	 *
	 * @param id the id of the content to be archived
	 */
	async unarchiveFolder(id: string) {
		const children = await this.fetchArchiveAffectedContent(
			id,
			'unarchive',
		);

		const payload = children.docs.map((doc) => ({
			id: doc.id,
			archivedAt: null,
		}));
		payload.push({ id, archivedAt: null });

		return this.batchUpdate(payload, {
			addActivityLog: true,
			type: 'unarchive',
		});
	}

	/**
	 * Fetches all of the affected content items (children of the content if there are some)
	 * and will unarchive all of the child as well as the requested content item.
	 *
	 * @param id the id of the content to be archived
	 */
	async deleteFolder(id: string) {
		const children = await this.fetchDeleteAffectedContent(id);
		const payload = children.docs.map((doc) => ({ id: doc.id }));
		payload.push({ id });

		return this.batchDelete(payload);
	}

	/**
	 * Fetches and returns all of the content that would be affected based on the id provided and
	 * if the content is upstream in any path.
	 *
	 * @param id the id of the doc that will be the basis of the affected
	 */
	fetchAffectedContent(id: string) {
		const docRef = doc(this.collection, id);
		return getDocs(
			query(this.collection, where('path', 'array-contains', docRef)),
		);
	}

	/**
	 * Determines how many items will be affected correlating to a deletion based
	 * function.
	 *
	 * @param id the id of the content item that is requested to be deleted
	 */
	fetchDeleteAffectedContent(id: string) {
		const docRef = doc(this.collection, id);
		return getDocs(
			query(
				this.collection,
				where('path', 'array-contains', docRef),
				where('deletedAt', '==', null),
			),
		);
	}

	/**
	 * Determines how many items will be affected correlating to an archive based
	 * function. I.e. archive/unarchive.
	 *
	 * @param id the id of the content item that is requested to be archived
	 */
	fetchArchiveAffectedContent(
		id: string,
		operation: 'archive' | 'unarchive',
	) {
		const docRef = doc(this.collection, id);
		const queryConstraints = [where('path', 'array-contains', docRef)];
		if (operation === 'archive') {
			queryConstraints.push(where('archivedAt', '==', null));
		} else {
			queryConstraints.push(where('archivedAt', '!=', null));
		}
		return getDocs(query(this.collection, ...queryConstraints));
	}

	/**
	 * Determines if the contents parent is archived. This was initially created
	 * to determine if a content needs to be moved to a different folder when
	 * the unarchive action is taken.
	 *
	 * @param content the content to check the parent of
	 */
	async isParentArchived(content: Content) {
		// no parent, no parent archived
		if (!content.parent) return false;

		const parentSnapshot = await getDoc(
			content.parent as DocumentReference<ContentFolder>,
		);
		const parent = parentSnapshot.data() as ContentFolder;

		const now = new Date().valueOf();
		return !!(
			parent.archivedAt && parent.archivedAt.toDate().valueOf() < now
		);
	}

	/**
	 * Sets the media derived thumbnails on the provided content item in order to utilize
	 * the media thumbnails as the contents thumbnail
	 *
	 * @param payload the information needed to generate the thumbnail links
	 */
	setContentMediaDerivedThumbnail(
		payload: {
			content: Content;
			media: ContentMedia;
		} & Pick<ThumbnailReferenceDerived, 'derived' | 'ref'>,
	) {
		const { content, media, ref, derived } = payload;
		const batch: Promise<any>[] = [];

		// being media derived will also update values on the media ref
		if (derived === 'media') {
			const prevMediaRef =
				(content.thumbnails.find(
					(t) => t.derived === derived && !!t.ref,
				)?.ref as DocumentReference<ContentMedia>) ?? null;

			if (prevMediaRef) {
				batch.push(
					updateDoc(prevMediaRef, { setAsThumbnail: false }).catch(
						(error) => {
							console.error(error);
							throw new Error(
								'The previous media ref was not updated',
							);
						},
					),
					this.logActivity({
						entity: prevMediaRef,
						createdBy: this.user.value?.id as string,
						activity: { type: 'remove-as-thumbnail' },
					}),
				);
			}

			batch.push(
				updateDoc(ref as DocumentReference<ContentMedia>, {
					setAsThumbnail: true,
				}),
				this.logActivity({
					entity: ref as DocumentReference<ContentMedia>,
					createdBy: this.user.value?.id as string,
					activity: { type: 'set-as-thumbnail' },
				}),
			);
		}

		content.thumbnails = [
			// remove any previous media derived thumbnails since only one should be referenced
			...content.thumbnails.filter((t) => t.derived !== derived),
			createThumbnail({
				ref,
				derived,
				storagePath: media.thumbnailPath as string,
				maxResolution: PresetCompressions.thumbnail.maxWidthOrHeight,
			}),
			createThumbnail({
				ref,
				derived,
				storagePath: media.storagePath as string,
				maxResolution: PresetCompressions.max.maxWidthOrHeight,
			}),
		];

		batch.push(
			this.update(
				{ id: content.id, thumbnails: content.thumbnails },
				{ addActivityLog: true, type: 'thumbnail' },
			),
		);

		return Promise.all(batch).then(() => content);
	}

	checkAndRemoveContentMediaThumbnail(payload: {
		content: Content;
		mediaRef: DocumentReference<ContentMedia>;
	}) {
		const { content, mediaRef } = payload;

		const prevMediaRef =
			(content.thumbnails.find((t) => t.ref?.id === mediaRef.id)
				?.ref as DocumentReference<ContentMedia>) ?? null;

		// if there is no prev media detected, then we don't want to update anything
		if (!prevMediaRef) return Promise.resolve();

		const thumbnails = [
			...content.thumbnails.filter((t) => t.ref?.id !== mediaRef.id),
		];

		return Promise.all([
			updateDoc(prevMediaRef, { setAsThumbnail: false }).catch(
				(error) => {
					console.error(error);
					throw new Error('The previous media ref was not updated');
				},
			),
			this.logActivity({
				entity: prevMediaRef,
				createdBy: this.user.value?.id as string,
				activity: { type: 'remove-as-thumbnail' },
			}),
			this.update(
				{ id: content.id, thumbnails },
				{ addActivityLog: true, type: 'thumbnail' },
			),
		]);
	}

	createExternalLink(content: Content) {
		const externalId = v4();
		return this.update(
			{ id: content.id, externalId },
			{ addActivityLog: true, type: 'create-external-link' },
		).then(() => externalId);
	}

	removeExternalLink = (content: Content) =>
		this.update(
			{ id: content.id, externalId: null },
			{ addActivityLog: true, type: 'remove-external-link' },
		);

	shareWithUsers(content: Content, userRefs: DocumentReference<User>[]) {
		if (!userRefs?.length) return Promise.resolve(content);

		const user = this.user.value as User;
		const sendNotification = (recipient: string) =>
			this.notificationService.send(
				{
					createdById: user.id,
					type: `user-shared-${content.type}`,
					args: {
						name: content.name,
						id: content.id,
						user: getName(user),
					},
				},
				recipient,
			);

		const getShared = (_c: Content, _r: DocumentReference<User>[]) => {
			const shareMap = new Map<string, DocumentReference<User>>();
			[..._r, ...(_c.shared ?? [])].forEach((_s) =>
				shareMap.set(_s.id, _s as DocumentReference<User>),
			);
			return Array.from(shareMap.values());
		};

		const batch: Promise<any>[] = [
			this.update(
				{ id: content.id, shared: getShared(content, userRefs) },
				{ addActivityLog: true, type: 'share' },
			)
				.then(() =>
					// we want to ensure the update was successful before initiating the notifications
					Promise.all(
						userRefs.map((user) =>
							sendNotification(user.id).catch((error) => {
								console.error(
									'There was an error sending the notification',
									error,
								);
							}),
						),
					),
				)
				.catch((error) => {
					console.error(
						'There was an error sharing the content',
						error,
					);
				}),
		];

		// adds the sharing to all of the entities upstream
		// we are not going to add a notification for inherited sharing
		if (content.path?.length) {
			content.path.forEach((p) => {
				batch.push(
					getDoc(p as DocumentReference<Content>).then((doc) => {
						const data = doc.data() as Content;
						return updateDoc(p as DocumentReference<Content>, {
							shared: getShared(data, userRefs),
						});
					}),
				);
			});
		}

		return Promise.all(batch);
	}

	async removeShareWithUser(
		content: Content,
		userRefs: DocumentReference<User>[],
	) {
		const user = this.user.value as User;
		const sendNotification = (recipient: string) =>
			this.notificationService.send(
				{
					createdById: user.id,
					type: `user-revoked-${content.type}`,
					args: {
						name: content.name,
						id: content.id,
						user: getName(user),
					},
				},
				recipient,
			);

		const removeFromShared = (_c: Content, _r: DocumentReference<User>[]) =>
			_c.shared.filter((u) => !_r.some((ref) => ref.id === u.id));

		const batch: Promise<any>[] = [
			this.update(
				{ id: content.id, shared: removeFromShared(content, userRefs) },
				{ addActivityLog: true, type: 'revoke-share' },
			)
				.then(() =>
					// we want to ensure the update was successful before initiating the notifications
					Promise.all(
						userRefs.map((user) =>
							sendNotification(user.id).catch((error) => {
								console.error(
									'There was an error sending the notification',
									error,
								);
							}),
						),
					),
				)
				.catch((error) => {
					console.error(
						'There was an error revoking the share access',
						error,
					);
				}),
		];

		const children = await this.fetchAffectedContent(content.id);
		children.docs.forEach((doc) => {
			const data = doc.data() as Content;
			batch.push(
				updateDoc(doc.ref, {
					shared: removeFromShared(data, userRefs),
				}),
			);
		});

		return Promise.all(batch);
	}

	override updateOwner(content: Content, newOwner: DocumentReference<User>) {
		// steps to ensure the new owner is in the share list, without duplicating
		const shared = content.shared.filter((s) => s.id !== newOwner.id);
		// new owner goes to the top of the list
		content.shared = [newOwner, ...shared];
		return super.updateOwner(content, newOwner);
	}
}
