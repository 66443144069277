<tui-icon [icon]="icon"></tui-icon>

<div class="activity-row__information">
	<small>
		{{ activity.createdAt.toDate() | date: 'hh:mm aa' }}
	</small>
	<p
		class="font-size-medium"
		[innerHtml]="
			label ?? 'activity.type.' + activity.type
				| transloco: { name: userName }
		"
	></p>
</div>
