import { Component, Input } from '@angular/core';
import { TuiAvatar, TuiAvatarOutline } from '@taiga-ui/kit';

export type PlaceholderConfig = {
	header: string;
	content: string;
	icon: string;
};

@Component({
	standalone: true,
	imports: [TuiAvatar, TuiAvatarOutline],
	selector: 'ctx-table-placeholder',
	templateUrl: 'table-placeholder.component.html',
	styleUrl: 'table-placeholder.component.scss',
})
export class TablePlaceholderComponent implements PlaceholderConfig {
	@Input()
	header!: string;

	@Input()
	content!: string;

	@Input()
	icon!: string;
}
