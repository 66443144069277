import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { DialogBase } from '@context/frontend/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';
import { TuiCheckbox } from '@taiga-ui/kit';

type AcknowledgeOptions = {
	description: string;
	acknowledge?: string;
	translationParams?: object;
};

@Component({
	standalone: true,
	imports: [
		TranslocoModule,
		TuiButton,
		TuiIcon,
		TuiCheckbox,
		ReactiveFormsModule,
	],
	selector: 'ctx-acknowledge-dialog',
	templateUrl: 'acknowledge-dialog.component.html',
})
export class AcknowledgeDialogComponent extends DialogBase<
	boolean,
	AcknowledgeOptions
> {
	readonly cancelText = 'action.no';
	readonly acceptText = 'action.yes';

	readonly form = new FormGroup({
		acknowledge: new FormControl(false, Validators.requiredTrue),
	});

	onAccept = () => this.context.completeWith(true);
}
