import * as i0 from '@angular/core';
import { TemplateRef, Component, ChangeDetectionStrategy, Input, inject, ViewChild, ContentChild, Directive, NgModule } from '@angular/core';
import { tuiProvide, tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';
import * as i2 from '@taiga-ui/core/components/data-list';
import { tuiAsDataList, tuiAsDataListHost, tuiAsOptionContent, TuiDataListDirective, TuiDataList } from '@taiga-ui/core/components/data-list';
import { AbstractTuiNativeSelect, AbstractTuiNullableControl, tuiAsControl, AbstractTuiTextfieldHost } from '@taiga-ui/legacy/classes';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@taiga-ui/kit/components/data-list-wrapper';
import { TuiDataListWrapper } from '@taiga-ui/kit/components/data-list-wrapper';
import * as i4 from '@taiga-ui/kit/pipes/stringify-content';
import { TuiStringifyContentPipe } from '@taiga-ui/kit/pipes/stringify-content';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';
import * as i1$1 from '@taiga-ui/core/directives/dropdown';
import { TuiDropdownOpen, TuiDropdownFixed, TuiDropdown } from '@taiga-ui/core/directives/dropdown';
import { TUI_ITEMS_HANDLERS } from '@taiga-ui/kit/tokens';
import { TUI_ARROW_MODE, TuiArrowComponent } from '@taiga-ui/legacy/components/arrow';
import * as i4$1 from '@taiga-ui/legacy/components/primitive-textfield';
import { TuiPrimitiveTextfieldComponent, TuiPrimitiveTextfieldModule, TuiTextfieldComponent } from '@taiga-ui/legacy/components/primitive-textfield';
import { TUI_SELECT_OPTION, TuiSelectOptionModule } from '@taiga-ui/legacy/components/select-option';
import * as i5 from '@taiga-ui/legacy/directives';
import { TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_SIZE, TuiWrapperModule, TuiTextfieldControllerModule, TuiLegacyDropdownOpenMonitorDirective } from '@taiga-ui/legacy/directives';
import { tuiAsFocusableItemAccessor, tuiAsTextfieldHost } from '@taiga-ui/legacy/tokens';
import * as i3$1 from '@taiga-ui/polymorpheus';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
const _c0 = ["tuiSelect", ""];
function TuiNativeSelectComponent_tui_data_list_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-data-list-wrapper", 4);
    i0.ɵɵpipe(1, "tuiStringifyContent");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r0.disabledItemHandler || ctx_r0.itemsHandlers.disabledItemHandler)("itemContent", i0.ɵɵpipeBind1(1, 3, ctx_r0.stringify))("items", ctx_r0.items);
  }
}
function TuiNativeSelectComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiNativeSelectComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.placeholder, "\n");
  }
}
function TuiNativeSelectComponent_option_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r0.disabledItemHandler ? ctx_r0.disabledItemHandler(option_r2) : ctx_r0.itemsHandlers.disabledItemHandler(option_r2))("selected", ctx_r0.selected(option_r2))("value", ctx_r0.stringify(option_r2));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.stringify(option_r2), "\n");
  }
}
const _c1 = ["tuiSelect", "", "labels", ""];
function TuiNativeSelectGroupComponent_tui_data_list_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-data-list-wrapper", 4);
    i0.ɵɵpipe(1, "tuiStringifyContent");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r0.disabledItemHandler || ctx_r0.itemsHandlers.disabledItemHandler)("itemContent", i0.ɵɵpipeBind1(1, 4, ctx_r0.stringify))("items", ctx_r0.items)("labels", ctx_r0.labels);
  }
}
function TuiNativeSelectGroupComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiNativeSelectGroupComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.placeholder, "\n");
  }
}
function TuiNativeSelectGroupComponent_optgroup_4_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r0.disabledItemHandler ? ctx_r0.disabledItemHandler(option_r2) : ctx_r0.itemsHandlers.disabledItemHandler(option_r2))("selected", ctx_r0.selected(option_r2))("value", ctx_r0.stringify(option_r2));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.stringify(option_r2), " ");
  }
}
function TuiNativeSelectGroupComponent_optgroup_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "optgroup", 6);
    i0.ɵɵtemplate(1, TuiNativeSelectGroupComponent_optgroup_4_option_1_Template, 2, 4, "option", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const group_r3 = ctx.$implicit;
    const index_r4 = ctx.index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("label", ctx_r0.labels[index_r4]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", group_r3);
  }
}
const _c2 = "[_nghost-%COMP%]{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;opacity:0}";
const _c3 = ["*", [["input"]], [["select"]]];
const _c4 = ["*", "input", "select"];
const _c5 = (a0, a1) => ({
  $implicit: a0,
  active: a1
});
function TuiSelectComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 5);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementContainerEnd();
  }
}
function TuiSelectComponent_div_5_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("textContent", text_r2);
  }
}
function TuiSelectComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, TuiSelectComponent_div_5_div_1_Template, 1, 1, "div", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.computedContent)("polymorpheusOutletContext", i0.ɵɵpureFunction2(2, _c5, ctx_r2.value, ctx_r2.computedFocused));
  }
}
function TuiSelectComponent_ng_template_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiSelectComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, TuiSelectComponent_ng_template_6_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.arrow);
  }
}
class TuiNativeSelectComponent extends AbstractTuiNativeSelect {
  constructor() {
    super(...arguments);
    this.items = [];
  }
  get stringify() {
    return this.host.stringify;
  }
  selected(option) {
    return this.control.value === option;
  }
  onValueChange(index) {
    this.host.onValueChange(this.items?.[index] || null);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiNativeSelectComponent_BaseFactory;
      return function TuiNativeSelectComponent_Factory(__ngFactoryType__) {
        return (ɵTuiNativeSelectComponent_BaseFactory || (ɵTuiNativeSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiNativeSelectComponent)))(__ngFactoryType__ || TuiNativeSelectComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiNativeSelectComponent,
      selectors: [["select", "tuiSelect", "", 3, "labels", "", 3, "multiple", ""]],
      hostVars: 4,
      hostBindings: function TuiNativeSelectComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("change", function TuiNativeSelectComponent_change_HostBindingHandler($event) {
            return ctx.onValueChange($event.target.options.selectedIndex - (ctx.emptyOption ? 1 : 0));
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("disabled", ctx.host.disabled || ctx.control.readOnly)("tabIndex", ctx.host.focusable ? 0 : -1)("value", ctx.host.value);
          i0.ɵɵattribute("aria-invalid", ctx.host.invalid);
        }
      },
      inputs: {
        items: "items"
      },
      features: [i0.ɵɵProvidersFeature([tuiAsDataList(TuiNativeSelectComponent), tuiProvide(AbstractTuiNativeSelect, TuiNativeSelectComponent), {
        provide: TemplateRef,
        deps: [TuiNativeSelectComponent],
        useFactory: ({
          datalist
        }) => datalist
      }]), i0.ɵɵInheritDefinitionFeature],
      attrs: _c0,
      decls: 5,
      vars: 5,
      consts: [[3, "disabledItemHandler", "itemContent", "items", 4, "tuiDataList"], [4, "ngIf"], ["disabled", "", "selected", "", "value", "", 4, "ngIf"], [3, "disabled", "selected", "value", 4, "ngFor", "ngForOf"], [3, "disabledItemHandler", "itemContent", "items"], ["disabled", "", "selected", "", "value", ""], [3, "disabled", "selected", "value"]],
      template: function TuiNativeSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiNativeSelectComponent_tui_data_list_wrapper_0_Template, 2, 5, "tui-data-list-wrapper", 0)(1, TuiNativeSelectComponent_ng_container_1_Template, 1, 0, "ng-container", 1);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, TuiNativeSelectComponent_option_3_Template, 2, 1, "option", 2)(4, TuiNativeSelectComponent_option_4_Template, 2, 4, "option", 3);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx.control.control == null ? null : ctx.control.control.valueChanges));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.emptyOption);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.items);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i2.TuiDataListDirective, i3.TuiDataListWrapperComponent, i1.AsyncPipe, i4.TuiStringifyContentPipe],
      styles: ["[_nghost-%COMP%]{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;opacity:0}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeSelectComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'select[tuiSelect]:not([labels]):not([multiple])',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataList(TuiNativeSelectComponent), tuiProvide(AbstractTuiNativeSelect, TuiNativeSelectComponent), {
        provide: TemplateRef,
        deps: [TuiNativeSelectComponent],
        useFactory: ({
          datalist
        }) => datalist
      }],
      host: {
        '[attr.aria-invalid]': 'host.invalid',
        '[disabled]': 'host.disabled || control.readOnly',
        '[tabIndex]': 'host.focusable ? 0 : -1',
        '[value]': 'host.value',
        '(change)': 'onValueChange($event.target.options.selectedIndex - (emptyOption ? 1 : 0))'
      },
      template: "<tui-data-list-wrapper\n    *tuiDataList\n    [disabledItemHandler]=\"disabledItemHandler || itemsHandlers.disabledItemHandler\"\n    [itemContent]=\"stringify | tuiStringifyContent\"\n    [items]=\"items\"\n/>\n<ng-container *ngIf=\"control.control?.valueChanges | async\" />\n<option\n    *ngIf=\"emptyOption\"\n    disabled\n    selected\n    value=\"\"\n>\n    {{ placeholder }}\n</option>\n<option\n    *ngFor=\"let option of items; let index = index\"\n    [disabled]=\"disabledItemHandler ? disabledItemHandler(option) : itemsHandlers.disabledItemHandler(option)\"\n    [selected]=\"selected(option)\"\n    [value]=\"stringify(option)\"\n>\n    {{ stringify(option) }}\n</option>\n",
      styles: [":host{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;opacity:0}\n"]
    }]
  }], null, {
    items: [{
      type: Input
    }]
  });
})();
class TuiNativeSelectGroupComponent extends AbstractTuiNativeSelect {
  constructor() {
    super(...arguments);
    this.items = [];
    this.labels = [];
  }
  get stringify() {
    return this.host.stringify;
  }
  selected(option) {
    return this.control.value === option;
  }
  onValueChange(index) {
    const flatItems = this.items?.reduce((acc, val) => acc.concat(val), []);
    this.host.onValueChange(flatItems?.[index] || null);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiNativeSelectGroupComponent_BaseFactory;
      return function TuiNativeSelectGroupComponent_Factory(__ngFactoryType__) {
        return (ɵTuiNativeSelectGroupComponent_BaseFactory || (ɵTuiNativeSelectGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiNativeSelectGroupComponent)))(__ngFactoryType__ || TuiNativeSelectGroupComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiNativeSelectGroupComponent,
      selectors: [["select", "tuiSelect", "", "labels", "", 3, "multiple", ""]],
      hostVars: 4,
      hostBindings: function TuiNativeSelectGroupComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("change", function TuiNativeSelectGroupComponent_change_HostBindingHandler($event) {
            return ctx.onValueChange($event.target.options.selectedIndex - (ctx.emptyOption ? 1 : 0));
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("disabled", ctx.host.disabled || ctx.control.readOnly)("tabIndex", ctx.host.focusable ? 0 : -1)("value", ctx.host.value);
          i0.ɵɵattribute("aria-invalid", ctx.host.invalid);
        }
      },
      inputs: {
        items: "items",
        labels: "labels"
      },
      features: [i0.ɵɵProvidersFeature([tuiAsDataList(TuiNativeSelectGroupComponent), tuiProvide(AbstractTuiNativeSelect, TuiNativeSelectGroupComponent), {
        provide: TemplateRef,
        deps: [TuiNativeSelectGroupComponent],
        useFactory: ({
          datalist
        }) => datalist
      }]), i0.ɵɵInheritDefinitionFeature],
      attrs: _c1,
      decls: 5,
      vars: 5,
      consts: [[3, "disabledItemHandler", "itemContent", "items", "labels", 4, "tuiDataList"], [4, "ngIf"], ["disabled", "", "selected", "", "value", "", 4, "ngIf"], [3, "label", 4, "ngFor", "ngForOf"], [3, "disabledItemHandler", "itemContent", "items", "labels"], ["disabled", "", "selected", "", "value", ""], [3, "label"], [3, "disabled", "selected", "value", 4, "ngFor", "ngForOf"], [3, "disabled", "selected", "value"]],
      template: function TuiNativeSelectGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiNativeSelectGroupComponent_tui_data_list_wrapper_0_Template, 2, 6, "tui-data-list-wrapper", 0)(1, TuiNativeSelectGroupComponent_ng_container_1_Template, 1, 0, "ng-container", 1);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, TuiNativeSelectGroupComponent_option_3_Template, 2, 1, "option", 2)(4, TuiNativeSelectGroupComponent_optgroup_4_Template, 2, 2, "optgroup", 3);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx.control.control == null ? null : ctx.control.control.valueChanges));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.emptyOption);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.items);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i2.TuiDataListDirective, i3.TuiDataListGroupWrapperComponent, i1.AsyncPipe, i4.TuiStringifyContentPipe],
      styles: [_c2],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeSelectGroupComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'select[tuiSelect][labels]:not([multiple])',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataList(TuiNativeSelectGroupComponent), tuiProvide(AbstractTuiNativeSelect, TuiNativeSelectGroupComponent), {
        provide: TemplateRef,
        deps: [TuiNativeSelectGroupComponent],
        useFactory: ({
          datalist
        }) => datalist
      }],
      host: {
        '[attr.aria-invalid]': 'host.invalid',
        '[disabled]': 'host.disabled || control.readOnly',
        '[tabIndex]': 'host.focusable ? 0 : -1',
        '[value]': 'host.value',
        '(change)': 'onValueChange($event.target.options.selectedIndex - (emptyOption ? 1 : 0))'
      },
      template: "<tui-data-list-wrapper\n    *tuiDataList\n    [disabledItemHandler]=\"disabledItemHandler || itemsHandlers.disabledItemHandler\"\n    [itemContent]=\"stringify | tuiStringifyContent\"\n    [items]=\"items\"\n    [labels]=\"labels\"\n/>\n<ng-container *ngIf=\"control.control?.valueChanges | async\" />\n<option\n    *ngIf=\"emptyOption\"\n    disabled\n    selected\n    value=\"\"\n>\n    {{ placeholder }}\n</option>\n<optgroup\n    *ngFor=\"let group of items; let index = index\"\n    [label]=\"labels[index]\"\n>\n    <option\n        *ngFor=\"let option of group; let i = index\"\n        [disabled]=\"disabledItemHandler ? disabledItemHandler(option) : itemsHandlers.disabledItemHandler(option)\"\n        [selected]=\"selected(option)\"\n        [value]=\"stringify(option)\"\n    >\n        {{ stringify(option) }}\n    </option>\n</optgroup>\n",
      styles: [":host{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;opacity:0}\n"]
    }]
  }], null, {
    items: [{
      type: Input
    }],
    labels: [{
      type: Input
    }]
  });
})();
const TUI_SELECT_DEFAULT_OPTIONS = {
  valueContent: ''
};
/**
 * @deprecated: drop in v5.0
 * Default parameters for Select component
 */
const TUI_SELECT_OPTIONS = tuiCreateToken(TUI_SELECT_DEFAULT_OPTIONS);
function tuiSelectOptionsProvider(options) {
  return tuiProvideOptions(TUI_SELECT_OPTIONS, options, TUI_SELECT_DEFAULT_OPTIONS);
}
class TuiSelectComponent extends AbstractTuiNullableControl {
  constructor() {
    super(...arguments);
    this.itemsHandlers = inject(TUI_ITEMS_HANDLERS);
    this.textfieldCleaner = inject(TUI_TEXTFIELD_CLEANER);
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.arrowMode = inject(TUI_ARROW_MODE);
    this.options = inject(TUI_SELECT_OPTIONS);
    this.isMobile = inject(TUI_IS_MOBILE);
    this.open = false;
    this.stringify = this.itemsHandlers.stringify;
    this.identityMatcher = this.itemsHandlers.identityMatcher;
    this.valueContent = this.options.valueContent;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return this.textfield?.nativeFocusableElement ?? null;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.dropdown?.tuiDropdownOpen;
  }
  get computedValue() {
    return this.value === null ? '' : this.stringify(this.value) || ' ';
  }
  onValueChange(value) {
    this.value = value || null;
  }
  handleOption(option) {
    this.focusInput();
    this.value = option;
    this.open = false;
  }
  get arrow() {
    return !this.interactive ? this.arrowMode.disabled : this.arrowMode.interactive;
  }
  get nativeDropdownMode() {
    return !!this.nativeSelect && this.isMobile;
  }
  get computedContent() {
    return this.valueContent || this.computedValue;
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  onKeyDownDelete() {
    if (this.textfieldCleaner.cleaner) {
      this.value = null;
    }
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiSelectComponent_BaseFactory;
      return function TuiSelectComponent_Factory(__ngFactoryType__) {
        return (ɵTuiSelectComponent_BaseFactory || (ɵTuiSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiSelectComponent)))(__ngFactoryType__ || TuiSelectComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiSelectComponent,
      selectors: [["tui-select"]],
      contentQueries: function TuiSelectComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, AbstractTuiNativeSelect, 7);
          i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nativeSelect = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
        }
      },
      viewQuery: function TuiSelectComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
          i0.ɵɵviewQuery(TuiDropdownOpen, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdown = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function TuiSelectComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      inputs: {
        stringify: "stringify",
        identityMatcher: "identityMatcher",
        valueContent: "valueContent"
      },
      features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiSelectComponent), tuiAsControl(TuiSelectComponent), tuiAsDataListHost(TuiSelectComponent), tuiAsOptionContent(TUI_SELECT_OPTION)]), i0.ɵɵHostDirectivesFeature([i1$1.TuiDropdownFixed]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c4,
      decls: 8,
      vars: 16,
      consts: [["icon", ""], ["tuiDropdownOpenMonitor", "", 1, "t-hosted", 3, "tuiDropdownOpenChange", "tuiActiveZoneChange", "tuiDropdown", "tuiDropdownEnabled", "tuiDropdownOpen"], ["automation-id", "tui-select__textfield", 1, "t-textfield", 3, "keydown.backspace.prevent", "keydown.delete.prevent", "valueChange", "disabled", "editable", "focusable", "invalid", "nativeId", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldIcon", "value"], ["ngProjectAs", "select", 5, ["select"], 4, "ngIf"], ["automation-id", "tui-select__value", "ngProjectAs", "tuiContent", "class", "t-value", 5, ["tuiContent"], 4, "ngIf"], ["ngProjectAs", "select", 5, ["select"]], ["automation-id", "tui-select__value", "ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-value"], ["class", "t-primitive-value", 3, "textContent", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-primitive-value", 3, "textContent"], ["appearance", "icon", "tuiWrapper", "", 1, "t-icon"], [4, "polymorpheusOutlet"]],
      template: function TuiSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵtwoWayListener("tuiDropdownOpenChange", function TuiSelectComponent_Template_div_tuiDropdownOpenChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.open, $event) || (ctx.open = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵlistener("tuiActiveZoneChange", function TuiSelectComponent_Template_div_tuiActiveZoneChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onActiveZone($event));
          });
          i0.ɵɵelementStart(1, "tui-primitive-textfield", 2);
          i0.ɵɵlistener("keydown.backspace.prevent", function TuiSelectComponent_Template_tui_primitive_textfield_keydown_backspace_prevent_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onKeyDownDelete());
          })("keydown.delete.prevent", function TuiSelectComponent_Template_tui_primitive_textfield_keydown_delete_prevent_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onKeyDownDelete());
          })("valueChange", function TuiSelectComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onValueChange($event));
          });
          i0.ɵɵprojection(2);
          i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
          i0.ɵɵtemplate(4, TuiSelectComponent_ng_container_4_Template, 2, 0, "ng-container", 3)(5, TuiSelectComponent_div_5_Template, 2, 5, "div", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, TuiSelectComponent_ng_template_6_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const icon_r4 = i0.ɵɵreference(7);
          i0.ɵɵproperty("tuiDropdown", ctx.datalist || "")("tuiDropdownEnabled", ctx.interactive && !ctx.nativeDropdownMode);
          i0.ɵɵtwoWayProperty("tuiDropdownOpen", ctx.open);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.computedDisabled)("editable", false)("focusable", ctx.computedFocusable)("invalid", ctx.computedInvalid)("nativeId", ctx.nativeId)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldIcon", ctx.arrow ? icon_r4 : "")("value", ctx.computedValue);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.isMobile);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.value !== null);
        }
      },
      dependencies: [i1.NgIf, i3$1.PolymorpheusOutlet, i4$1.TuiPrimitiveTextfieldComponent, i4$1.TuiPrimitiveTextfieldDirective, i5.TuiWrapperDirective, i5.TuiTextfieldIconDirective, i5.TuiLegacyDropdownOpenMonitorDirective, i1$1.TuiDropdownDirective, i1$1.TuiDropdownOpen],
      styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:start}[_nghost-%COMP%]:not(._readonly)     input:not(:disabled){cursor:pointer}._readonly[_nghost-%COMP%]     input{cursor:default}[_nghost-%COMP%]   *:disabled[_nghost-%COMP%], *:disabled   [_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}.t-value[_ngcontent-%COMP%]{display:flex;inline-size:100%;align-items:center}.t-primitive-value[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiSelectComponent), tuiAsControl(TuiSelectComponent), tuiAsDataListHost(TuiSelectComponent), tuiAsOptionContent(TUI_SELECT_OPTION)],
      hostDirectives: [TuiDropdownFixed],
      host: {
        '[attr.data-size]': 'size'
      },
      template: "<div\n    tuiDropdownOpenMonitor\n    class=\"t-hosted\"\n    [tuiDropdown]=\"datalist || ''\"\n    [tuiDropdownEnabled]=\"interactive && !nativeDropdownMode\"\n    [(tuiDropdownOpen)]=\"open\"\n    (tuiActiveZoneChange)=\"onActiveZone($event)\"\n>\n    <tui-primitive-textfield\n        automation-id=\"tui-select__textfield\"\n        class=\"t-textfield\"\n        [disabled]=\"computedDisabled\"\n        [editable]=\"false\"\n        [focusable]=\"computedFocusable\"\n        [invalid]=\"computedInvalid\"\n        [nativeId]=\"nativeId\"\n        [pseudoActive]=\"pseudoActive\"\n        [pseudoFocus]=\"computedFocused\"\n        [pseudoHover]=\"pseudoHover\"\n        [readOnly]=\"readOnly\"\n        [tuiTextfieldIcon]=\"arrow ? icon : ''\"\n        [value]=\"computedValue\"\n        (keydown.backspace.prevent)=\"onKeyDownDelete()\"\n        (keydown.delete.prevent)=\"onKeyDownDelete()\"\n        (valueChange)=\"onValueChange($any($event))\"\n    >\n        <ng-content />\n        <ng-content\n            ngProjectAs=\"input\"\n            select=\"input\"\n        />\n\n        <ng-container\n            *ngIf=\"isMobile\"\n            ngProjectAs=\"select\"\n        >\n            <ng-content select=\"select\" />\n        </ng-container>\n\n        <div\n            *ngIf=\"value !== null\"\n            automation-id=\"tui-select__value\"\n            ngProjectAs=\"tuiContent\"\n            class=\"t-value\"\n        >\n            <div\n                *polymorpheusOutlet=\"computedContent as text; context: {$implicit: value, active: computedFocused}\"\n                class=\"t-primitive-value\"\n                [textContent]=\"text\"\n            ></div>\n        </div>\n    </tui-primitive-textfield>\n\n    <ng-template #icon>\n        <div\n            appearance=\"icon\"\n            tuiWrapper\n            class=\"t-icon\"\n        >\n            <ng-container *polymorpheusOutlet=\"arrow\" />\n        </div>\n    </ng-template>\n</div>\n",
      styles: [":host{display:block;border-radius:var(--tui-radius-m);text-align:start}:host:not(._readonly) ::ng-deep input:not(:disabled){cursor:pointer}:host._readonly ::ng-deep input{cursor:default}:host :host-context(*:disabled){pointer-events:none}.t-hosted{display:block;border-radius:inherit}.t-textfield{border-radius:inherit;text-align:inherit}.t-value{display:flex;inline-size:100%;align-items:center}.t-primitive-value{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}\n"]
    }]
  }], null, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    dropdown: [{
      type: ViewChild,
      args: [TuiDropdownOpen]
    }],
    nativeSelect: [{
      type: ContentChild,
      args: [AbstractTuiNativeSelect, {
        static: true
      }]
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    stringify: [{
      type: Input
    }],
    identityMatcher: [{
      type: Input
    }],
    valueContent: [{
      type: Input
    }]
  });
})();
class TuiSelectDirective extends AbstractTuiTextfieldHost {
  get readOnly() {
    return true;
  }
  get value() {
    return this.host.computedValue;
  }
  get stringify() {
    return this.host.stringify;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiSelectDirective_BaseFactory;
      return function TuiSelectDirective_Factory(__ngFactoryType__) {
        return (ɵTuiSelectDirective_BaseFactory || (ɵTuiSelectDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiSelectDirective)))(__ngFactoryType__ || TuiSelectDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiSelectDirective,
      selectors: [["tui-select"]],
      features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiSelectDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: 'tui-select',
      providers: [tuiAsTextfieldHost(TuiSelectDirective)]
    }]
  }], null, null);
})();
class TuiSelectModule {
  static {
    this.ɵfac = function TuiSelectModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSelectModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiSelectModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, TuiPrimitiveTextfieldModule, TuiSelectOptionModule, TuiArrowComponent, TuiWrapperModule, TuiTextfieldControllerModule, i1$1.TuiDropdownComponent, i2.TuiDataListComponent, i2.TuiOption, i3.TuiDataListWrapperComponent, i3.TuiDataListGroupWrapperComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusOutlet, PolymorpheusTemplate, TuiPrimitiveTextfieldModule, TuiSelectOptionModule, TuiArrowComponent, TuiWrapperModule, TuiTextfieldControllerModule, TuiStringifyContentPipe, TuiLegacyDropdownOpenMonitorDirective, ...TuiDropdown, ...TuiDataList, ...TuiDataListWrapper],
      declarations: [TuiSelectComponent, TuiSelectDirective, TuiNativeSelectComponent, TuiNativeSelectGroupComponent],
      exports: [TuiSelectComponent, TuiSelectDirective, TuiTextfieldComponent, TuiNativeSelectComponent, TuiNativeSelectGroupComponent, ...TuiDropdown, ...TuiDataList, ...TuiDataListWrapper]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_SELECT_DEFAULT_OPTIONS, TUI_SELECT_OPTIONS, TuiNativeSelectComponent, TuiNativeSelectGroupComponent, TuiSelectComponent, TuiSelectDirective, TuiSelectModule, tuiSelectOptionsProvider };
