import { Entity } from '@context/shared/types/common';
import { SortItem } from '../types';

export const SortOptions = <T extends Entity>(key: keyof T): SortItem<T>[] => [
	{
		key,
		sortDir: 'asc',
		label: 'data.sort.name-ascending',
	},
	{
		key,
		sortDir: 'desc',
		label: 'data.sort.name-descending',
	},
	{
		key: 'createdAt',
		sortDir: 'desc',
		label: 'data.sort.created-ascending',
	},
];
