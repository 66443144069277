import { Pipe, PipeTransform } from '@angular/core';
import {
	canEditUser,
	User
} from '@context/shared/types/user';
import { AuthService } from '../services';

@Pipe({
	name: 'canEditUser',
	standalone: true,
})
export class CanEditUserPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}

	transform(user: User) {
		if (!this.authService.user?.role) return false;
		return canEditUser(this.authService.user, user);
	}
}
