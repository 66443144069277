<section tuiContent>
	<p
		[innerHtml]="
			context.data.description | transloco: context.data.translationParams
		"
	></p>

	<form [formGroup]="form">
		<label>
			<input
				formControlName="acknowledge"
				size="m"
				tuiCheckbox
				type="checkbox"
			/>

			<span
				[innerHtml]="
					context.data.acknowledge
						| transloco: context.data.translationParams
				"
			></span>
		</label>
	</form>
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss(false)">
		{{ cancelText | transloco: context.data.translationParams }}
	</button>

	<button
		tuiButton
		appearance="accent"
		(click)="onAccept()"
		[disabled]="form.invalid"
	>
		{{ acceptText | transloco: context.data.translationParams }}
	</button>
</footer>
