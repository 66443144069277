import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Alert, AlertId, Alerts } from '@context/shared/types/alert';
import { TuiAlertOptions, TuiAlertService } from '@taiga-ui/core';
import { of, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
	readonly defaultOptions: Partial<TuiAlertOptions> = {
		autoClose: 5000,
		closeable: true,
	};

	constructor(
		private readonly tuiAlertService: TuiAlertService,
		private readonly transloco: TranslocoService,
	) {}

	/**
	 * Opens the specified pre-made alert based on the id provided. Although not
	 * recommended, a custom alert can be created too.
	 *
	 * @param id the id of the alert to open
	 */
	open(id: AlertId, alert?: Alert) {
		alert = alert ?? Alerts[id];

		const label = alert.label
			? this.transloco.translate(alert.label)
			: undefined;

		const content = alert.content
			? typeof alert.content === 'string'
				? this.transloco.translate(alert.content, alert.data)
				: alert.content
			: undefined;

		return this.tuiAlertService
			.open(content, {
				...this.defaultOptions,
				...alert,
				label,
			})
			.pipe(take(1));
	}
}
