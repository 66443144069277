import { Injectable } from '@angular/core';
import { CanActivate, GuardResult, MaybeAsync, Router } from '@angular/router';
import { getUserStatus } from '@context/shared/types/user';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class CustomAuthGuard implements CanActivate {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}

	/**
	 * @todo handle a timeout if the authentication takes too long or if an error occurs
	 * during the authentication process
	 */
	canActivate(): MaybeAsync<GuardResult> {
		const resolved$ = new Subject<boolean>();
		return new Promise<boolean>((resolve) => {
			this.authService.authenticated
				.pipe(takeUntil(resolved$))
				.subscribe((authenticated) => {
					let canActivate = false;

					// wait for the authenticated flag, the Firebase AuthGuard
					// will handle the unauthenticated workflow
					if (authenticated && this.authService.user) {
						const status = getUserStatus(this.authService.user);
						canActivate =
							status !== 'disabled' && status !== 'deleted';

						resolve(canActivate);
						resolved$.next(true);
					}
				});
		}).then((canActivate) => {
			// assuming we are authenticated when we get here
			if (!canActivate) {
				// if authenticated but can't activate, sign out and nav to auth
				return this.authService
					.signOut()
					.then(() => this.router.navigate(['/auth']))
					.then(() => false);
			}
			return canActivate;
		});
	}
}
