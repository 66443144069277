import { Pipe, PipeTransform } from '@angular/core';
import { getName, User } from '@context/shared/types/user';

@Pipe({
	name: 'userName',
	standalone: true,
})
export class UserNamePipe implements PipeTransform {
	transform(user: User | null) {
		if (!user || (!user.familyName && !user.givenName)) return null;
		return getName(user);
	}
}
