<section tuiContent>
	@for (item of context.data.menuItems; track item.text) {
		<button
			size="l"
			tuiButton
			class="menu-button"
			[disabled]="item.disabled ?? false"
			[iconStart]="item.iconStart"
			[iconEnd]="item.iconEnd ?? ''"
			(click)="onClick(item)"
		>
			<span>{{ item.text | transloco }} </span>
		</button>
	}
</section>
