<section tuiContent>
	@if (loading) {
		<div class="padding-v-4">
			<tui-loader size="xl" />
		</div>
	} @else if (form) {
		<form [formGroup]="form">
			<tui-textfield
				tuiTextfieldSize="m"
				iconStart="@tui.search"
				size="s"
			>
				<input
					formControlName="search"
					[placeholder]="'action.search' | transloco"
					tuiTextfield
				/>
			</tui-textfield>

			<ctx-table
				[pagination]="false"
				[loading]="loading"
				[empty]="!filteredUsers.length"
				[placeholder]="
					form.get('search')?.value || searching
						? placeholder
						: placeholderUsers
				"
			>
				@for (item of filteredUsers; track item.id) {
					<ctx-user-row
						size="s"
						[user]="item.data"
						[interactable]="false"
						[showStatus]="false"
					>
						<input
							start
							tuiRadio
							type="radio"
							[value]="item.id"
							formControlName="selected"
						/>
					</ctx-user-row>
				}
			</ctx-table>

			<footer tuiFooter>
				<button tuiButton appearance="outline" (click)="onDismiss()">
					{{ 'action.cancel' | transloco }}
				</button>
				<button
					tuiButton
					[loading]="busy"
					appearance="accent"
					(click)="onSubmit()"
				>
					{{ 'action.save' | transloco }}
				</button>
			</footer>
		</form>
	}
</section>
