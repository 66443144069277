import { DocumentReference } from '@context/shared/types/common';
import { ContentFolder } from '../types';
import { User } from '@context/shared/types/user';

export function createFolder(
	payload: Partial<ContentFolder>,
	userRef: DocumentReference<User>,
): Omit<
	ContentFolder,
	'createdAt' | 'updatedAt' | 'createdBy' | 'deletedAt' | 'id'
> {
	if (!payload.name) throw new Error('Folder name is required');

	return {
		...payload,
		type: 'folder',
		name: payload.name,
		description: payload.description ?? null,
		path: payload.path ?? null,
		parent: payload.parent ?? null,
		thumbnails: payload.thumbnails ?? [],
		archivedAt: payload.archivedAt ?? null,
		externalId: payload.externalId ?? null,
		shared: payload.shared ?? [userRef],
	};
}
