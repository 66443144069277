import { Capacitor } from '@capacitor/core';
import { Platform } from '@context/shared/types/native';

/**
 * Checks the device that is currently rendering the experience and returns
 * `true` if the requested platform is equal to the device. Note that browser
 * based emulators do not reflect the emulated OS, they will return as `web`.
 *
 * @param platform the platform to check the device against
 */
export function isPlatform(platform: Platform): boolean {
	return getPlatform() === platform;
}

/**
 * Returns the devices current platform typed against our internally detected
 * platforms.
 */
export function getPlatform(): Platform {
	return Capacitor.getPlatform() as Platform;
}
