import { User } from '../types';

export const getInitials = (name: string) =>
	name
		.match(/(^\S\S?|\s\S)?/g)
		?.map((v) => v.trim())
		?.join('')
		?.match(/(^\S|\S$)?/g)
		?.join('')
		.toLocaleUpperCase();

/**
 * Formats the users data to create the users full name
 *
 * @todo support the formatting of different languages based on the auth users locale
 *
 * @param user the user to format the name of
 * @returns the formatted full name of the user
 */
export const getName = (user: User) => {
	if (!user) return '';

	const value = [];
	if (user.givenName) value.push(user.givenName);
	if (user.familyName) value.push(user.familyName);
	return value.join(' ');
};
