<ng-content select="[start]" />

<ctx-avatar class="user-row__avatar" size="l" [user]="user"></ctx-avatar>

@if (user | userStatus; as status) {
	<div class="user-row__information">
		@if (user | userName; as name) {
			<p class="margin-0 font-size-medium">{{ user | userName }}</p>
		} @else {
			<p class="margin-0 font-size-medium">{{ user.email }}</p>
		}

		<small>
			{{ 'user.roles.' + user.role | transloco }}
			<span class="margin-h-1">•</span>
			@if (status.value === 'pending') {
				{{
					'user.invited-relative-time'
						| transloco: { time: (user.createdAt | relativeTime) }
				}}
			} @else {
				{{ user.email }}
			}
		</small>
	</div>

	<div class="user-row__actions">
		@if (showStatus) {
			<tui-badge [appearance]="status.appearance">
				<tui-icon [icon]="status.icon"></tui-icon>
				<span class="user-row__actions-badge-text">
					{{ status.text | transloco }}
				</span>
			</tui-badge>
		}

		<ng-content select="[end]" />

		@if (interactable) {
			<tui-icon icon="@tui.arrow-right"></tui-icon>
		}
	</div>
}
