@if (src) {
	<tui-avatar
		size="m"
		tuiAvatarOutline
		appearance="primary"
		[src]="src | tuiFallbackSrc: initials | async"
	/>
} @else {
	<tui-avatar size="m" tuiAvatarOutline [src]="icon | tuiIcon" />
}
