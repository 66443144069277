import { Injectable } from '@angular/core';
import {
	collection,
	collectionCount,
	doc,
	DocumentReference,
	getDocs,
	limit,
	query,
	setDoc,
	startAfter,
} from '@angular/fire/firestore';
import { Fetchable, PaginatedObject } from '@context/frontend/pagination';
import { Activity, ActivityMap } from '@context/shared/types/activity';
import { Entity } from '@context/shared/types/common';
import { User } from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import {
	CollectionReference,
	DocumentData,
	DocumentSnapshot,
	Timestamp,
} from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class ActivityService implements Fetchable<Activity> {
	private static readonly CollectionId = 'activity';

	create(
		entityRef: DocumentReference<Entity>,
		activity: Pick<Activity, 'type' | 'data'>,
		creator: DocumentReference<User>,
	): Promise<Activity> {
		const { type, data } = activity;
		const now = Timestamp.now();
		const payload: Activity = {
			...ActivityMap[type],
			event: 'user',
			// we assume user at this level since it's on the client side
			// server side will typically consist of system level events
			user: creator,
			createdAt: now,
			updatedAt: now,
			data,
			id: v4(),
		};

		// the activity collection tied to the entity
		const activityCollection = collection(
			entityRef,
			ActivityService.CollectionId,
		);

		return setDoc(
			doc(activityCollection, payload.id),
			sanitize(payload),
		).then(() => payload);
	}

	async fetch(
		paginated?: PaginatedObject<Activity>,
	): Promise<PaginatedObject<Activity>> {
		if (!paginated) paginated = new PaginatedObject<Activity>();
		if (!paginated?.parentRef) throw new Error('Parent ref is required');

		const activityCollection = collection(
			paginated.parentRef,
			ActivityService.CollectionId,
		);

		if (!paginated.totalElements) {
			paginated.totalElements = await firstValueFrom(
				collectionCount(
					query(activityCollection, ...paginated.constraints),
				),
			);
		}

		// if the object already contains the data, don't fetch again
		if (paginated.page) return paginated;

		const constraints = [...paginated.constraints];
		const lastDoc = paginated.previousPage?.last ?? null;
		if (lastDoc) constraints.push(startAfter(lastDoc));
		if (paginated.pageSize) constraints.push(limit(paginated.pageSize));

		const snapshot = await getDocs(
			query(activityCollection, ...constraints),
		);

		let last: DocumentSnapshot | null = null;
		const items = snapshot.docs.map((d) => {
			last = d;
			return { data: d.data(), ref: d.ref, id: d.id };
		});

		paginated.pages = Object.assign({}, paginated.pages, {
			[paginated.currentPage as number]: { last, items },
		});

		return paginated;
	}
}
