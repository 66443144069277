import { inject, Pipe, PipeTransform } from '@angular/core';
import { USER } from '@context/frontend/common';
import { hasInheritedRolePermission, Role } from '@context/shared/types/user';

@Pipe({
	name: 'roleAccess',
	standalone: true,
})
export class RoleAccessPipe implements PipeTransform {
	user$ = inject(USER);

	transform(role: Role) {
		const user = this.user$.value;
		if (!user?.role) return false;
		return hasInheritedRolePermission(user?.role, role);
	}
}
