import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DestroyService } from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import {
	PageSize,
	PageSizes,
	PaginatedPageDetails,
} from '@context/shared/types/pagination';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiDataList } from '@taiga-ui/core';
import { TuiDataListWrapper, TuiPagination } from '@taiga-ui/kit';
import {
	TuiSelectModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { takeUntil } from 'rxjs';
import {
	PlaceholderConfig,
	TableLoaderComponent,
	TablePlaceholderComponent,
} from '../';

@Component({
	standalone: true,
	imports: [
		TableLoaderComponent,
		TablePlaceholderComponent,
		TranslocoModule,
		TuiPagination,
		TuiDataList,
		TuiDataListWrapper,
		TuiSelectModule,
		TuiTextfieldControllerModule,
		FormsModule,
		ReactiveFormsModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	selector: 'ctx-table',
	templateUrl: 'table.component.html',
	styleUrl: 'table.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class TableComponent implements OnInit {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@HostBinding('attr.disabled')
	@Input()
	disabled = false;

	@Input()
	loading = false;

	@Input()
	placeholder!: PlaceholderConfig;

	@Input()
	empty = false;

	@Input()
	index = 0;

	@Input()
	length = 0;

	@Input()
	details: PaginatedPageDetails | null = null;

	@Input()
	@HostBinding('attr.paginated')
	pagination = false;

	@Input()
	size: PageSize = 10;

	@Output()
	readonly loadMore = new EventEmitter();

	@Output()
	readonly indexChange = new EventEmitter<number>();

	@Output()
	readonly sizeChange = new EventEmitter<PageSize>();

	protected items = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);

	readonly destroy$ = inject(DestroyService);
	readonly sizes = PageSizes;
	sizeControl = new FormControl(10 as PageSize);

	menuOpen = false;

	ngOnInit() {
		this.setupSizer();
	}

	setupSizer() {
		this.sizeControl.setValue(this.size);
		this.sizeControl.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value && value !== this.size) {
					this.sizeChange.emit(value);
				}
			});
	}

	onIndexChange(index: number) {
		this.indexChange.emit(index);
	}
}
