<section tuiContent>
	@if (loading) {
		<div class="padding-v-4">
			<tui-loader size="xl" />
		</div>
	} @else if (form) {
		<form [formGroup]="form">
			<tui-textfield
				tuiTextfieldSize="m"
				iconStart="@tui.search"
				size="s"
			>
				<input
					formControlName="search"
					[placeholder]="'action.search' | transloco"
					tuiTextfield
				/>
			</tui-textfield>

			<tui-tabs-with-more [(activeItemIndex)]="activeTabIndex">
				<button
					*tuiItem
					[disabled]="busy"
					tuiTab
					iconStart="@tui.share-2"
				>
					{{ 'content.shared-with' | transloco }}
				</button>
				<button
					*tuiItem
					[disabled]="busy"
					tuiTab
					iconStart="@tui.users"
				>
					{{
						'user.selected-users'
							| transloco: { count: getSelected().size }
					}}
				</button>
			</tui-tabs-with-more>

			@switch (activeTabIndex) {
				@case (0) {
					<ctx-table
						[pagination]="false"
						[loading]="loading"
						[empty]="!filteredShared.length"
						[placeholder]="
							form.get('search')?.value || searching
								? placeholder
								: placeholderShared
						"
					>
						@for (item of filteredShared; track item.id) {
							<ctx-share-user-row
								[user]="item.data"
								[userRef]="item.ref"
								[content]="context.data.content"
								(remove)="onUserRemoved($event)"
							></ctx-share-user-row>
						}
					</ctx-table>
				}
				@case (1) {
					<ctx-table
						[pagination]="false"
						[loading]="loading"
						[empty]="!filteredUsers.length"
						[placeholder]="
							form.get('search')?.value || searching
								? placeholder
								: placeholderUsers
						"
					>
						@for (item of filteredUsers; track item.id) {
							<ctx-user-row
								size="s"
								[user]="item.data"
								[interactable]="false"
								[showStatus]="false"
							>
								<input
									start
									tuiCheckbox
									type="checkbox"
									[formControl]="
										$any(form.get('selected')?.get(item.id))
									"
								/>
							</ctx-user-row>
						}
					</ctx-table>

					<footer tuiFooter>
						<button
							tuiButton
							appearance="outline"
							(click)="onDismiss()"
						>
							{{ 'action.cancel' | transloco }}
						</button>
						<button
							tuiButton
							[loading]="busy"
							appearance="accent"
							(click)="onSubmit()"
						>
							{{ 'action.save' | transloco }}
						</button>
					</footer>
				}
			}
		</form>
	}
</section>
