import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { inject, EventEmitter, TemplateRef, Component, ChangeDetectionStrategy, ViewChild, ContentChild, Input, Output, Directive, NgModule } from '@angular/core';
import { maskitoTransform, MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
import { maskitoPrefixPostprocessorGenerator, maskitoCaretGuard } from '@maskito/kit';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';
import { tuiCreateToken, tuiProvideOptions, tuiPure } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiAsDataListHost, TuiDataListDirective } from '@taiga-ui/core/components/data-list';
import * as i1 from '@taiga-ui/core/directives/dropdown';
import { TuiDropdownOpen, TuiDropdownFixed, TuiDropdown } from '@taiga-ui/core/directives/dropdown';
import { AbstractTuiControl, tuiAsControl, AbstractTuiTextfieldHost } from '@taiga-ui/legacy/classes';
import * as i3 from '@taiga-ui/legacy/components/primitive-textfield';
import { TuiPrimitiveTextfieldComponent, TuiPrimitiveTextfieldModule, TuiTextfieldComponent } from '@taiga-ui/legacy/components/primitive-textfield';
import * as i4 from '@taiga-ui/legacy/directives';
import { TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_SIZE, TuiTextfieldControllerModule, TuiValueAccessorModule, TuiLegacyDropdownOpenMonitorDirective } from '@taiga-ui/legacy/directives';
import { tuiAsFocusableItemAccessor, tuiAsTextfieldHost } from '@taiga-ui/legacy/tokens';
import * as i2 from '@maskito/angular';
import { MaskitoDirective } from '@maskito/angular';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
const TUI_INPUT_PHONE_DEFAULT_OPTIONS = {
  phoneMaskAfterCountryCode: '### ###-##-##',
  allowText: false,
  countryCode: '+7'
};
/**
 * @deprecated: drop in v5.0
 * Default parameters for input phone component
 */
const TUI_INPUT_PHONE_OPTIONS = tuiCreateToken(TUI_INPUT_PHONE_DEFAULT_OPTIONS);
function tuiInputPhoneOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_PHONE_OPTIONS, options, TUI_INPUT_PHONE_DEFAULT_OPTIONS);
}
const countDigits = value => value.replaceAll(/\D/g, '').length;
/**
 * `InputPhone` component sets country code as non-removable prefix.
 * This prefix appears on focus and cannot be erased.
 * But users sometimes can copy complete phone number (from any different source)
 * and try to insert the whole string inside our `InputPhone` textfield.
 * This preprocessor helps to avoid country prefix duplication on paste/drop/autofill events.
 */
function tuiCreateCompletePhoneInsertionPreprocessor(countryCode, phoneMaskAfterCountryCode) {
  const completePhoneLength = (countryCode + phoneMaskAfterCountryCode).replaceAll(/[^#\d]+/g, '').length;
  const trimCountryPrefix = value => countryCode === '+7' ? value.replace(/^(\+?7?\s?8?)\s?/, '') : value.replace(new RegExp(`^(\\+?${countryCode.replace('+', '')}?)\\s?`), '');
  return ({
    elementState,
    data
  }) => {
    const {
      value,
      selection
    } = elementState;
    return {
      elementState: {
        selection,
        value:
        /**
         * The only possible case when `value` includes digits more
         * than mask expression allows – browser autofill.
         * It means that we are inside `input`-event
         * and mask are ready to reject "extra" characters.
         * We should cut leading country prefix to save trailing characters!
         */
        countDigits(value) > completePhoneLength ? trimCountryPrefix(value) : value
      },
      data: countDigits(data) >= completePhoneLength ?
      /**
       * User tries to insert/drop the complete phone number (with country prefix).
       * We should drop already existing non-removable prefix.
       */
      trimCountryPrefix(data) : data
    };
  };
}

/**
 * Create {@link https://maskito.dev/core-concepts/mask-expression pattern mask expression} for phone number
 *
 * @example
 * tuiCreatePhoneMaskExpression('+1', '(###) ###-####');
 */
function tuiCreatePhoneMaskExpression(countryCode, phoneMaskAfterCountryCode) {
  return [...countryCode.split(''), ' ', ...phoneMaskAfterCountryCode.replaceAll(/[^#\- ()]+/g, '').split('').map(item => item === '#' ? /\d/ : item)];
}
const MASK_SYMBOLS = /[ \-_()]/g;
function isText(value) {
  return Number.isNaN(parseInt(value.replaceAll(MASK_SYMBOLS, ''), 10));
}
class TuiInputPhoneComponent extends AbstractTuiControl {
  constructor() {
    super(...arguments);
    this.textfieldCleaner = inject(TUI_TEXTFIELD_CLEANER);
    this.options = inject(TUI_INPUT_PHONE_OPTIONS);
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.open = false;
    this.phoneMaskAfterCountryCode = this.options.phoneMaskAfterCountryCode;
    this.allowText = this.options.allowText;
    this.search = '';
    this.searchChange = new EventEmitter();
    this.countryCode = this.options.countryCode;
  }
  set countryCodeSetter(newCountryCode) {
    const prevCountryCode = this.countryCode;
    this.countryCode = newCountryCode;
    this.updateValueWithNewCountryCode(prevCountryCode, newCountryCode);
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return !this.textfield || this.computedDisabled ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.dropdown?.tuiDropdownOpen;
  }
  get nativeValue() {
    return this.nativeFocusableElement?.value || maskitoTransform(this.value, this.maskOptions);
  }
  set nativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
  get inputMode() {
    return this.allowText ? 'text' : 'numeric';
  }
  onValueChange(value) {
    const parsed = isText(value) ? value : value.replaceAll(MASK_SYMBOLS, '').slice(0, this.maxPhoneLength);
    this.updateSearch(parsed);
    this.value = parsed === this.countryCode || isText(parsed) ? '' : parsed;
    this.open = true;
    if (!this.value && !this.allowText) {
      this.nativeValue = this.nonRemovablePrefix;
    }
  }
  handleOption(item) {
    this.focusInput();
    this.value = item;
    this.nativeValue = maskitoTransform(this.value, this.maskOptions);
    this.updateSearch('');
    this.open = false;
  }
  setDisabledState() {
    super.setDisabledState();
    this.open = false;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = maskitoTransform(value || '', this.maskOptions);
    this.updateSearch('');
  }
  get canOpen() {
    return this.interactive && !!this.datalist;
  }
  get canClean() {
    return this.nativeValue !== this.nonRemovablePrefix && this.textfieldCleaner.cleaner;
  }
  get maskOptions() {
    return this.calculateMask(this.countryCode, this.phoneMaskAfterCountryCode, this.nonRemovablePrefix, this.allowText);
  }
  onActiveZone(active) {
    this.updateFocused(active);
    if (active && !this.nativeValue && !this.readOnly && !this.allowText) {
      this.updateSearch(this.nonRemovablePrefix);
      this.nativeValue = this.nonRemovablePrefix;
      return;
    }
    if (this.nativeValue === this.nonRemovablePrefix || this.isTextValue) {
      this.updateSearch('');
      this.nativeValue = '';
      return;
    }
    if (!active && !this.allowText && this.nativeFocusableElement) {
      this.nativeValue = this.nativeValue.replace(/\D$/, '');
    }
  }
  getFallbackValue() {
    return '';
  }
  get nonRemovablePrefix() {
    return `${this.countryCode} `;
  }
  get maxPhoneLength() {
    return this.countryCode.length + this.phoneMaskAfterCountryCode.replaceAll(/[^#]+/g, '').length;
  }
  get isTextValue() {
    return !!this.search && isText(this.search);
  }
  calculateMask(countryCode, phoneMaskAfterCountryCode, nonRemovablePrefix, allowText) {
    const mask = tuiCreatePhoneMaskExpression(countryCode, phoneMaskAfterCountryCode);
    const preprocessors = [tuiCreateCompletePhoneInsertionPreprocessor(countryCode, phoneMaskAfterCountryCode)];
    return allowText ? {
      mask: ({
        value
      }) => isText(value) && value !== '+' ? MASKITO_DEFAULT_OPTIONS.mask : mask,
      preprocessors
    } : {
      mask,
      preprocessors,
      postprocessors: [maskitoPrefixPostprocessorGenerator(nonRemovablePrefix)],
      plugins: [maskitoCaretGuard((value, [from, to]) => [from === to ? nonRemovablePrefix.length : 0, value.length])]
    };
  }
  focusInput() {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll: true
      });
    }
  }
  updateSearch(search) {
    if (this.search === search) {
      return;
    }
    this.search = search;
    this.searchChange.emit(search);
  }
  updateValueWithNewCountryCode(prevCountryCode, newCountryCode) {
    if (!this.isTextValue) {
      this.value = this.value.replace(prevCountryCode, newCountryCode);
      this.nativeValue = maskitoTransform(this.value, this.maskOptions);
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputPhoneComponent_BaseFactory;
      return function TuiInputPhoneComponent_Factory(__ngFactoryType__) {
        return (ɵTuiInputPhoneComponent_BaseFactory || (ɵTuiInputPhoneComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputPhoneComponent)))(__ngFactoryType__ || TuiInputPhoneComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiInputPhoneComponent,
      selectors: [["tui-input-phone"]],
      contentQueries: function TuiInputPhoneComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
        }
      },
      viewQuery: function TuiInputPhoneComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiDropdownOpen, 5);
          i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdown = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function TuiInputPhoneComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      inputs: {
        phoneMaskAfterCountryCode: "phoneMaskAfterCountryCode",
        allowText: "allowText",
        search: "search",
        countryCodeSetter: [0, "countryCode", "countryCodeSetter"]
      },
      outputs: {
        searchChange: "searchChange"
      },
      features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputPhoneComponent), tuiAsControl(TuiInputPhoneComponent), tuiAsDataListHost(TuiInputPhoneComponent)]), i0.ɵɵHostDirectivesFeature([i1.TuiDropdownFixed]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 4,
      vars: 13,
      consts: [["tuiDropdownOpenMonitor", "", 1, "t-hosted", 3, "tuiDropdownOpenChange", "tuiActiveZoneChange", "tuiDropdown", "tuiDropdownEnabled", "tuiDropdownOpen"], ["tuiValueAccessor", "", 1, "t-textfield", 3, "valueChange", "disabled", "focusable", "invalid", "maskito", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldCleaner", "value"]],
      template: function TuiInputPhoneComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtwoWayListener("tuiDropdownOpenChange", function TuiInputPhoneComponent_Template_div_tuiDropdownOpenChange_0_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.open, $event) || (ctx.open = $event);
            return $event;
          });
          i0.ɵɵlistener("tuiActiveZoneChange", function TuiInputPhoneComponent_Template_div_tuiActiveZoneChange_0_listener($event) {
            return ctx.onActiveZone($event);
          });
          i0.ɵɵelementStart(1, "tui-primitive-textfield", 1);
          i0.ɵɵtwoWayListener("valueChange", function TuiInputPhoneComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.nativeValue, $event) || (ctx.nativeValue = $event);
            return $event;
          });
          i0.ɵɵlistener("valueChange", function TuiInputPhoneComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
            return ctx.onValueChange($event);
          });
          i0.ɵɵprojection(2);
          i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("tuiDropdown", ctx.datalist || "")("tuiDropdownEnabled", ctx.canOpen);
          i0.ɵɵtwoWayProperty("tuiDropdownOpen", ctx.open);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("invalid", ctx.computedInvalid)("maskito", ctx.maskOptions)("nativeId", ctx.nativeId)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldCleaner", ctx.canClean);
          i0.ɵɵtwoWayProperty("value", ctx.nativeValue);
        }
      },
      dependencies: [i2.MaskitoDirective, i3.TuiPrimitiveTextfieldComponent, i3.TuiPrimitiveTextfieldDirective, i4.TuiTextfieldCleanerDirective, i4.TuiValueAccessorDirective, i4.TuiLegacyDropdownOpenMonitorDirective, i1.TuiDropdownDirective, i1.TuiDropdownOpen],
      styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:start}._disabled[_nghost-%COMP%], [_nghost-%COMP%]   *:disabled[_nghost-%COMP%], *:disabled   [_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}"],
      changeDetection: 0
    });
  }
}
__decorate([tuiPure], TuiInputPhoneComponent.prototype, "calculateMask", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-input-phone',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputPhoneComponent), tuiAsControl(TuiInputPhoneComponent), tuiAsDataListHost(TuiInputPhoneComponent)],
      hostDirectives: [TuiDropdownFixed],
      host: {
        '[attr.data-size]': 'size'
      },
      template: "<div\n    tuiDropdownOpenMonitor\n    class=\"t-hosted\"\n    [tuiDropdown]=\"datalist || ''\"\n    [tuiDropdownEnabled]=\"canOpen\"\n    [(tuiDropdownOpen)]=\"open\"\n    (tuiActiveZoneChange)=\"onActiveZone($event)\"\n>\n    <tui-primitive-textfield\n        tuiValueAccessor\n        class=\"t-textfield\"\n        [disabled]=\"computedDisabled\"\n        [focusable]=\"focusable\"\n        [invalid]=\"computedInvalid\"\n        [maskito]=\"maskOptions\"\n        [nativeId]=\"nativeId\"\n        [pseudoFocus]=\"computedFocused\"\n        [pseudoHover]=\"pseudoHover\"\n        [readOnly]=\"readOnly\"\n        [tuiTextfieldCleaner]=\"canClean\"\n        [(value)]=\"nativeValue\"\n        (valueChange)=\"onValueChange($event)\"\n    >\n        <ng-content />\n        <ng-content\n            ngProjectAs=\"input\"\n            select=\"input\"\n        />\n    </tui-primitive-textfield>\n</div>\n",
      styles: [":host{display:block;border-radius:var(--tui-radius-m);text-align:start}:host._disabled,:host :host-context(*:disabled){pointer-events:none}.t-hosted{display:block;border-radius:inherit}.t-textfield{border-radius:inherit;text-align:inherit}\n"]
    }]
  }], null, {
    dropdown: [{
      type: ViewChild,
      args: [TuiDropdownOpen]
    }],
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    phoneMaskAfterCountryCode: [{
      type: Input
    }],
    allowText: [{
      type: Input
    }],
    search: [{
      type: Input
    }],
    searchChange: [{
      type: Output
    }],
    countryCodeSetter: [{
      type: Input,
      args: ['countryCode']
    }],
    calculateMask: []
  });
})();
class TuiInputPhoneDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.nativeValue;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  process(input) {
    this.input = input;
  }
  ngDoCheck() {
    if (!this.input) {
      return;
    }
    this.input.type = 'tel';
    this.input.inputMode = this.host.inputMode;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputPhoneDirective_BaseFactory;
      return function TuiInputPhoneDirective_Factory(__ngFactoryType__) {
        return (ɵTuiInputPhoneDirective_BaseFactory || (ɵTuiInputPhoneDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputPhoneDirective)))(__ngFactoryType__ || TuiInputPhoneDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiInputPhoneDirective,
      selectors: [["tui-input-phone"]],
      features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputPhoneDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: 'tui-input-phone',
      providers: [tuiAsTextfieldHost(TuiInputPhoneDirective)]
    }]
  }], null, null);
})();
class TuiInputPhoneModule {
  static {
    this.ɵfac = function TuiInputPhoneModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiInputPhoneModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiInputPhoneModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, i1.TuiDropdownComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPhoneModule, [{
    type: NgModule,
    args: [{
      imports: [MaskitoDirective, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, TuiLegacyDropdownOpenMonitorDirective, ...TuiDropdown],
      declarations: [TuiInputPhoneComponent, TuiInputPhoneDirective],
      exports: [TuiInputPhoneComponent, TuiInputPhoneDirective, TuiTextfieldComponent, ...TuiDropdown]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_PHONE_DEFAULT_OPTIONS, TUI_INPUT_PHONE_OPTIONS, TuiInputPhoneComponent, TuiInputPhoneDirective, TuiInputPhoneModule, tuiInputPhoneOptionsProvider };
