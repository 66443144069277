import { NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	inject,
	OnInit,
} from '@angular/core';
import { where } from '@angular/fire/firestore';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import {
	DestroyService,
	DialogBase,
	DialogOptions,
} from '@context/frontend/common';
import { ContentService } from '@context/frontend/content';
import { getUaDevice } from '@context/frontend/native/platform';
import {
	PaginatedObject,
	PaginatedPageItem,
	PaginationPage,
} from '@context/frontend/pagination';
import { TableComponent } from '@context/frontend/table';
import { Content } from '@context/shared/types/common';
import {
	getName,
	getUserStatus,
	hasInheritedRolePermission,
	User,
} from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiLoader, TuiTextfield } from '@taiga-ui/core';
import { TuiButtonLoading, TuiRadio } from '@taiga-ui/kit';
import { DocumentReference } from 'firebase/firestore';
import { debounceTime, takeUntil, tap } from 'rxjs';
import { UserRowComponent } from '../../components';
import { AuthService, UserService } from '../../services';

@Component({
	standalone: true,
	imports: [
		TranslocoModule,
		TuiButton,
		TuiButtonLoading,
		TuiTextfield,
		FormsModule,
		ReactiveFormsModule,
		NgTemplateOutlet,
		UserRowComponent,
		TuiLoader,
		TableComponent,
		TuiIcon,
		TuiRadio,
	],
	selector: 'ctx-change-owner-dialog',
	templateUrl: 'change-owner-dialog.component.html',
	styleUrl: 'change-owner-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class ChangeOwnerDialogComponent
	extends DialogBase<
		{ content: Content; selected: DocumentReference<User>[] },
		{ content: Content }
	>
	implements OnInit
{
	static override readonly DialogOptions = {
		label: 'content.change-owner',
		size: getUaDevice() === 'mobile' ? 'fullscreen' : 'm',
	} as DialogOptions;

	@HostBinding('attr.device')
	readonly device = getUaDevice();

	form: FormGroup | null = null;

	searching = false;
	loading = false;
	busy = false;

	private readonly alert = inject(AlertService);
	private readonly contentService = inject(ContentService);
	private readonly authService = inject(AuthService);
	private readonly userService = inject(UserService);
	private readonly cdRef = inject(ChangeDetectorRef);
	private readonly destroy$ = inject(DestroyService);

	filteredUsers: PaginatedPageItem<User>[] = [];
	users = new PaginatedObject<User>({
		sortBy: 'givenName',
		sortDir: 'asc',
		pageSize: 100,
	});

	readonly placeholder = {
		icon: '@tui.search',
		header: 'data.search-placeholder-header',
		content: 'data.search-placeholder-content',
	};

	readonly placeholderUsers = {
		icon: '@tui.users',
		header: 'content.users-table-placeholder-header',
		content: 'content.users-table-placeholder-content',
	};

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		this.loading = true;

		await this.onLoadUsers();

		const contentId = this.context.data.content.createdBy.id;
		this.form = new FormGroup({
			search: new FormControl(),
			selected: new FormControl(contentId),
		});

		this.loading = false;
		this.cdRef.detectChanges();

		this.form
			.get('search')
			?.valueChanges.pipe(
				tap(() => {
					this.searching = true;
				}),
				takeUntil(this.destroy$),
				debounceTime(250),
			)
			.subscribe((term) => {
				this.searching = false;
				this.onSetFiltered(term);
			});
	}

	onLoadUsers() {
		// only supers are allow to set to another super
		const user = this.authService.user;
		if (!user || !hasInheritedRolePermission(user.role, 'super')) {
			this.users.update({
				constraints: [where('role', '!=', 'super')],
			});
		}

		return this.userService.fetch(this.users).then((value) => {
			this.users.update(value);
			this.filteredUsers = this.onFilterUsers(
				this.form?.get('search')?.value,
				value.page,
			);
		});
	}

	getSelected() {
		if (!this.form) return new Map<string, DocumentReference<User>>();
		const formValue = this.form.get('selected')?.getRawValue();
		return this.users.items.reduce((value, current) => {
			if (formValue[current.id] === true)
				value.set(current.id, current.ref);
			return value;
		}, new Map<string, DocumentReference<User>>());
	}

	onSetFiltered(term: string) {
		this.filteredUsers = this.onFilterUsers(term, this.users.page);
		this.cdRef.detectChanges();
	}

	onFilterUsers = (
		term: string | null,
		page: PaginationPage<User> | null,
	): PaginatedPageItem<User>[] =>
		(page?.items ?? []).reduce((value, current) => {
			term = term?.trim().toLowerCase() ?? '';
			const user = current.data;

			const status = getUserStatus(user);
			if (status !== 'enabled') return value;

			if (
				!term ||
				getName(user).toLowerCase().includes(term) ||
				user.email.toLowerCase().includes(term) ||
				user.role.toLowerCase().includes(term) ||
				user.id.toLowerCase().includes(term)
			) {
				value.push(current);
			}

			return value;
		}, [] as PaginatedPageItem<User>[]);

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		this.form.disable();
		this.busy = true;
		const reset = () => {
			this.busy = false;
			this.form?.enable();
		};

		const { selected } = this.form.getRawValue();
		if (!selected) return reset();

		const ref = this.users.items.find((i) => i.id === selected)?.ref;
		if (!ref) return reset();

		return this.contentService
			.updateOwner(this.context.data.content, ref)
			.then((content) => {
				this.onDismiss({ content: content as Content, selected });
				this.alert.open('generic-success').subscribe();
			})
			.catch((error) => {
				this.alert.open('generic-error').subscribe();
				console.error(error);
				throw new Error(
					'There was an error sharing with the selected users',
				);
			})
			.finally(() => reset());
	}
}
