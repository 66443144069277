import { Pipe, PipeTransform } from '@angular/core';
import { getUserStatus, User, UserStatusMap } from '@context/shared/types/user';

@Pipe({
	name: 'userStatus',
	standalone: true,
})
export class UserStatusPipe implements PipeTransform {
	transform(user: User | null) {
		if (!user) return null;
		const status = getUserStatus(user);
		return UserStatusMap[status];
	}
}
