/**
 * Takes the payload provided and formats it before being added into the database. This includes
 * removing all undefined values (Firestore requirement), and converting all known time based
 * props into a native Date object (which converts to Firestore Timestamp class in the database).
 * This is to ensure no time property is stripped of it's Timestamp class and can be queried properly
 * from the backend.
 *
 * @note if you are sending data through an HTTP request then setting the `convertTimestamps` to `true`
 * is paramount. That will ensure the Timestamp object will be set properly, otherwise the class will be stripped
 * and only the `{_seconds, _nanoseconds}` will be saved in place of the timestamp.
 *
 * @param data the data to sanitize before adding to the database
 * @param options additional options to configure the specifics of the sanitizing
 * @returns the newly sanitized payload
 */
export function sanitize<T = never>(
	data: T,
	options?: {
		convertTimestamps: boolean;
	},
): T {
	const convertTimestamps = options?.convertTimestamps ?? false;
	for (const key in data) {
		const value: any = data[key];

		// This is checking for `DocumentReference`s. It includes an instance
		// of `Firestore` on the object. If so, we should leave it along to do it's job
		if (typeof value === 'object' && value?.firestore) continue;
		// This is checking for built in FieldValues from Firebase.
		// If so, we should leave it alone to do it's job
		else if (typeof value === 'object' && value?._methodName) continue;
		else if (typeof value === 'undefined' || value === undefined) {
			delete data[key];
		}
		// This checks for built-in `Timestamp` objects. We need to convert these over
		// to the native Date object for transferring over through JSON based requests
		else if (value !== null && value.toDate) {
			// only sanitize it if the options include it
			if (!convertTimestamps) continue;

			let milliseconds = undefined;
			if (typeof value === 'number') {
				const isSeconds = `${value}`.length === 10 ? true : false;
				milliseconds = isSeconds ? value * 1000 : value;
			} else if (typeof value === 'string') {
				milliseconds = new Date(value).valueOf();
			} else if (value.seconds || value._seconds) {
				milliseconds = (value.seconds ?? value._seconds) * 1000;
			}

			if (milliseconds) data[key] = new Date(milliseconds) as any;
		} else if (value !== null && Array.isArray(value)) {
			data[key] = sanitize(value) as any;
		} else if (
			value !== null &&
			typeof value === 'object' &&
			Object.keys(value).length
		) {
			data[key] = sanitize({ ...value });
		}
	}
	return data;
}

// These are not necessarily all of the codes, just the known codes we handle individually
const FirebaseCodes = [
	'auth/invalid-credential',
	'auth/account-disabled',
] as const;

// eslint-disable-next-line @typescript-eslint/ban-types
export type FirebaseCode = (typeof FirebaseCodes)[number] | (string & {});

const FirebaseCodeMap = new Map<FirebaseCode, string>([
	['auth/invalid-credential', 'auth.error.invalid-credential'],
	['auth/account-disabled', 'auth.error.account-disabled'],
]);

const DefaultErrorKey = 'error.firebase-api';
export function getFirebaseErrorKey(code: FirebaseCode) {
	return FirebaseCodeMap.get(code) ?? DefaultErrorKey;
}
