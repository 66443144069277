import { inject, Pipe, PipeTransform } from '@angular/core';
import { Content, ViewAccess } from '@context/shared/types/common';
import { ORGANIZATION_ID } from '../tokens';

@Pipe({
	standalone: true,
	name: 'backUrl',
})
export class BackUrlPipe implements PipeTransform {
	readonly organizationId = inject(ORGANIZATION_ID);

	transform(
		content: Content,
		options?: { toContent: boolean; access?: ViewAccess },
	) {
		const isViewOnly = options?.access === 'view';
		const toContent = options?.toContent ?? false;

		if (isViewOnly) {
			return [
				'/external',
				this.organizationId.value,
				'view',
				content.type,
				content.externalId,
			].join('/');
		}

		const now = new Date().valueOf();
		if (content.archivedAt && content.archivedAt.toDate().valueOf() < now) {
			return '/archive';
		}

		const url = ['/home'];
		if (toContent) url.push(content.type, content.id);
		else if (content.parent) url.push('folder', content.parent.id);

		return url.join('/');
	}
}
