import { DocumentReference } from 'firebase/firestore';
import { Entity, Thumbnail } from '../types';

export function createThumbnail(payload: Partial<Thumbnail>): Thumbnail {
	if (payload.derived === 'self' || !payload.derived) {
		return {
			...payload,
			storagePath: payload.storagePath ?? '', // provided by implementation
			maxResolution: payload.maxResolution ?? 2048,
			derived: payload.derived ?? 'self',
			ref: null,
		};
	}

	return {
		...payload,
		storagePath: payload.storagePath ?? '', // provided by implementation
		maxResolution: payload.maxResolution ?? 2048,
		derived: payload.derived,
		ref: payload.ref as DocumentReference<Entity>,
	};
}
