export const AcceptedImages = ['image/*'] as const;

export const AcceptedFiles = [
	...AcceptedImages,
	'.pdf',
	'.doc',
	'.csv',
	'.eps',
	'video/*',

	// catchall for utility files (sheets, docs, etc)
	// todo: refine this
	'application/*',
] as const;
