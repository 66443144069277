import { Injectable } from '@angular/core';
import { CrudService } from '@context/frontend/api-client';
import { AcceptInviteDto, Invite } from '@context/shared/types/user';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InviteService extends CrudService<Invite> {
	static readonly CollectionId = 'invites';

	constructor() {
		super(InviteService.CollectionId, {
			useOrganization: false,
			version: 'v1',
		});
	}

	accept(id: string, data: AcceptInviteDto) {
		return firstValueFrom(
			this.http.post(`${this.api}/v1/users/invite/${id}/accept`, data),
		);
	}

	reject(id: string) {
		return firstValueFrom(
			this.http.get(`${this.api}/v1/users/invite/${id}/reject`),
		);
	}
}
