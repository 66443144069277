import * as i0 from '@angular/core';
import { inject, Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { tuiGetClosestFocusable } from '@taiga-ui/cdk/utils/focus';
import { TuiDropdownOpen, TuiDropdownOpenLegacy } from '@taiga-ui/core/directives/dropdown';
import { distinctUntilChanged } from 'rxjs';
class TuiLegacyDropdownOpenMonitorDirective {
  constructor() {
    this.el = tuiInjectElement();
    this.host = inject(TuiDropdownOpen, {
      self: true
    });
    this.external = inject(TuiDropdownOpenLegacy, {
      optional: true
    });
    this.$ = this.host.driver.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe(open => this.external?.tuiDropdownOpenChange.next(open));
    this.$1 = this.external?.tuiDropdownOpenChange.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe(open => {
      if (open) {
        tuiGetClosestFocusable({
          initial: this.el,
          root: this.el
        })?.focus();
      }
      this.host.toggle(open);
    });
  }
  static {
    this.ɵfac = function TuiLegacyDropdownOpenMonitorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiLegacyDropdownOpenMonitorDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiLegacyDropdownOpenMonitorDirective,
      selectors: [["", "tuiDropdownOpenMonitor", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLegacyDropdownOpenMonitorDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiDropdownOpenMonitor]'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiLegacyDropdownOpenMonitorDirective };
