import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertService } from '@context/frontend/alert';
import { User } from '@context/shared/types/user';
import { DocumentReference } from 'firebase/firestore';
import { UserService } from '../services';

type UserResolveData = {
	data: User;
	ref: DocumentReference<User>;
};

@Injectable({ providedIn: 'root' })
export class UserDetailResolver implements Resolve<UserResolveData | null> {
	readonly userService = inject(UserService);
	readonly router = inject(Router);
	readonly alert = inject(AlertService);

	async resolve(route: ActivatedRouteSnapshot): Promise<UserResolveData> {
		const id = route.paramMap.get('id');
		if (!id) return this.redirect();

		const snapshot = await this.userService
			.findById(id)
			.catch(() => this.redirect());

		return {
			ref: snapshot.ref,
			data: snapshot.data() as User,
		};
	}

	redirect() {
		return this.router.navigate(['/users']).then(() => {
			this.alert.open('user-not-found').subscribe();
			return Promise.reject();
		});
	}
}
