import { Pipe, PipeTransform } from '@angular/core';
import { ContentLog } from '@context/shared/types/log';
import { DocumentReference } from 'firebase/firestore';

@Pipe({
	standalone: true,
	name: 'logContentUrl',
})
export class LogContentUrlPipe implements PipeTransform {
	transform(ref: DocumentReference<ContentLog>) {
		const parentId = ref.parent.parent?.id;
		if (!parentId) return null;

		return `/home/file/${parentId}`;
	}
}
