<section tuiContent>
	<p>{{ 'auth.sign-out-description' | transloco }}</p>
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss(false)">
		{{ 'action.no' | transloco }}
	</button>
	<button
		tuiButton
		type="submit"
		appearance="accent"
		(click)="onDismiss(true)"
	>
		{{ 'action.yes' | transloco }}
	</button>
</footer>
