<div class="table__content">
	@if (loading) {
		<ctx-table-loader></ctx-table-loader>
	} @else {
		@if (empty) {
			<ctx-table-placeholder
				[icon]="placeholder.icon"
				[header]="placeholder.header | transloco"
				[content]="placeholder.content | transloco"
			></ctx-table-placeholder>
		} @else {
			<div class="table__content-items">
				<ng-content></ng-content>
			</div>
		}
	}
</div>

<!-- todo think about mobile infinite scroll vs pagination -->
@if (!empty && pagination) {
	<footer class="table__footer">
		@if (details) {
			<p class="margin-0 table__footer-of">
				{{ 'pagination.of' | transloco: details }}
			</p>
		}

		<tui-pagination
			[index]="index"
			[length]="length"
			[activePadding]="0"
			[sidePadding]="-1"
			(indexChange)="onIndexChange($event)"
		></tui-pagination>

		<div class="table__footer-sizer">
			<tui-select
				tuiTextfieldSize="s"
				appearance="ghost"
				[formControl]="sizeControl"
				[valueContent]="sizeTemplate"
			>
				<input tuiTextfieldLegacy />

				<tui-data-list-wrapper
					*tuiDataList
					[items]="sizes"
				></tui-data-list-wrapper>

				<ng-template #sizeTemplate let-size>
					{{ 'pagination.per-page' | transloco: { size } }}
				</ng-template>
			</tui-select>
		</div>
	</footer>
}
