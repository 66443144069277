import * as i0 from '@angular/core';
import { Directive } from '@angular/core';

/**
 * Blank directive for queries via `@ContentChildren` / `@ViewChildren` / `querySelector`
 */
class TuiItem {
  static {
    this.ɵfac = function TuiItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiItem)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiItem,
      selectors: [["", "tuiItem", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItem, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiItem]'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiItem };
