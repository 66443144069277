import { Component, Input } from '@angular/core';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { DetailMenuItem } from '@context/shared/types/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [TranslocoModule, TuiButton, TuiIcon],
	selector: 'ctx-detail-menu-dialog',
	templateUrl: 'detail-menu-dialog.component.html',
})
export class DetailMenuDialogComponent extends DialogBase<
	DetailMenuItem,
	{ menuItems: DetailMenuItem[] }
> {
	static override readonly DialogOptions = { size: 's' } as DialogOptions;

	@Input()
	menuItems: DetailMenuItem[] = [];

	onClick(item: DetailMenuItem) {
		item.handler();
		this.context.completeWith(item);
	}
}
