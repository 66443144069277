<section tuiContent>
	@if (error) {
		<tui-notification appearance="error">
			{{ 'form.error.image-cropper.load' | transloco }}
		</tui-notification>
	} @else if (context.data.imageFile) {
		@if (loading) {
			<div class="image-cropper-dialog__loader">
				<tui-loader size="xl" />
			</div>
		}

		<image-cropper
			[imageFile]="context.data.imageFile"
			[maintainAspectRatio]="true"
			[aspectRatio]="1 / 1"
			format="png"
			[disabled]="loading"
			(imageCropped)="onImageCropped($event)"
			(imageLoaded)="onImageLoaded()"
			(cropperReady)="onCropperReady()"
			(loadImageFailed)="onLoadImageFailed()"
		></image-cropper>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>
	<button
		tuiButton
		appearance="accent"
		(click)="onSave()"
		[disabled]="loading || error"
	>
		{{ 'action.save' | transloco }}
	</button>
</footer>
