import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	GuardResult,
	MaybeAsync,
	Router,
} from '@angular/router';
import { hasInheritedRolePermission } from '@context/shared/types/user';
import { Subject } from 'rxjs';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class RoleAccessGuard implements CanActivate {
	readonly resolved$ = new Subject<boolean>();

	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}

	canActivate(route: ActivatedRouteSnapshot): MaybeAsync<GuardResult> {
		const requirement = route.data['role'];
		if (!requirement) return this.routeToHome();

		const role = this.authService.user?.role;
		if (!role) return this.routeToHome();

		const hasPermission = hasInheritedRolePermission(role, requirement);
		if (!hasPermission) return this.routeToHome();

		return true;
	}

	routeToHome() {
		return this.router.navigate(['/home']);
	}
}
