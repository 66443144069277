<ctx-user-row
	size="s"
	[user]="user"
	[interactable]="false"
	[showStatus]="false"
>
	<button
		end
		tuiButton
		appearance="ghost"
		iconStart="@tui.trash"
		size="xs"
		[loading]="busy"
		[disabled]="busy"
		(click)="onRemove()"
	>
		{{ 'action.remove' | transloco }}
	</button>
</ctx-user-row>
