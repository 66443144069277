import * as i0 from '@angular/core';
import { inject, DestroyRef, NgZone, Directive, Input } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { tuiZonefree, tuiTakeUntilDestroyed } from '@taiga-ui/cdk/observables';
import { tuiInjectElement, tuiProvide } from '@taiga-ui/cdk/utils';
import { timer } from 'rxjs';
class TuiNativeValidator {
  constructor() {
    this.destroyRef = inject(DestroyRef);
    this.zone = inject(NgZone);
    this.el = tuiInjectElement();
    this.tuiNativeValidator = 'Invalid';
  }
  validate(control) {
    this.control = control;
    timer(0).pipe(tuiZonefree(this.zone), tuiTakeUntilDestroyed(this.destroyRef)).subscribe(() => this.handleValidation());
    return null;
  }
  handleValidation() {
    this.el.setCustomValidity?.(this.control?.touched && this.control?.invalid ? this.tuiNativeValidator : '');
  }
  static {
    this.ɵfac = function TuiNativeValidator_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiNativeValidator)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiNativeValidator,
      selectors: [["", "tuiNativeValidator", ""]],
      hostBindings: function TuiNativeValidator_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusout", function TuiNativeValidator_focusout_HostBindingHandler() {
            return ctx.handleValidation();
          });
        }
      },
      inputs: {
        tuiNativeValidator: "tuiNativeValidator"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiProvide(NG_VALIDATORS, TuiNativeValidator, true)])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeValidator, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiNativeValidator]',
      providers: [tuiProvide(NG_VALIDATORS, TuiNativeValidator, true)],
      host: {
        '(focusout)': 'handleValidation()'
      }
    }]
  }], null, {
    tuiNativeValidator: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiNativeValidator };
