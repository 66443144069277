import { Component, Input } from '@angular/core';
import { DetailMenuItem } from '@context/shared/types/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiDataList } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [TuiDataList, TranslocoModule],
	selector: 'ctx-detail-menu-dropdown',
	templateUrl: 'detail-menu-dropdown.component.html',
})
export class DetailMenuDropdownComponent {
	@Input()
	menuItems: DetailMenuItem[] = [];
}
