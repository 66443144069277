import * as i1 from '@angular/common';
import { NgIf, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Injectable, Directive, INJECTOR, Injector, ViewEncapsulation } from '@angular/core';
import { toSignal, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { tuiFadeIn, tuiSlideIn, tuiHeightCollapse, tuiParentAnimation } from '@taiga-ui/core/animations';
import { TuiButton } from '@taiga-ui/core/components/button';
import { TUI_NOTIFICATION_OPTIONS, TuiNotification } from '@taiga-ui/core/components/notification';
import { TuiTitle } from '@taiga-ui/core/directives/title';
import { TUI_COMMON_ICONS, TUI_ANIMATIONS_SPEED, TUI_CLOSE_WORD } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils';
import { injectContext, PolymorpheusOutlet, POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { BehaviorSubject, combineLatest, of, map, switchMap, timer, EMPTY, takeUntil, fromEvent, repeat, identity } from 'rxjs';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import { tuiCreateTokenFromFactory, tuiCreateToken } from '@taiga-ui/cdk/utils/miscellaneous';
import { TuiPopoverDirective } from '@taiga-ui/cdk/directives/popover';
import { TuiPopoverService, tuiAsPopover } from '@taiga-ui/cdk/services';
import { TuiMapperPipe } from '@taiga-ui/cdk/pipes/mapper';
function TuiAlertComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
function TuiAlertComponent_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("innerHTML", text_r2, i0.ɵɵsanitizeHtml);
  }
}
function TuiAlertComponent_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function TuiAlertComponent_button_5_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.item.$implicit.complete());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("iconStart", ctx_r3.icons.close);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r3.close(), " ");
  }
}
function TuiAlerts_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 3);
    i0.ɵɵpipe(1, "tuiMapper");
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngComponentOutlet", item_r1.component.component)("ngComponentOutletInjector", i0.ɵɵpipeBind2(1, 2, item_r1, ctx_r1.mapper));
  }
}
function TuiAlerts_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, TuiAlerts_div_0_ng_container_1_Template, 2, 5, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const group_r3 = ctx.$implicit;
    i0.ɵɵproperty("@tuiParentAnimation", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", group_r3);
  }
}
const TUI_ALERT_DEFAULT_OPTIONS = {
  autoClose: 3000,
  label: '',
  closeable: true,
  data: undefined
};
const TUI_ALERT_OPTIONS = tuiCreateTokenFromFactory(() => ({
  ...TUI_ALERT_DEFAULT_OPTIONS,
  ...inject(TUI_NOTIFICATION_OPTIONS)
}));
const TUI_ALERT_POSITION = tuiCreateTokenFromFactory(() => inject(TUI_IS_MOBILE) ? '1rem 1rem 0 auto' : '2rem 3rem 0 auto');
const TUI_ALERTS = tuiCreateToken(new BehaviorSubject([]));
/**
 * Grouping alerts by their component
 */
const TUI_ALERTS_GROUPED = tuiCreateTokenFromFactory(() => combineLatest([of(new Map()), inject(TUI_ALERTS)]).pipe(map(([map, alerts]) => {
  map.forEach((_, key) => map.set(key, []));
  alerts.forEach(alert => {
    const key = alert.component.component;
    const value = map.get(key) || [];
    map.set(key, [...value, alert]);
  });
  return Array.from(map.values());
})));
class TuiAlertComponent {
  constructor() {
    this.el = tuiInjectElement();
    this.icons = inject(TUI_COMMON_ICONS);
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.close = toSignal(inject(TUI_CLOSE_WORD));
    this.position = inject(TUI_ALERT_POSITION);
    this.item = injectContext();
    this.animation = this.position.endsWith('auto') ? {
      ...this.options,
      value: 'right'
    } : {
      ...this.options,
      value: 'left'
    };
    this.sub = of(typeof this.item.autoClose === 'function' ? this.item.autoClose(this.item.appearance) : this.item.autoClose).pipe(switchMap(autoClose => autoClose ? timer(autoClose) : EMPTY), takeUntil(fromEvent(this.el, 'mouseenter')), repeat({
      delay: () => fromEvent(this.el, 'mouseleave')
    }), takeUntilDestroyed()).subscribe(() => this.item.$implicit.complete());
  }
  static {
    this.ɵfac = function TuiAlertComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiAlertComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiAlertComponent,
      selectors: [["tui-alert"]],
      hostAttrs: ["role", "alert"],
      hostVars: 5,
      hostBindings: function TuiAlertComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@tuiFadeIn", ctx.options)("@tuiSlideIn", ctx.animation)("@tuiHeightCollapse", ctx.animation);
          i0.ɵɵstyleProp("margin", ctx.position);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 7,
      consts: [["size", "m", 3, "appearance", "icon"], ["tuiTitle", ""], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["tuiSubtitle", ""], [3, "innerHTML", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["tuiIconButton", "", "type", "button", 3, "iconStart", "click", 4, "ngIf"], [3, "innerHTML"], ["tuiIconButton", "", "type", "button", 3, "click", "iconStart"]],
      template: function TuiAlertComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "tui-notification", 0)(1, "span", 1);
          i0.ɵɵtemplate(2, TuiAlertComponent_ng_container_2_Template, 2, 1, "ng-container", 2);
          i0.ɵɵelementStart(3, "span", 3);
          i0.ɵɵtemplate(4, TuiAlertComponent_span_4_Template, 1, 1, "span", 4);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(5, TuiAlertComponent_button_5_Template, 2, 2, "button", 5);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("appearance", ctx.item.appearance)("icon", ctx.item.icon);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("polymorpheusOutlet", ctx.item.label)("polymorpheusOutletContext", ctx.item);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("polymorpheusOutlet", ctx.item.content)("polymorpheusOutletContext", ctx.item);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.item.closeable);
        }
      },
      dependencies: [NgIf, PolymorpheusOutlet, TuiButton, TuiNotification, TuiTitle],
      styles: ["[_nghost-%COMP%]{display:block;inline-size:18rem;flex-shrink:0;word-break:break-word;background:var(--tui-background-elevation-1);border-radius:var(--tui-radius-m);box-shadow:var(--tui-shadow-medium)}[_nghost-%COMP%]:not(:first-child){margin-top:.75rem!important}[_nghost-%COMP%]:not(:last-child){margin-bottom:0!important}"],
      data: {
        animation: [tuiFadeIn, tuiSlideIn, tuiHeightCollapse]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-alert',
      imports: [NgIf, PolymorpheusOutlet, TuiButton, TuiNotification, TuiTitle],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiFadeIn, tuiSlideIn, tuiHeightCollapse],
      host: {
        role: 'alert',
        '[style.margin]': 'position',
        '[@tuiFadeIn]': 'options',
        '[@tuiSlideIn]': 'animation',
        '[@tuiHeightCollapse]': 'animation'
      },
      template: "<tui-notification\n    size=\"m\"\n    [appearance]=\"item.appearance\"\n    [icon]=\"item.icon\"\n>\n    <span tuiTitle>\n        <ng-container *polymorpheusOutlet=\"item.label as text; context: item\">\n            {{ text }}\n        </ng-container>\n        <span tuiSubtitle>\n            <span\n                *polymorpheusOutlet=\"item.content as text; context: item\"\n                [innerHTML]=\"text\"\n            ></span>\n        </span>\n    </span>\n    <button\n        *ngIf=\"item.closeable\"\n        tuiIconButton\n        type=\"button\"\n        [iconStart]=\"icons.close\"\n        (click)=\"item.$implicit.complete()\"\n    >\n        {{ close() }}\n    </button>\n</tui-notification>\n",
      styles: [":host{display:block;inline-size:18rem;flex-shrink:0;word-break:break-word;background:var(--tui-background-elevation-1);border-radius:var(--tui-radius-m);box-shadow:var(--tui-shadow-medium)}:host:not(:first-child){margin-top:.75rem!important}:host:not(:last-child){margin-bottom:0!important}\n"]
    }]
  }], null, null);
})();
class TuiAlertService extends TuiPopoverService {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiAlertService_BaseFactory;
      return function TuiAlertService_Factory(__ngFactoryType__) {
        return (ɵTuiAlertService_BaseFactory || (ɵTuiAlertService_BaseFactory = i0.ɵɵgetInheritedFactory(TuiAlertService)))(__ngFactoryType__ || TuiAlertService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiAlertService,
      factory: () => (() => new TuiAlertService(TUI_ALERTS, TuiAlertComponent, inject(TUI_ALERT_OPTIONS)))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertService, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => new TuiAlertService(TUI_ALERTS, TuiAlertComponent, inject(TUI_ALERT_OPTIONS))
    }]
  }], null, null);
})();
class TuiAlert extends TuiPopoverDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiAlert_BaseFactory;
      return function TuiAlert_Factory(__ngFactoryType__) {
        return (ɵTuiAlert_BaseFactory || (ɵTuiAlert_BaseFactory = i0.ɵɵgetInheritedFactory(TuiAlert)))(__ngFactoryType__ || TuiAlert);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiAlert,
      selectors: [["ng-template", "tuiAlert", ""]],
      inputs: {
        options: [0, "tuiAlertOptions", "options"],
        open: [0, "tuiAlert", "open"]
      },
      outputs: {
        openChange: "tuiAlertChange"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsPopover(TuiAlertService)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlert, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-template[tuiAlert]',
      inputs: ['options: tuiAlertOptions', 'open: tuiAlert'],
      outputs: ['openChange: tuiAlertChange'],
      providers: [tuiAsPopover(TuiAlertService)]
    }]
  }], null, null);
})();
class TuiAlerts {
  constructor() {
    this.injector = inject(INJECTOR);
    this.alerts$ = inject(TUI_ALERTS_GROUPED);
    this.trackBy = identity;
    this.mapper = useValue => Injector.create({
      providers: [{
        provide: POLYMORPHEUS_CONTEXT,
        useValue
      }],
      parent: this.injector
    });
  }
  static {
    this.ɵfac = function TuiAlerts_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiAlerts)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiAlerts,
      selectors: [["tui-alerts"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 4,
      consts: [["class", "t-wrapper", 4, "ngFor", "ngForOf", "ngForTrackBy"], [1, "t-wrapper"], [3, "ngComponentOutlet", "ngComponentOutletInjector", 4, "ngFor", "ngForOf"], [3, "ngComponentOutlet", "ngComponentOutletInjector"]],
      template: function TuiAlerts_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiAlerts_div_0_Template, 2, 2, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(1, 2, ctx.alerts$))("ngForTrackBy", ctx.trackBy);
        }
      },
      dependencies: [CommonModule, i1.NgComponentOutlet, i1.NgForOf, i1.AsyncPipe, TuiMapperPipe],
      styles: ["tui-alerts>.t-wrapper{position:fixed;top:0;left:0;inline-size:100%;block-size:100%;display:flex;flex-direction:column;pointer-events:none}tui-alerts>.t-wrapper>*{pointer-events:auto}\n"],
      encapsulation: 2,
      data: {
        animation: [tuiParentAnimation]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlerts, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-alerts',
      imports: [CommonModule, TuiMapperPipe],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.Default,
      animations: [tuiParentAnimation],
      template: "<div\n    *ngFor=\"let group of alerts$ | async; trackBy: trackBy\"\n    class=\"t-wrapper\"\n    @tuiParentAnimation\n>\n    <ng-container\n        *ngFor=\"let item of group\"\n        [ngComponentOutlet]=\"item.component.component\"\n        [ngComponentOutletInjector]=\"item | tuiMapper: mapper\"\n    />\n</div>\n",
      styles: ["tui-alerts>.t-wrapper{position:fixed;top:0;left:0;inline-size:100%;block-size:100%;display:flex;flex-direction:column;pointer-events:none}tui-alerts>.t-wrapper>*{pointer-events:auto}\n"]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ALERTS, TUI_ALERTS_GROUPED, TUI_ALERT_DEFAULT_OPTIONS, TUI_ALERT_OPTIONS, TUI_ALERT_POSITION, TuiAlert, TuiAlertComponent, TuiAlertService, TuiAlerts };
