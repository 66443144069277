export enum LanguageCode {
	AUTO = 'auto',
	EN = 'en',
	JA = 'ja',
}

export type RealLanguageCode = Omit<LanguageCode, LanguageCode.AUTO>;

/**
 * Used to iterate through any deeply nested object and flatten it out into dot notation
 */
export type FlattenDotNotation<
	T extends object | string,
	P extends string | null = null,
> = T extends string
	? T
	: T extends object
		? {
				[K in keyof T]: K extends string
					? T[K] extends string
						? P extends null
							? K
							: `${P}.${K}`
						: T[K] extends object
							? P extends null
								? FlattenDotNotation<T[K], K>
								: FlattenDotNotation<T[K], `${P}.${K}`>
							: never
					: never;
			}[keyof T]
		: never;
