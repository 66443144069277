import { inject, Injectable } from '@angular/core';
import {
	Auth,
	EmailAuthProvider,
	User as FirebaseUser,
	reauthenticateWithCredential,
	sendEmailVerification,
	signInWithEmailAndPassword,
	signOut,
	verifyBeforeUpdateEmail,
} from '@angular/fire/auth';
import {
	collection,
	CollectionReference,
	doc,
	DocumentReference,
	Firestore,
	getDoc,
	updateDoc,
} from '@angular/fire/firestore';
import { ORGANIZATION_ID, USER } from '@context/frontend/common';
import { Organization } from '@context/shared/types/organization';
import {
	AuthUser,
	getUserStatus,
	OrgUser,
	User,
	UserStatus,
} from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import { onAuthStateChanged } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
	firebaseUser: FirebaseUser | null = null;

	authUser: AuthUser | null = null;
	authUserRef: DocumentReference<AuthUser> | null = null;

	user: User | null = null;
	userRef: DocumentReference<User> | null = null;

	organization: Organization | null = null;
	organizationRef: DocumentReference<Organization> | null = null;

	authenticated = new BehaviorSubject<boolean>(false);

	private readonly organizationId$ = inject(ORGANIZATION_ID);
	private readonly user$ = inject(USER);

	constructor(
		private readonly auth: Auth,
		private readonly firestore: Firestore,
	) {
		onAuthStateChanged(this.auth, this.onAuthStateChanged.bind(this));
	}

	async onAuthStateChanged(firebaseUser: FirebaseUser | null) {
		if (!firebaseUser) {
			this.reset();
			return;
		}

		this.firebaseUser = firebaseUser;

		const authUserCollection = collection(
			this.firestore,
			'authUsers',
		) as CollectionReference<AuthUser>;
		this.authUserRef = doc(authUserCollection, this.firebaseUser.uid);
		const authSnapshot = await getDoc(this.authUserRef);

		if (!authSnapshot.exists())
			throw new Error('Auth user could not be found');

		this.authUser = authSnapshot.data() as AuthUser;
		this.determineOrganization(this.authUser);
	}

	async determineOrganization(authUser: AuthUser) {
		const hasMany = authUser.users.length > 1;
		// @todo implement organization selector
		if (hasMany)
			console.warn(
				'Organization selection is not supported yet, taking first object',
			);

		const { user, organization }: OrgUser = authUser.users[0];

		this.organizationRef = organization as DocumentReference<Organization>;
		const organizationSnapshot = await getDoc(this.organizationRef);

		// @todo implement no organization state
		if (!organizationSnapshot || !organizationSnapshot.exists())
			return Promise.reject('No organization could be determined');

		this.userRef = user as DocumentReference<User>;
		this.organization = organizationSnapshot.data() as Organization;
		this.organizationId$.next(this.organization.id);

		this.user = (await getDoc(this.userRef)).data() as User;
		this.user$.next(this.user);

		this.applyFirebaseUserData();
		this.authenticated.next(true);
	}

	/**
	 * Applies the needed information from the FirebaseUser data to the User and AuthUser
	 * objects. This ensures the email is correct regardless of how it was changed.
	 */
	applyFirebaseUserData() {
		if (!this.firebaseUser || !this.user || !this.authUser) return;
		this.user.emailVerified = this.firebaseUser.emailVerified ?? false;
	}

	/**
	 * Signs the user into the app with the provided email and password. We will wait until
	 * the authenticated flag has been set to `true` to ensure the user doesn't enter the app
	 * while information is still being gathered.
	 *
	 * @note any other authentication means need to also support the waiting on `authenticated`
	 * value for the reasons detailed above.
	 *
	 * @param email the email to sign in with
	 * @param password the password to sign in with
	 */
	signInWithEmailAndPassword(email: string, password: string) {
		const resolved$ = new Subject<boolean>();
		return signInWithEmailAndPassword(this.auth, email, password).then(
			() =>
				new Promise((resolve, reject) => {
					this.authenticated
						.pipe(takeUntil(resolved$))
						.subscribe((value) => {
							if (value) {
								const code = this.getAccountStatus();

								if (code === 'disabled') {
									this.signOut();
									reject({
										message:
											'The account has been disabled',
										code: 'auth/account-disabled',
									});
								} else if (code === 'deleted') {
									this.signOut();
									reject({
										message: 'The account has been deleted',
										code: 'auth/invalid-credential',
									});
								} else {
									resolve(true);
								}

								resolved$.next(true);
							}
						});
				}),
		);
	}

	getAccountStatus(): UserStatus | null {
		if (!this.user) return null;
		return getUserStatus(this.user);
	}

	reauthenticateWithEmailAndPassword(email: string, password: string) {
		if (!this.firebaseUser) return Promise.reject();
		const credential = EmailAuthProvider.credential(email, password);
		return reauthenticateWithCredential(this.firebaseUser, credential);
	}

	signOut() {
		return signOut(this.auth).then(() => this.reset());
	}

	reset() {
		this.organizationId$.next(null);
		this.organization = null;
		this.organizationRef = null;

		this.firebaseUser = null;

		this.authUser = null;
		this.authUserRef = null;

		this.user = null;
		this.userRef = null;
		this.user$.next(null);

		this.authenticated.next(false);
	}

	/**
	 * Updates the auth users organization specific profile with the changes. If email is updated, then
	 * it will require another sign in.
	 *
	 * @todo when implementing multiple tenants work through updating the email across multiple accounts
	 *
	 * @param data the data to update the auth user with
	 */
	async update(
		data: Partial<
			Pick<
				User,
				| 'email'
				| 'familyName'
				| 'givenName'
				| 'thumbnailUrl'
				| 'notifications'
				| 'updatedAt'
				| 'requestDeletedAt'
			>
		>,
	) {
		if (!this.userRef || !this.user || !this.firebaseUser)
			throw new Error('User ref could not be found');

		let emailUpdated = false;
		if (data.email && data.email !== this.user.email) {
			// we need to change the auth email first before attempting to update the org user object
			await this.updateEmail(data.email)?.catch((error) => {
				console.error(error);
				throw new Error(
					'AuthService.update: Email could not be updated',
				);
			});
			emailUpdated = true;
		}

		data.updatedAt = Timestamp.now();
		await updateDoc(this.userRef, sanitize(data));

		// the user needs to be signed out to apply updates
		if (emailUpdated)
			return Promise.resolve({ user: this.user, signOut: true });

		this.user = (await getDoc(this.userRef)).data() as User;
		this.applyFirebaseUserData();
		return { user: this.user, signOut: false };
	}

	sendVerificationEmail() {
		if (!this.firebaseUser) return Promise.reject();
		return sendEmailVerification(this.firebaseUser);
	}

	/**
	 * Updates the auth user data to correlate to the new email being set by the
	 * user. Currently we update ALL user accounts tied to this auth user.
	 *
	 * @todo implement account email linking/unlinking for when multi-org is available
	 *
	 * @param email the email to update the auth user with
	 */
	async updateEmail(email: string) {
		if (!this.firebaseUser || !this.authUserRef) return;
		return verifyBeforeUpdateEmail(this.firebaseUser, email);
	}
}
