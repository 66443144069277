import * as i0 from '@angular/core';
import { Pipe } from '@angular/core';
class TuiStringifyContentPipe {
  transform(stringify) {
    return ({
      $implicit
    }) => stringify($implicit);
  }
  static {
    this.ɵfac = function TuiStringifyContentPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiStringifyContentPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiStringifyContent",
      type: TuiStringifyContentPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiStringifyContentPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiStringifyContent'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiStringifyContentPipe };
