import { Directive, inject, OnInit } from '@angular/core';
import { AlertService } from '@context/frontend/alert';
import { DestroyService, DialogService } from '@context/frontend/common';
import { PlaceholderConfig } from '@context/frontend/table';
import { Entity } from '@context/shared/types/common';
import {
	PageSize,
	SortConfig,
	SortItem,
	SortOptions,
} from '@context/shared/types/pagination';
import { TranslocoService } from '@jsverse/transloco';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { SortDialogComponent } from '../../dialogs';
import { Fetchable, PaginatedObject, PaginatedObjectType } from '../../models';

@Directive()
export abstract class PaginationPageBase<ContentType extends Entity>
	implements OnInit
{
	sortItems: SortItem<ContentType>[] = [];

	activeSort!: SortItem<ContentType>;
	activeFilters: string[] = [];

	sortOpen = false;
	filterOpen = false;

	paginated!: PaginatedObject<ContentType>;

	loading = true;

	readonly dialog = inject(DialogService);
	readonly alerts = inject(AlertService);
	readonly destroy$ = inject(DestroyService);
	readonly transloco = inject(TranslocoService);

	private defaultOptions: Partial<PaginatedObjectType<ContentType>> = {
		sortBy: 'createdAt',
		sortDir: 'desc',
		constraints: [],
	};

	readonly sortDialog = () =>
		this.dialog.open<SortItem<ContentType> | null>(
			new PolymorpheusComponent(SortDialogComponent),
			SortDialogComponent.Options({
				data: { items: this.sortItems, activeItem: this.activeSort },
			}),
		);

	constructor(
		protected readonly sortConfig: SortConfig<ContentType>,
		protected readonly placeholder: PlaceholderConfig,
		protected readonly crudService?: Fetchable<ContentType>,
	) {}

	ngOnInit() {
		this.resetSort();
		this.resetFilters();
		this.onLoadItems();
	}

	setOptions(options: Partial<PaginatedObjectType<ContentType>>) {
		Object.assign(this.defaultOptions, options);
	}

	protected onLoadItems(
		options?: Partial<PaginatedObjectType<ContentType>>,
		reset?: boolean,
	) {
		if (!this.crudService)
			throw new Error(
				'Crud service has not been provided, a custom override for `onLoadItems` may be desired.',
			);

		options = { ...this.defaultOptions, ...options };
		if (this.sortConfig.enabled && this.activeSort) {
			Object.assign(options, {
				sortBy: this.activeSort.key,
				sortDir: this.activeSort.sortDir,
			});
		}

		if (!this.paginated || reset) {
			this.paginated = new PaginatedObject(options);
		} else if (this.activeSort) {
			this.paginated.update({
				sortBy: this.activeSort.key,
				sortDir: this.activeSort.sortDir,
			});
		}

		this.loading = true;
		this.crudService
			.fetch(this.paginated)
			.then((value) => {
				this.paginated.update(value);
				this.onItemsLoaded();
			})
			.catch((error) => {
				this.onItemsLoadedError();
				console.error('There was an issue loading items', error);
				this.alerts.open('loading-data').subscribe();
			})
			.finally(() => {
				this.loading = false;
			});
	}

	protected onItemsLoaded() {
		// optional override in implementation
	}

	protected onItemsLoadedError() {
		// optional override in implementation
	}

	protected onPageChange(index: number) {
		this.paginated.currentPage = index;
		this.onLoadItems();
	}

	protected onSizeChange(pageSize: PageSize) {
		this.onLoadItems({ pageSize }, true);
	}

	protected onSortOpen = () =>
		this.sortDialog().subscribe({
			next: (data) => this.onSortSelect(data),
		});

	protected onSortSelect(item: SortItem<ContentType> | null) {
		if (item) this.activeSort = item;
		this.sortOpen = false;
		this.onLoadItems({}, true);
	}

	protected resetSort() {
		if (!this.sortConfig.enabled) return;
		this.sortItems = SortOptions<ContentType>(this.sortConfig.key);
		// todo: look into saving preferences
		this.activeSort = this.sortItems[0];
	}

	protected onFilterClick() {
		// todo: add filtering logic
	}

	protected resetFilters() {
		// todo: add filtering logic
	}
}
