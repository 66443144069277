import { inject } from '@angular/core';
import { TuiDialogContext, TuiDialogOptions } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';

export type DialogOptions = Partial<TuiDialogOptions<any>>;

const DefaultOptions: DialogOptions = {
	size: 'm',
};

export class DialogBase<T = void, K = undefined> {
	/**
	 * Set the options of the dialog to be easily referenced in the dialogs implementation
	 */
	static readonly DialogOptions: DialogOptions = DefaultOptions;

	/**
	 * Quickly retrieve the dialogs options by providing a translation service
	 *
	 * @param options additional options to configure and override the default
	 */
	static Options(options?: DialogOptions) {
		return Object.assign({}, DefaultOptions, this.DialogOptions, options);
	}

	protected readonly context = inject(
		POLYMORPHEUS_CONTEXT,
	) as TuiDialogContext<T | null, K>;

	/**
	 * All dialog classes should have an implementation of a dismiss action.
	 *
	 * @param data the optional data to pass through a dismiss action
	 */
	protected onDismiss(data?: T) {
		this.context.completeWith(data ?? null);
	}
}
