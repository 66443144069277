import { Pipe, PipeTransform } from '@angular/core';

import { Device } from '@context/shared/types/native';
import { isUaDevice } from '../utils';

@Pipe({
	name: 'isDevice',
	standalone: true,
})
export class IsDevicePipe implements PipeTransform {
	transform(device: Device) {
		return isUaDevice(device);
	}
}
