import { Component, inject, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { applyValidators } from '@context/frontend/form';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiError, TuiTextfield } from '@taiga-ui/core';
import {
	DialogBase,
	DialogOptions,
} from '../dialog-base/dialog-base.component';
import { TuiButtonLoading, TuiFieldErrorPipe } from '@taiga-ui/kit';
import { AsyncPipe } from '@angular/common';
import {
	TuiInputPhoneModule,
	TuiTextareaModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { SupportService } from '../../services';
import { AlertService } from '@context/frontend/alert';

@Component({
	standalone: true,
	imports: [
		TuiButton,
		TuiTextfield,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		TuiError,
		TuiFieldErrorPipe,
		AsyncPipe,
		TuiTextfieldControllerModule,
		TuiTextareaModule,
		TuiInputPhoneModule,
		TuiButtonLoading,
	],
	selector: 'ctx-inquire-dialog',
	templateUrl: 'inquire-dialog.component.html',
})
export class InquireDialogComponent
	extends applyValidators(DialogBase)
	implements OnInit
{
	static override readonly DialogOptions = {
		label: 'support.inquire-header',
		size: 's',
	} as DialogOptions;

	readonly support = inject(SupportService);
	readonly alert = inject(AlertService);

	form: FormGroup | null = null;
	busy = false;

	ngOnInit() {
		this.setupForm();
	}

	setupForm() {
		this.form = new FormGroup({
			email: new FormControl(null, [
				(field) =>
					this.getEmailValidator(field, 'form.error.email.valid'),
				(field) =>
					this.getRequiredValidator(
						field,
						'form.error.email.required',
					),
			]),
			name: new FormControl(null),
			tel: new FormControl(),
			message: new FormControl(),
		});
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;
		this.busy = true;

		this.form.disable();
		return this.support
			.inquire(this.form.getRawValue())
			.then(() => {
				this.alert.open('inquire-sent').subscribe();
				this.onDismiss();
			})
			.catch((error) => {
				this.alert.open('generic-error').subscribe();
				console.error(error);
				throw new Error(
					'There was an error submitting the inquiry form',
				);
			})
			.finally(() => {
				this.busy = false;
				this.form?.enable();
			});
	}
}
