import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';
import { RelativeTimePipe } from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import { Content } from '@context/shared/types/common';
import { hasInheritedRolePermission, User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiHint, TuiIcon, TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';
import { ContentThumbnailComponent } from '../content-thumbnail/content-thumbnail.component';

@Component({
	standalone: true,
	imports: [
		ContentThumbnailComponent,
		RelativeTimePipe,
		TranslocoModule,
		TuiIcon,
		TuiBadge,
		TuiHint,
	],
	selector: 'ctx-content-row',
	templateUrl: 'content-row.component.html',
	styleUrl: 'content-row.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentRowComponent {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@Input()
	content!: Content;

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeL | TuiSizeS = 'l';

	@Input()
	interactable = true;

	@Input()
	label: string | null = null;

	@Input()
	user: User | null = null;

	@Input() showAccess = true;

	@HostBinding('attr.as-table-row')
	@Input()
	asTableRow = true;

	get role() {
		if (!this.user) return 'user';
		return this.user.role;
	}

	/**
	 * `true` if the content has been shared with the user
	 */
	get isShared() {
		if (!this.user) return true;
		if (this.content.createdBy?.id === this.user.id) return false;
		return this.content.shared?.some((u) => u.id === this.user?.id);
	}

	/**
	 * `true` if the currently authenticated user has created this
	 */
	get isMine() {
		if (!this.user) return false;
		return this.content.createdBy?.id === this.user.id;
	}

	get roleAccess() {
		if (!this.user) return false;
		return hasInheritedRolePermission(this.user.role, 'owner');
	}
}
