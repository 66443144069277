import { DocumentReference } from '@context/shared/types/common';
import { User } from '@context/shared/types/user';
import { ContentFile } from '../types';

export function createFile(
	payload: Partial<ContentFile>,
	userRef: DocumentReference<User>,
): Omit<
	ContentFile,
	'createdAt' | 'createdBy' | 'updatedAt' | 'deletedAt' | 'id'
> {
	if (!payload.name) throw new Error('A name is required');
	if (!payload.status) throw new Error('A status ref is required');

	return {
		...payload,
		type: 'file',
		name: payload.name,
		status: payload.status,
		path: payload.path ?? null,
		parent: payload.parent ?? null,
		description: payload.description ?? null,
		thumbnails: payload.thumbnails ?? [],
		archivedAt: payload.archivedAt ?? null,
		externalId: payload.externalId ?? null,
		shared: payload.shared ?? [userRef],
	};
}
