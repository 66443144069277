import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '@context/shared/types/common';

@Pipe({
	standalone: true,
	name: 'contentUrl',
})
export class ContentUrlPipe implements PipeTransform {
	transform(content: Content) {
		let prefix = '/home';
		if (!content.id) return [prefix];

		const now = new Date().valueOf();
		if (content.archivedAt && content.archivedAt.toDate().valueOf() < now) {
			prefix = '/archive';
		}

		switch (content.type) {
			case 'file':
				return [prefix, 'file', content.id];
			case 'folder':
				return [prefix, 'folder', content.id];
			default:
				throw new Error(
					`The content type is not supported: ${content.type}`,
				);
		}
	}
}
