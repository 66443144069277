import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

/**
 * This is a per-instance provider!
 *
 * DO NOT provide this at the root-level or as a part of any modules.
 *
 * This service is intended to replace any manual declarations of a destroy subject
 * within components.
 *
 * @example
 * // my-component.component.ts
 * Component({
 *   ...,
 *   providers: [
 *     ...,
 *     DestroyService
 *   ]
 * })
 * export class MyComponent {
 *   constructor(private readonly destroy$: DestroyService) {
 *     someSubscription.pipe(takeUntil(this.destroy$)).subscribe()
 *   }
 * }
 */
@Injectable()
export class DestroyService extends ReplaySubject<void> implements OnDestroy {
	constructor() {
		super(1);
	}

	ngOnDestroy() {
		this.next();
		this.complete();
	}
}
