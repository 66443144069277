export type ViewAccess = 'edit' | 'view' | 'share';

const ViewControl: ViewAccess[] = ['view'];
const ShareControl: ViewAccess[] = [...ViewControl, 'share'];
const EditControl: ViewAccess[] = [...ShareControl, 'edit'];

export function hasInheritedViewAccess(
	access?: ViewAccess,
	other?: ViewAccess,
) {
	if (!access || !other) return false;

	switch (access) {
		case 'view':
			return ViewControl.includes(other);
		case 'share':
			return ShareControl.includes(other);
		case 'edit':
			return EditControl.includes(other);
		default:
			return false;
	}
}
