import { inject, Injectable } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertService } from '@context/frontend/alert';
import { Organization } from '@context/shared/types/organization';
import { AuthUser, Invite, User } from '@context/shared/types/user';
import { DocumentReference } from 'firebase/firestore';
import { InviteService } from '../services';
import { Auth } from '@angular/fire/auth';

type InviteDetailData = {
	authUser: AuthUser | null;
	user: User;
	invite: Invite;
	organization: Organization;
};

@Injectable({ providedIn: 'root' })
export class InviteDetailResolve implements Resolve<InviteDetailData | null> {
	readonly inviteService = inject(InviteService);
	readonly router = inject(Router);
	readonly alert = inject(AlertService);
	readonly auth = inject(Auth);

	async resolve(route: ActivatedRouteSnapshot): Promise<InviteDetailData> {
		const id = route.queryParamMap.get('id');
		if (!id) return this.redirect();

		const invite = await this.inviteService
			.findById(id)
			.then((res) => res.data() as Invite)
			.catch(() => this.redirect());

		const user = (
			await getDoc(invite.user as DocumentReference<User>).catch(() =>
				this.redirect(),
			)
		).data() as User;

		const organization = (
			await getDoc(
				invite.organization as DocumentReference<Organization>,
			).catch(() => this.redirect())
		).data() as Organization;

		const authUser = invite.authUser
			? ((
					await getDoc(
						invite.authUser as DocumentReference<AuthUser>,
					).catch(() => this.redirect())
				).data() as AuthUser)
			: null;

		return {
			authUser,
			invite,
			user,
			organization,
		};
	}

	redirect = () =>
		this.router.navigate(['/']).then(() => {
			this.alert.open('invite-not-found').subscribe();
			return Promise.reject();
		});
}
