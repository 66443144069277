import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HelpFormDto, InquireFormDto } from '@context/shared/types/common';
import { firstValueFrom } from 'rxjs';
import { API_URL } from '../tokens';
import { getName, User } from '@context/shared/types/user';
import { Organization } from '@context/shared/types/organization';

@Injectable({ providedIn: 'root' })
export class SupportService {
	protected readonly api = inject(API_URL);
	protected readonly http = inject(HttpClient);

	inquire(payload: InquireFormDto) {
		return firstValueFrom(
			this.http.post(`${this.api}/v1/support/inquire`, payload),
		);
	}

	help(payload: HelpFormDto) {
		return firstValueFrom(
			this.http.post(`${this.api}/v1/support/help`, payload),
		);
	}

	requestDeletion(user: User, org: Organization) {
		const payload = new HelpFormDto({
			userId: user.id,
			name: getName(user),
			organizationId: org.id,
			organizationName: org.name,
			email: user.email,
			message:
				'User submitted an Account Deletion Request. Please follow up with the user to ensure this was intentional and start the data migration process.',
		});

		return firstValueFrom(
			this.http.post(`${this.api}/v1/support/help`, payload),
		);
	}

	cancelDeletionRequest(user: User, org: Organization) {
		const payload = new HelpFormDto({
			userId: user.id,
			name: getName(user),
			organizationId: org.id,
			organizationName: org.name,
			email: user.email,
			message:
				'User has cancelled their Account Deletion Request. Please follow up with the user to ensure this was intentional and prevent the data migration process.',
		});

		return firstValueFrom(
			this.http.post(`${this.api}/v1/support/help`, payload),
		);
	}
}
