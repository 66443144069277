import { Component } from '@angular/core';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';

type ConfirmOptions = {
	description: string;
	translationParams?: object;
};

@Component({
	standalone: true,
	imports: [TranslocoModule, TuiButton, TuiIcon],
	selector: 'ctx-confirm-dialog',
	templateUrl: 'confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends DialogBase<
	boolean,
	ConfirmOptions
> {
	static override readonly DialogOptions = { size: 's' } as DialogOptions;

	readonly cancelText = 'action.no';
	readonly acceptText = 'action.yes';

	onAccept = () => this.onDismiss(true);
}
