import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, Injectable, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { WA_WINDOW } from '@ng-web-apis/common';
const SafeObserver = typeof ResizeObserver !== 'undefined' ? ResizeObserver : class {
  observe() {}
  unobserve() {}
  disconnect() {}
};
const WA_RESIZE_OPTION_BOX_DEFAULT = 'content-box';
/**
 * @deprecated: drop in v5.0, use {@link WA_RESIZE_OPTION_BOX_DEFAULT}
 */
const RESIZE_OPTION_BOX_DEFAULT = WA_RESIZE_OPTION_BOX_DEFAULT;
const WA_RESIZE_OPTION_BOX = new InjectionToken('[WA_RESIZE_OPTION_BOX]', {
  providedIn: 'root',
  factory: () => RESIZE_OPTION_BOX_DEFAULT
});
/**
 * @deprecated: drop in v5.0, use {@link WA_RESIZE_OPTION_BOX}
 */
const RESIZE_OPTION_BOX = WA_RESIZE_OPTION_BOX;
class ResizeObserverService extends Observable {
  constructor() {
    const nativeElement = inject(ElementRef).nativeElement;
    const box = inject(RESIZE_OPTION_BOX);
    super(subscriber => {
      const observer = new SafeObserver(entries => subscriber.next(entries));
      observer.observe(nativeElement, {
        box
      });
      return () => {
        observer.disconnect();
      };
    });
  }
  static ɵfac = function ResizeObserverService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ResizeObserverService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ResizeObserverService,
    factory: ResizeObserverService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeObserverService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class WaResizeObserver {
  waResizeObserver = inject(ResizeObserverService);
  box = RESIZE_OPTION_BOX_DEFAULT;
  static ɵfac = function WaResizeObserver_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WaResizeObserver)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: WaResizeObserver,
    selectors: [["", "waResizeObserver", ""]],
    inputs: {
      waResizeBox: [0, "box", "waResizeBox"]
    },
    outputs: {
      waResizeObserver: "waResizeObserver"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([ResizeObserverService, {
      provide: RESIZE_OPTION_BOX,
      useFactory: () => inject(ElementRef).nativeElement.getAttribute('waResizeBox') || RESIZE_OPTION_BOX_DEFAULT
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaResizeObserver, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[waResizeObserver]',
      inputs: ['waResizeBox: box'],
      outputs: ['waResizeObserver'],
      providers: [ResizeObserverService, {
        provide: RESIZE_OPTION_BOX,
        useFactory: () => inject(ElementRef).nativeElement.getAttribute('waResizeBox') || RESIZE_OPTION_BOX_DEFAULT
      }]
    }]
  }], null, null);
})();
/**
 * @deprecated: use {@link WaResizeObserver}
 */
const ResizeObserverDirective = WaResizeObserver;
const WA_RESIZE_OBSERVER_SUPPORT = new InjectionToken('[WA_RESIZE_OBSERVER_SUPPORT]', {
  providedIn: 'root',
  factory: () => !!inject(WA_WINDOW).ResizeObserver
});
/**
 * @deprecated: drop in v5.0, use {@link WA_RESIZE_OBSERVER_SUPPORT}
 */
const RESIZE_OBSERVER_SUPPORT = WA_RESIZE_OBSERVER_SUPPORT;

/**
 * Generated bundle index. Do not edit.
 */

export { RESIZE_OBSERVER_SUPPORT, RESIZE_OPTION_BOX, RESIZE_OPTION_BOX_DEFAULT, ResizeObserverDirective, ResizeObserverService, WA_RESIZE_OBSERVER_SUPPORT, WA_RESIZE_OPTION_BOX, WA_RESIZE_OPTION_BOX_DEFAULT, WaResizeObserver };
