<section tuiContent>
	<p
		[innerHtml]="
			context.data.description | transloco: context.data.translationParams
		"
	></p>
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss(false)">
		{{ cancelText | transloco: context.data.translationParams }}
	</button>

	<button tuiButton appearance="accent" (click)="onAccept()">
		{{ acceptText | transloco: context.data.translationParams }}
	</button>
</footer>
