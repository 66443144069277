import { DocumentReference, EntityOption } from '@context/shared/types/common';
import { ContentFolder } from '../types';

export class FolderOption extends EntityOption<ContentFolder> {
	path: string | null = null;

	constructor(
		data: ContentFolder,
		ref?: DocumentReference<ContentFolder> | null,
		path?: string | null,
	) {
		super(data, ref);
		this.path = path ?? null;
	}

	protected override toString() {
		return this.data.name;
	}
}

export const HomeFolderOption = new FolderOption(
	{
		id: 'home',
		type: 'folder',
		name: 'Home',
	} as ContentFolder,
	null,
	null,
);
