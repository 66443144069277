<section tuiContent>
	@if (context.data.message) {
		<p class="margin-top-0">{{ context.data.message | transloco }}</p>
	}

	@if (error) {
		<tui-notification class="margin-bottom-2" size="s" appearance="error">
			{{ error | transloco }}
		</tui-notification>
	}

	@if (form) {
		<form
			[formGroup]="form"
			(ngSubmit)="onSubmit()"
			(keyup.enter)="onSubmit()"
		>
			<label tuiLabel>
				{{ 'data.email' | transloco }}

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="email"
						tuiTextfield
						type="email"
						autocomplete="email"
					/>
				</tui-textfield>

				<tui-error
					formControlName="email"
					[error]="['required'] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'data.password' | transloco }}

				<tui-input-password
					formControlName="password"
					tuiTextfieldSize="m"
					[tuiTextfieldLabelOutside]="true"
				>
					<input
						tuiTextfieldLegacy
						type="password"
						autocomplete="current-password"
					/>
				</tui-input-password>

				<tui-error
					formControlName="password"
					[error]="[] | tuiFieldError | async"
				/>
			</label>
		</form>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>
	<button
		tuiButton
		type="submit"
		appearance="accent"
		(click)="onSubmit()"
		[disabled]="busy"
		[loading]="busy"
	>
		{{ 'auth.sign-in' | transloco }}
	</button>
</footer>
