import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertService } from '@context/frontend/alert';
import { AlertId } from '@context/shared/types/alert';
import { UserService } from '../services';

@Injectable({ providedIn: 'root' })
export class ResetPasswordResolve
	implements Resolve<{ email: string; token: string } | null>
{
	private readonly router = inject(Router);
	private readonly alert = inject(AlertService);
	private readonly userService = inject(UserService);

	async resolve(route: ActivatedRouteSnapshot) {
		const token = route.queryParamMap.get('token');
		const email = decodeURIComponent(
			route.queryParamMap.get('email') ?? '',
		);

		if (!token || !email) return this.redirect();

		const res = await this.userService
			.verifyForgotPasswordToken({ email, token })
			.catch((error) => {
				this.redirect();
				console.error('There was an issue verifying the token', error);
				throw new Error('There was an issue verifying the token');
			});

		const now = new Date();
		const isExpired = res.expiredAt
			? res.expiredAt.valueOf() < now.valueOf()
			: false;

		// possibility that the jwt validation doesn't throw expiration but we determine it's expired.
		if (isExpired) return this.redirect('forgot-password-expired');

		if (!res.verified || !res.value) {
			switch (res.code) {
				case 'auth/expired-code':
					return this.redirect('forgot-password-expired');
				case 'auth/decode-error':
				default:
					return this.redirect();
			}
		}

		const isCorrectEmail = res.value.email === email;
		if (!isCorrectEmail) return this.redirect();

		return { token, email };
	}

	redirect = (alert: AlertId = 'generic-error') =>
		this.router.navigate(['/auth/login']).then(() => {
			this.alert.open(alert).subscribe();
			return null;
		});
}
