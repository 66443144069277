import * as i0 from '@angular/core';
import { Optional, Self, Directive, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TUI_VALUE_ACCESSOR } from '@taiga-ui/legacy/tokens';
import { identity } from 'rxjs';
const TUI_VALUE_ACCESSOR_PROVIDER = {
  provide: TUI_VALUE_ACCESSOR,
  deps: [[new Optional(), new Self(), NG_VALUE_ACCESSOR]],
  useFactory: identity
};
class TuiValueAccessorDirective {
  static {
    this.ɵfac = function TuiValueAccessorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiValueAccessorDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiValueAccessorDirective,
      selectors: [["", "tuiValueAccessor", ""]],
      features: [i0.ɵɵProvidersFeature([TUI_VALUE_ACCESSOR_PROVIDER])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiValueAccessorDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiValueAccessor]',
      providers: [TUI_VALUE_ACCESSOR_PROVIDER]
    }]
  }], null, null);
})();
class TuiValueAccessorModule {
  static {
    this.ɵfac = function TuiValueAccessorModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiValueAccessorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiValueAccessorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiValueAccessorModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiValueAccessorDirective],
      exports: [TuiValueAccessorDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_VALUE_ACCESSOR_PROVIDER, TuiValueAccessorDirective, TuiValueAccessorModule };
