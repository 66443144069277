@if (thumbnailUrl) {
	<tui-avatar
		class="content-thumbnail__avatar"
		[size]="size"
		[round]="false"
		[src]="thumbnailUrl | tuiFallbackSrc: icon | async"
	/>
} @else {
	<tui-avatar
		class="content-thumbnail__avatar"
		[size]="size"
		[round]="false"
		[src]="icon"
	/>
}

@if (badgeIcon) {
	<tui-avatar
		class="content-thumbnail__badge"
		size="s"
		[src]="badgeIcon"
		tuiHintAppearance="dark"
		[tuiHint]="badgeHint"
	></tui-avatar>
}
