import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, EventEmitter, Directive, Input, Output, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
const SafeObserver = typeof MutationObserver !== 'undefined' ? MutationObserver : class {
  observe() {}
  disconnect() {}
  takeRecords() {
    return [];
  }
};
const WA_MUTATION_OBSERVER_INIT = new InjectionToken('[WA_MUTATION_OBSERVER_INIT]');
/**
 * @deprecated: drop in v5.0, use {@link WA_MUTATION_OBSERVER_INIT}
 */
const MUTATION_OBSERVER_INIT = WA_MUTATION_OBSERVER_INIT;
function booleanAttribute(element, attribute) {
  return element.getAttribute(attribute) !== null || undefined;
}
function mutationObserverInitFactory() {
  const {
    nativeElement
  } = inject(ElementRef);
  const attributeFilter = nativeElement.getAttribute('attributeFilter');
  return {
    attributeFilter: attributeFilter?.split(',').map(attr => attr.trim()),
    attributeOldValue: booleanAttribute(nativeElement, 'attributeOldValue'),
    attributes: booleanAttribute(nativeElement, 'attributes'),
    characterData: booleanAttribute(nativeElement, 'characterData'),
    characterDataOldValue: booleanAttribute(nativeElement, 'characterDataOldValue'),
    childList: booleanAttribute(nativeElement, 'childList'),
    subtree: booleanAttribute(nativeElement, 'subtree')
  };
}
class WaMutationObserver extends SafeObserver {
  nativeElement = inject(ElementRef).nativeElement;
  config = inject(MUTATION_OBSERVER_INIT);
  attributeFilter = '';
  attributeOldValue = '';
  attributes = '';
  characterData = '';
  characterDataOldValue = '';
  childList = '';
  subtree = '';
  waMutationObserver = new EventEmitter();
  constructor() {
    super(records => {
      this.waMutationObserver.emit(records);
    });
    this.observe(this.nativeElement, this.config);
  }
  ngOnDestroy() {
    this.disconnect();
  }
  static ɵfac = function WaMutationObserver_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WaMutationObserver)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: WaMutationObserver,
    selectors: [["", "waMutationObserver", ""]],
    inputs: {
      attributeFilter: "attributeFilter",
      attributeOldValue: "attributeOldValue",
      attributes: "attributes",
      characterData: "characterData",
      characterDataOldValue: "characterDataOldValue",
      childList: "childList",
      subtree: "subtree"
    },
    outputs: {
      waMutationObserver: "waMutationObserver"
    },
    exportAs: ["MutationObserver"],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: MUTATION_OBSERVER_INIT,
      useFactory: mutationObserverInitFactory
    }]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaMutationObserver, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[waMutationObserver]',
      providers: [{
        provide: MUTATION_OBSERVER_INIT,
        useFactory: mutationObserverInitFactory
      }],
      exportAs: 'MutationObserver'
    }]
  }], function () {
    return [];
  }, {
    attributeFilter: [{
      type: Input
    }],
    attributeOldValue: [{
      type: Input
    }],
    attributes: [{
      type: Input
    }],
    characterData: [{
      type: Input
    }],
    characterDataOldValue: [{
      type: Input
    }],
    childList: [{
      type: Input
    }],
    subtree: [{
      type: Input
    }],
    waMutationObserver: [{
      type: Output
    }]
  });
})();
/**
 * @deprecated: use {@link WaMutationObserver}
 */
const MutationObserverDirective = WaMutationObserver;
class MutationObserverService extends Observable {
  constructor() {
    const nativeElement = inject(ElementRef).nativeElement;
    const config = inject(MUTATION_OBSERVER_INIT);
    super(subscriber => {
      const observer = new SafeObserver(records => {
        subscriber.next(records);
      });
      observer.observe(nativeElement, config);
      return () => {
        observer.disconnect();
      };
    });
  }
  static ɵfac = function MutationObserverService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MutationObserverService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MutationObserverService,
    factory: MutationObserverService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MutationObserverService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MUTATION_OBSERVER_INIT, MutationObserverDirective, MutationObserverService, WA_MUTATION_OBSERVER_INIT, WaMutationObserver };
