import {
	DerivedThumbnailFrom,
	Resolution,
	Thumbnail,
} from '@context/shared/types/common';
import { ContentMedia } from '../types';

export function createMedia(
	payload: Partial<ContentMedia>,
	file: File,
): Partial<ContentMedia> {
	return {
		...payload,
		name: payload.name ?? file.name,
		type: payload.type ?? file.type,
		storagePath: payload.storagePath ?? '', // provided by implementation
		log: payload.log ?? null,
		archivedAt: payload.archivedAt ?? null,
		thumbnailPath: payload.thumbnailPath ?? null,
		setAsThumbnail: payload.setAsThumbnail ?? false,
	};
}

// The preferred order that the thumbnail will generate from. Subject to change.
const ThumbnailPriority: DerivedThumbnailFrom[] = [
	'media',
	'log',
	'self',
] as const;

/**
 * 1 a). Reorder the thumbnail to match derived priority
 * 1 b). Reorder the thumbnail based on resolution
 * 2. Return thumbnail that matches request resolution
 */
export function getPreferredThumbnail(
	thumbnails: Thumbnail[],
	preference: { resolution: Resolution },
) {
	const { resolution } = preference;
	return thumbnails
		.sort((a, b) => {
			const aP = ThumbnailPriority.findIndex((p) => p === a.derived);
			const bP = ThumbnailPriority.findIndex((p) => p === b.derived);
			if (aP !== bP) return aP - bP;
			return b.maxResolution - a.maxResolution;
		})
		.find((t) => t.maxResolution <= resolution);
}

export function isImage(file: File) {
	return file.type.startsWith('image/');
}

export function getExtension(name: string) {
	return `.${name.split('.').pop()}`;
}

export function removeExtension(name: string) {
	const split = name.split('.');
	split.pop();
	return split.join('.');
}

export async function downloadBlob(options: { name: string; blob: Blob }) {
	const { name, blob } = options;

	const a = document.createElement('a');
	a.setAttribute('download', name);
	a.setAttribute('target', '_blank');

	const href = URL.createObjectURL(blob);
	a.href = href;

	a.click();

	URL.revokeObjectURL(href);
	a.remove();
}

const MediaIconMap = {
	png: '@tui.file-image',
	jpeg: '@tui.file-image',
	jpg: '@tui.file-image',

	pdf: '@tui.file-text',

	mp4: '@tui.file-video',
	video: '@tui.file-video',

	mp3: '@tui.file-audio',

	json: '@tui.file-json',

	csv: '@tui.file-spreadsheet',
	xlsx: '@tui.file-spreadsheet',

	pptx: '@tui.file-chart-pie',

	docx: '@tui.file',

	default: '@tui.file-question',
} as const;

// string & {} allows any string to pass, but still shows MediaIconMap for autocomplete
type MediaIcon = keyof typeof MediaIconMap | (string & {});

export function getMediaIcon(type: MediaIcon) {
	// any cast to allow any string to be passed for the default icon
	return (MediaIconMap as any)[type] ?? MediaIconMap.default;
}

const ImageTypes = ['jpeg', 'png'];
export function isMediaImage(type: string) {
	return type.startsWith('image/') || ImageTypes.includes(type);
}

export function isMediaPdf(type: string) {
	if (type === 'application/pdf' || type === 'pdf') return true;
	return false;
}

const DocumentExtensions = ['.xlsx', '.docx', '.odt', '.ods', '.csv', '.pptx'];
export function isMediaDoc(extension: string) {
	return DocumentExtensions.includes(extension);
}
