import { Component } from '@angular/core';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { Entity } from '@context/shared/types/common';
import { SortItem } from '@context/shared/types/pagination';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiOption } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [TuiButton, TuiIcon, TuiOption, TranslocoModule],
	selector: 'ctx-sort-dialog',
	templateUrl: 'sort-dialog.component.html',
	styleUrl: 'sort-dialog.component.scss',
})
export class SortDialogComponent<T extends Entity> extends DialogBase<
	SortItem<T> | null,
	{ items: SortItem<T>[]; activeItem: SortItem<T> | null }
> {
	static override readonly DialogOptions = {
		label: 'action.sort',
		size: 's',
	} as DialogOptions;

	onClick(item: SortItem<T>) {
		this.onDismiss(item);
	}
}
