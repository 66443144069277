import * as i0 from '@angular/core';
import { ElementRef, Directive } from '@angular/core';
import { tuiInjectElement } from '@taiga-ui/cdk/utils';
class TuiElement {
  constructor() {
    this.nativeElement = tuiInjectElement();
    /**
     * @note:
     * Typically, when your constructor is invoked with new,
     * an object is created, its constructor is assigned to
     * the invoked constructor and the object is then assigned
     * to this before executing any operations specified
     * in your constructor method.
     *
     * ERROR TypeError: Class constructor ElementRef cannot be invoked without 'new'
     * https://github.com/taiga-family/taiga-ui/issues/3072
     *
     * This way we can instantiate object creation
     * without additional prototype chain for possible fix bug.
     */
    return new ElementRef(this.nativeElement);
  }
  static {
    this.ɵfac = function TuiElement_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiElement)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiElement,
      selectors: [["", "tuiElement", ""]],
      exportAs: ["elementRef"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiElement, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiElement]',
      exportAs: 'elementRef'
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiElement };
