import { isDevMode } from '@angular/core';
import { Device } from '@context/shared/types/native';
import { UAParser } from 'ua-parser-js';

/**
 * In Google Chrome, the `Responsive` dimension returns as the following configuration. Due to this
 * setting being highly used for developers, we override that to ensure we detect as "desktop" instead.
 */
const ResponsiveDevToolsDevice = {
	vendor: 'LG',
	model: 'Nexus 5',
	type: 'mobile',
} as const;

/**
 * @note the device will not auto update, the value may need to be fetched again
 * if the app is running in and out of an emulator in the same instance.
 *
 * @returns the current device based on the window user agent
 */
export function getUaDevice(): Device {
	const device = new UAParser(window.navigator.userAgent).getDevice();
	const type = device.type as Device | undefined;

	if (
		isDevMode() &&
		JSON.stringify(device) == JSON.stringify(ResponsiveDevToolsDevice)
	) {
		return 'desktop';
	}

	return type ?? 'desktop';
}

export function isUaDevice(device: Device) {
	return device === getUaDevice();
}
