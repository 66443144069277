<div class="table-placeholder__icon-container">
	<tui-avatar [src]="icon" size="xl" tuiAvatarOutline></tui-avatar>
</div>

<h3 class="font-size-medium margin-top-8 margin-bottom-0">
	{{ header }}
</h3>

<p class="font-size-large margin-top-2">
	{{ content }}
</p>
