import { DocumentReference } from './document-reference.interface';
import { Entity } from './entity.interface';

const Resolutions = [128, 256, 512, 1024, 2048] as const;
export type Resolution = (typeof Resolutions)[number];

export type DerivedThumbnailFrom = 'self' | 'media' | 'log';
export type ThumbnailBase = {
	storagePath: string;
	maxResolution: Resolution;
	derived: DerivedThumbnailFrom;
	ref: DocumentReference<Entity> | null;
};

export interface ThumbnailSelfDerived extends ThumbnailBase {
	derived: 'self';
	ref: null;
}

export interface ThumbnailReferenceDerived extends ThumbnailBase {
	derived: 'log' | 'media';
	// the entity type doesn't really matter, just extended from Entity
	ref: DocumentReference<Entity>;
}

export type Thumbnail = ThumbnailSelfDerived | ThumbnailReferenceDerived;

export type ImageCompressionOptions = {
	maxSizeMB: number;
	maxWidthOrHeight: Resolution;
};

export type PresetCompression = 'thumbnail' | 'max';

export const PresetCompressions: {
	[key in PresetCompression]: ImageCompressionOptions;
} = {
	// We can adjust this value based on user feedback
	max: { maxSizeMB: 1.5, maxWidthOrHeight: 2048 },
	thumbnail: { maxSizeMB: 0.5, maxWidthOrHeight: 256 },
};
