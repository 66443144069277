import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [TranslocoModule, TuiButton, TuiIcon],
	selector: 'ctx-logout-dialog',
	templateUrl: 'logout-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutDialogComponent extends DialogBase<boolean> {
	static override readonly DialogOptions = {
		label: 'navigation.sign-out',
		size: 's',
	} as DialogOptions;
}
