import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {
	DestroyService,
	DialogService,
	ImageCropperDialogComponent,
} from '@context/frontend/common';
import { getInitials, getName, User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiButton,
	TuiFallbackSrcPipe,
	TuiIcon,
	TuiIconPipe,
	TuiSizeS,
	TuiSizeXXL,
	TuiTitle,
} from '@taiga-ui/core';
import { TuiAvatar, TuiAvatarOutline } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';

const FileAcceptImageTypes = ['*/*', 'image/png', 'image/jpg', 'image/jpeg'];
export type FileAcceptImageType = (typeof FileAcceptImageTypes)[number];

@Component({
	standalone: true,
	imports: [
		TuiAvatar,
		TuiAvatarOutline,
		TuiButton,
		TuiIcon,
		TuiFallbackSrcPipe,
		AsyncPipe,
		TuiIconPipe,
		TuiTitle,
		TuiAvatarOutline,
		TranslocoModule,
		ImageCropperDialogComponent,
	],
	selector: 'ctx-avatar',
	templateUrl: 'avatar.component.html',
	styleUrl: 'avatar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class AvatarComponent {
	@ViewChild('fileUploadInput')
	fileUploadInputRef!: ElementRef<HTMLInputElement>;

	@Input() icon = '@tui.user';

	@Input() user: User | null = null;

	@Input() size: TuiSizeS | TuiSizeXXL = 'm';

	@HostBinding('attr.disabled')
	@Input()
	disabled = false;

	@HostBinding('attr.can-click')
	@HostBinding('tabindex.0')
	@Input()
	canUpload = false;

	@Output() fileChange = new EventEmitter<File>();
	@Output() fileRemove = new EventEmitter<void>();

	private readonly dialog = inject(DialogService);

	readonly accept = FileAcceptImageTypes.join(', ');

	/**
	 * used for the cropper, should only be used until cropper has resolved and then remove
	 */
	imageFile: File | null = null;

	readonly imageCropperDialog = () =>
		this.dialog.open<File | null>(
			new PolymorpheusComponent(ImageCropperDialogComponent),
			ImageCropperDialogComponent.Options({
				data: { imageFile: this.imageFile },
			}),
		);

	get src() {
		if (!this.user) return null;
		if (this.user.thumbnailUrl) return this.user.thumbnailUrl;
		return this.initials;
	}

	get initials() {
		if (!this.user || (!this.user.familyName && !this.user.givenName))
			return '';
		return getInitials(getName(this.user)) ?? '';
	}

	onUploadImage() {
		if (!this.canUpload) return;
		this.fileUploadInputRef.nativeElement?.click();
	}

	onRemoveImage = () => this.fileRemove.emit();

	onFileChange() {
		const fileInput = this.fileUploadInputRef.nativeElement;

		this.imageFile = fileInput.files?.item(0) ?? null;
		if (this.imageFile) {
			this.imageCropperDialog().subscribe({
				next: (data) => this.onImageCropperDismiss(data),
			});
		}
		return Promise.resolve();
	}

	onImageCropperDismiss(file: File | null) {
		this.imageFile = null;
		this.fileUploadInputRef.nativeElement.value = '';

		if (!file) return;
		this.fileChange.emit(file);
	}
}
