import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import {
	collection,
	collectionCount,
	collectionData,
	CollectionReference,
	doc,
	documentId,
	DocumentReference,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	QueryConstraint,
	setDoc,
	startAfter,
	UpdateData,
	updateDoc,
	where,
	WithFieldValue,
	writeBatch,
} from '@angular/fire/firestore';
import { API_URL } from '@context/frontend/common';
import {
	Fetchable,
	PaginatedObject,
	PaginatedOptions,
} from '@context/frontend/pagination';
import { Activity, ActivityType } from '@context/shared/types/activity';
import { Entity } from '@context/shared/types/common';
import { SortDir } from '@context/shared/types/pagination';
import { User } from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import { DocumentSnapshot, Timestamp } from 'firebase/firestore';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import { v4 } from 'uuid';
import {
	BaseActivityService,
	LogActivityPayload,
} from './base-activity.service';

const MaxBatch = 500;
const DefaultConfig = {
	useOrganization: true,
	version: 'v1',
};

export abstract class CrudService<ContentType extends Entity>
	extends BaseActivityService<ContentType>
	implements Fetchable<ContentType>
{
	collection!: CollectionReference<ContentType, ContentType>;

	protected readonly items = new Map<string, ContentType>();

	protected readonly api = inject(API_URL);
	protected readonly http = inject(HttpClient);

	/**
	 * emits whenever a change event occurs locally. That way if a modal or some out of scope
	 * service/component creates content we can have other components/table listen for it
	 * to know when we need to reload.
	 */
	readonly changed$ = new Subject<void>();

	readonly reset$ = new Subject<boolean>();

	userRef: DocumentReference<User> | null = null;

	constructor(
		protected path?: string,
		protected readonly config = DefaultConfig,
	) {
		super();
		this.setupInitializing();
	}

	setupInitializing() {
		combineLatest([this.organizationId, this.user]).subscribe(() => {
			// resets when the organization id and user id have been updated
			this.reset$.next(true);
			this.initialize();
		});
	}

	protected initialize() {
		if (!this.path) return;

		if (this.config.useOrganization) {
			if (!this.organizationId.value) return;

			this.collection = collection(
				this.firestore,
				`organizations/${this.organizationId.value}/${this.path}`,
			) as CollectionReference<ContentType, ContentType>;
		} else {
			this.collection = collection(
				this.firestore,
				`${this.path}`,
			) as CollectionReference<ContentType, ContentType>;
		}

		if (this.user.value?.id && this.organizationId.value) {
			this.userRef = doc(
				collection(
					this.firestore,
					`organizations/${this.organizationId.value}/users`,
				),
				this.user.value?.id,
			) as DocumentReference<User>;
		}
	}

	async create(payload: Partial<ContentType>) {
		const now = Timestamp.now();
		payload.createdAt = now;
		payload.updatedAt = now;
		payload.deletedAt = null;

		payload.createdBy = this.userRef ?? undefined;

		payload.id = v4();

		const docRef = doc(this.collection, payload.id);
		// we use `setDoc` instead of `addDoc` to use our own `id` value.
		await setDoc(
			docRef,
			sanitize<WithFieldValue<Partial<ContentType>>>(payload),
		);

		if (this.user.value?.id) {
			await this.logActivity({
				entity: docRef,
				activity: { type: 'create' },
				createdBy: this.userRef ?? this.user.value?.id,
			});
		}

		this.changed$.next();
		return payload;
	}

	async update(
		payload: Partial<ContentType & Entity>,
		options: UpdateOptions,
	): Promise<Partial<ContentType>> {
		payload.updatedAt = Timestamp.now();

		const docRef = doc(this.collection, payload.id);
		const value = sanitize(payload);
		await updateDoc(docRef, value as UpdateData<ContentType>);

		if (this.user.value?.id && options.addActivityLog) {
			await this.logActivity({
				entity: docRef,
				activity: { type: options.type },
				createdBy: this.userRef ?? this.user.value?.id,
			});
		}

		this.changed$.next();
		return value;
	}

	async delete(id: string) {
		const docRef = doc(this.collection, id);

		const now = Timestamp.now();
		await updateDoc(
			docRef,
			sanitize({
				updatedAt: now,
				deletedAt: now,
			}) as UpdateData<ContentType>,
		);

		if (this.user.value?.id) {
			await this.logActivity({
				entity: docRef,
				activity: { type: 'delete' },
				createdBy: this.userRef ?? this.user.value?.id,
			});
		}

		this.changed$.next();
		// empty object to ensure deletion success
		return {};
	}

	/**
	 * Batch creates multiple entries at the same time.
	 *
	 * @param payloads The collection of entries to create.
	 */
	batchCreate(payloads: (ContentType & Entity)[]) {
		if (payloads.length > MaxBatch) {
			// todo: setup true batching on limits
			throw new Error(
				'Attempting to batch update more than 500 records.',
			);
		}

		const batch = writeBatch(this.firestore);
		const activityTasks: Promise<Activity>[] = [];

		const now = Timestamp.now();
		payloads.forEach((payload) => {
			payload.createdAt = now;
			payload.updatedAt = now;
			payload.deletedAt = null;
			payload.id = v4();

			const docRef = doc(this.collection, payload.id);
			batch.set(docRef, sanitize(payload));
			if (this.user.value?.id) {
				activityTasks.push(
					this.logActivity({
						entity: docRef,
						activity: { type: 'create' },
						createdBy: this.userRef ?? this.user.value?.id,
					}),
				);
			}
		});

		// we need the activity promises to complete AFTER the entity actions occur or
		// a race case could occur and we add notifications to an object that doesn't
		// exist yet
		return batch.commit().then(() => {
			// we don't need to wait for this to complete
			Promise.all(activityTasks);
			this.changed$.next();
			return payloads;
		});
	}

	/**
	 * Batch updates multiple entries at the same time.
	 *
	 * @param payloads The collection of entries to create.
	 */
	batchUpdate(
		payloads: Partial<ContentType & Entity>[],
		options: UpdateOptions,
	) {
		if (payloads.length > MaxBatch) {
			// todo: setup true batching on limits
			throw new Error(
				'Attempting to batch update more than 500 records.',
			);
		}

		const batch = writeBatch(this.firestore);
		const activityTasks: Promise<Activity>[] = [];

		const now = Timestamp.now();
		payloads.forEach((payload) => {
			payload.updatedAt = now;

			const docRef = doc(this.collection, payload.id);
			batch.update(docRef, sanitize(payload) as UpdateData<ContentType>);
			if (this.user.value?.id && options.addActivityLog) {
				activityTasks.push(
					this.logActivity({
						entity: docRef,
						activity: { type: options.type },
						createdBy: this.userRef ?? this.user.value?.id,
					}),
				);
			}
		});

		// we need the activity promises to complete AFTER the entity actions occur or
		// a race case could occur and we add notifications to an object that doesn't
		// exist yet
		return batch.commit().then(() => {
			// we don't need to wait for this to complete
			Promise.all(activityTasks);
			this.changed$.next();
			return payloads;
		});
	}

	/**
	 * Batch updates multiple entries at the same time.
	 *
	 * @param payloads The collection of entries to create.
	 */
	batchDelete(payloads: Partial<ContentType & Entity>[]) {
		if (payloads.length > MaxBatch) {
			// todo: setup true batching on limits
			throw new Error(
				'Attempting to batch update more than 500 records.',
			);
		}

		const batch = writeBatch(this.firestore);
		const activityTasks: Promise<Activity>[] = [];

		const now = Timestamp.now();
		payloads.forEach((payload) => {
			const docRef = doc(this.collection, payload.id);
			batch.update(docRef, { deletedAt: now } as UpdateData<ContentType>);
			if (this.user.value?.id) {
				activityTasks.push(
					this.logActivity({
						entity: docRef,
						activity: { type: 'delete' },
						createdBy: this.userRef ?? this.user.value?.id,
					}),
				);
			}
		});

		// we need the activity promises to complete AFTER the entity actions occur or
		// a race case could occur and we add notifications to an object that doesn't
		// exist yet
		return batch.commit().then(() => {
			// we don't need to wait for this to complete
			Promise.all(activityTasks);
			this.changed$.next();
			return payloads;
		});
	}

	/**
	 * Finds a collection's document record by it's id
	 *
	 * @param id The unique id of the document.
	 */
	findById(id: string) {
		return getDoc(doc(this.collection, id)).then((doc) => {
			if (!doc.exists()) throw new Error('Document does not exist');
			return doc;
		});
	}

	/**
	 * Fetches all the records that match the uid
	 *
	 * @param uids The unique identifiers to fetch.
	 */
	async fetchByIdentities(uids: string[] = []) {
		if (uids.length === 0) return [];

		// This is a limitation on Firebase. You can only use the `in` Filter Operation with a max of a 30 length array
		const BATCH_SIZE = 30;
		const totalBatches = Math.ceil(uids.length / BATCH_SIZE);

		const value = [];
		for (let i = 0; i < totalBatches; i++) {
			const batchIds = uids.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);

			const queryDocs = query(
				this.collection,
				where(documentId(), 'in', batchIds),
			);

			const { docs } = await getDocs(queryDocs);
			value.push(...docs.map((doc) => doc.data() as ContentType));
		}
		return value;
	}

	/**
	 * Fetches all the document records in a collection as their raw reference payload. This
	 * may be needed in order to set the references properly on other documents.
	 */
	fetchReferences(options?: PaginatedOptions<ContentType>) {
		const sortDir = options?.sortDir ?? 'asc';
		const sortBy = options?.sortBy ?? 'createdAt';

		const constraints: QueryConstraint[] = [
			orderBy(sortBy as string, sortDir),
			where('deletedAt', '==', null),
		];

		if (options?.constraints) constraints.push(...options.constraints);

		query(this.collection, ...constraints);
		return getDocs(query(this.collection, ...constraints));
	}

	async fetch(
		paginated?: PaginatedObject<ContentType>,
	): Promise<PaginatedObject<ContentType>> {
		if (!paginated) paginated = new PaginatedObject();

		// avoid fetching it every time.
		// todo make it detect if the constraints change
		if (!paginated.totalElements) {
			paginated.totalElements = await firstValueFrom(
				collectionCount(
					query(this.collection, ...paginated.constraints),
				),
			);
		}

		// if the object already contains the data, don't fetch again
		if (paginated.page) return paginated;

		const constraints = [...paginated.constraints];
		const lastDoc = paginated.previousPage?.last ?? null;
		if (lastDoc) constraints.push(startAfter(lastDoc));
		if (paginated.pageSize) constraints.push(limit(paginated.pageSize));
		const snapshot = await getDocs(query(this.collection, ...constraints));

		let last: DocumentSnapshot | null = null;
		const items = snapshot.docs.map((d) => {
			last = d;
			return { data: d.data(), ref: d.ref, id: d.id };
		});

		paginated.pages = Object.assign({}, paginated.pages, {
			[paginated.currentPage as number]: { last, items },
		});

		return paginated;
	}

	/**
	 * Fetches the data from the initializing collection and leaves the stream open for the
	 * implementation to determine how to handle it. Useful for needing immediate updating
	 * for new data like alerting users when a new notification comes through
	 */
	fetchStream(options?: { sortBy?: keyof ContentType; sortDir?: SortDir }) {
		const constraints: QueryConstraint[] = [where('deletedAt', '==', null)];

		// only allow strings for the sortBy value
		if (options?.sortBy && typeof options.sortBy === 'string')
			constraints.push(orderBy(options.sortBy, options.sortDir ?? 'asc'));

		return collectionData(query(this.collection, ...constraints));
	}

	/**
	 * Resets the cached items collection.
	 */
	reset() {
		this.items.clear();
	}

	/**
	 * Finds an item located in the cache layer.
	 *
	 * @param id The item id to find in cache
	 */
	findInCacheById(id: string) {
		return this.items.get(id);
	}

	/**
	 * Fetches a collection of items from the cache layer
	 *
	 * @param ids The collection of ids to find
	 */
	fetchFromCache(ids: string[]) {
		return ids.reduce((value, current) => {
			const item = this.findInCacheById(current);
			if (item) value.push(item);
			return value;
		}, [] as ContentType[]);
	}

	/**
	 * Loads the items that have not already been loaded
	 * into the cache layer of the service.
	 *
	 * @param ids The collection of all item ids that need to be available.
	 */
	differentialFetchByIdentities(ids: string[]) {
		ids = ids.filter((id) => !!id);
		const itemIds = [...Array.from(new Set(ids))];

		const diff = itemIds.filter((id) => this.items.has(id));
		return this.fetchByIdentities(diff).then((items) => {
			items.forEach((item) => this.items.set(item.id, item));
			return items;
		});
	}

	/**
	 * Changes the owner of the entity (createdBy) to the provided value. This
	 * should be reserved for high level roles within the organization.
	 *
	 * @param id the id of the entity to change the owner of
	 * @param newOwner the new owner to update the entity with
	 */
	updateOwner<T extends ContentType>(
		entity: T,
		newOwner: DocumentReference<User>,
	) {
		return this.update({ ...entity, createdBy: newOwner } as T, {
			addActivityLog: true,
			type: 'ownership',
		});
	}

	override logActivity(payload: Omit<LogActivityPayload, 'collectionRef'>) {
		if (!this.collection)
			throw new Error('Collection could not be determined');

		const { entity } = payload;
		if (typeof entity === 'string') {
			return super.logActivity({
				...payload,
				collectionRef: this.collection,
			} as LogActivityPayload);
		}
		return super.logActivity(payload as LogActivityPayload);
	}

	override fetchActivity(payload: Pick<LogActivityPayload, 'entity'>) {
		if (!this.collection)
			throw new Error('Collection could not be determined');

		const { entity } = payload;
		if (typeof entity === 'string') {
			return super.fetchActivity({
				entity,
				collectionRef: this.collection,
			});
		}

		return super.fetchActivity({ entity });
	}
}

type UpdateOptionsBase = {
	addActivityLog: boolean | undefined;
	type?: never | ActivityType;
};

interface UpdateOptionsWithLog extends UpdateOptionsBase {
	addActivityLog: true | undefined;
	type: ActivityType;
}

interface UpdateOptionsWithoutLog extends UpdateOptionsBase {
	addActivityLog: false;
	type?: never;
}

export type UpdateOptions = UpdateOptionsWithLog | UpdateOptionsWithoutLog;
