import { Component, HostBinding, Input } from '@angular/core';
import { RelativeTimePipe } from '@context/frontend/common';
import { User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiSizeS } from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';
import { UserNamePipe, UserStatusPipe } from '../../pipes';
import { AvatarComponent } from '../avatar/avatar.component';
import { getUaDevice } from '@context/frontend/native/platform';

@Component({
	standalone: true,
	imports: [
		AvatarComponent,
		UserNamePipe,
		TuiButton,
		TuiIcon,
		TranslocoModule,
		TuiBadge,
		UserStatusPipe,
		RelativeTimePipe,
	],
	selector: 'ctx-user-row',
	templateUrl: 'user-row.component.html',
	styleUrl: 'user-row.component.scss',
})
export class UserRowComponent {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@Input() user!: User;

	@Input() interactable = true;

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeS = 'm';

	@Input() showStatus = true;
}
