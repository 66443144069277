import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { iif, of, switchMap } from 'rxjs';
import { AuthService } from '../services';

const HeaderKey = 'Authorization';
export function authBearerInterceptor(
	req: HttpRequest<unknown>,
	next: HttpHandlerFn,
) {
	const auth = inject(AuthService);
	if (!auth.firebaseUser) return next(req);

	return iif(
		() => !req.headers.has(HeaderKey),
		new Promise((resolve) => {
			return auth.firebaseUser?.getIdToken().then((token) => {
				if (!token || !auth.organization) return resolve(null);

				const headers = req.headers
					.set(HeaderKey, `Bearer ${token}`)
					.set('Organization', auth.organization.id);

				req = req.clone({ headers });
				return resolve(req);
			});
		}),
		of(null),
	).pipe(switchMap(() => next(req)));
}
