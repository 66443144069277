import * as i0 from '@angular/core';
import { inject, ElementRef, NgZone, Directive, Input, Pipe } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { MASKITO_DEFAULT_ELEMENT_PREDICATE, maskitoTransform, Maskito, MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
class MaskitoDirective {
  constructor() {
    this.elementRef = inject(ElementRef).nativeElement;
    this.ngZone = inject(NgZone);
    this.maskedElement = null;
    this.options = null;
    this.elementPredicate = MASKITO_DEFAULT_ELEMENT_PREDICATE;
    const accessor = inject(DefaultValueAccessor, {
      self: true,
      optional: true
    });
    if (accessor) {
      const original = accessor.writeValue.bind(accessor);
      accessor.writeValue = value => {
        original(this.options ? maskitoTransform(String(value ?? ''), this.options) : value);
      };
    }
  }
  async ngOnChanges() {
    const {
      elementPredicate,
      options,
      maskedElement,
      elementRef,
      ngZone
    } = this;
    maskedElement?.destroy();
    if (!options) {
      return;
    }
    const predicateResult = await elementPredicate(elementRef);
    if (this.elementPredicate !== elementPredicate || this.options !== options) {
      // Ignore the result of the predicate if the
      // maskito element (or its options) has changed before the predicate was resolved.
      return;
    }
    ngZone.runOutsideAngular(() => {
      this.maskedElement = new Maskito(predicateResult, options);
    });
  }
  ngOnDestroy() {
    this.maskedElement?.destroy();
  }
  static {
    this.ɵfac = function MaskitoDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MaskitoDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MaskitoDirective,
      selectors: [["", "maskito", ""]],
      inputs: {
        options: [0, "maskito", "options"],
        elementPredicate: [0, "maskitoElement", "elementPredicate"]
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[maskito]'
    }]
  }], function () {
    return [];
  }, {
    options: [{
      type: Input,
      args: ['maskito']
    }],
    elementPredicate: [{
      type: Input,
      args: ['maskitoElement']
    }]
  });
})();
class MaskitoPipe {
  transform(value, maskitoOptions) {
    return maskitoTransform(String(value ?? ''), maskitoOptions ?? MASKITO_DEFAULT_OPTIONS);
  }
  static {
    this.ɵfac = function MaskitoPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MaskitoPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "maskito",
      type: MaskitoPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'maskito'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MaskitoDirective, MaskitoPipe };
