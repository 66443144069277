import { orderBy, where } from '@angular/fire/firestore';
import type { Entity } from '@context/shared/types/common';
import {
	PageSize,
	PaginatedPageDetails,
	SortDir,
} from '@context/shared/types/pagination';
import type {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	QueryConstraint,
} from 'firebase/firestore';

export type PaginatedPageItem<T extends Partial<Entity>> = {
	data: T;
	ref: DocumentReference<T, DocumentData>;
	id: string;
};

export type PaginationPage<T extends Partial<Entity>> = {
	items: PaginatedPageItem<T>[];
	last?: DocumentSnapshot<T, DocumentData> | null;
};

export type PaginatedOptions<T extends Partial<Entity>> = {
	sortBy: keyof T;
	sortDir: SortDir;
	readonly constraints: QueryConstraint[];

	/**
	 * some queries require a parent to reference the collection of
	 */
	parentRef?: DocumentReference | null;
};

export interface PaginatedObjectType<T extends Partial<Entity>>
	extends PaginatedOptions<T> {
	pages: { [page: number]: PaginationPage<T> };
	currentPage: number;
	pageSize: PageSize;
	totalElements: number | null;
}

export class PaginatedObject<T extends Partial<Entity>>
	implements PaginatedObjectType<T>
{
	/**
	 * Organizations the items based on the page they are being loaded in
	 * as. This way we can quickly reference the page if the user changes to
	 * a page that has already been loaded. The `last` property is needed at
	 * the page root for quick access when querying the next page with `startAfter`
	 *
	 * @example
	 * pages: {
	 *		0: { items: [{ id: string, data: ..., ref: ...}], last: DocumentSnapshot }
	 *		1: { items: [{ id: string, data: ..., ref: ...}], last: DocumentSnapshot }
	 * }
	 */
	pages: { [page: number]: PaginationPage<T> } = {};

	currentPage = 0;
	pageSize: PageSize = 10;

	totalElements: number | null = null;

	sortBy: keyof T = 'createdAt';
	sortDir: SortDir = 'desc';

	parentRef: DocumentReference | null = null;

	includeDeleted = false;

	private _constraints: QueryConstraint[] = [];

	constructor(
		paginated?: Partial<PaginatedObjectType<T>>,
		options?: { includeDeleted: boolean },
	) {
		this.includeDeleted = options?.includeDeleted ?? false;
		Object.assign(this, PaginatedDefault, paginated);
	}

	get previousPage() {
		if (this.currentPage === 0) return null;
		const prevPage = this.pages[this.currentPage - 1];
		return prevPage ?? null;
	}

	get page() {
		if (!this.pages[this.currentPage]) return null;
		return this.pages[this.currentPage];
	}

	get items() {
		if (!this.page) return [];
		return this.page.items;
	}

	get pageCount() {
		if (!this.totalElements) return 0;
		if (!this.pageSize) return 1;
		return Math.ceil(this.totalElements / this.pageSize);
	}

	get pageDetails(): PaginatedPageDetails | null {
		if (!this.totalElements) return null;
		if (!this.pageSize)
			return {
				start: 1,
				end: this.totalElements,
				total: this.totalElements,
			};

		const start = this.currentPage * this.pageSize + 1;
		const end = Math.min(
			(this.currentPage + 1) * this.pageSize,
			this.totalElements,
		);

		return { start, end, total: this.totalElements };
	}

	get constraints() {
		return [...this.defaultConstraints, ...this._constraints];
	}

	private set constraints(value: QueryConstraint[]) {
		this._constraints = value;
	}

	private get defaultConstraints() {
		const value: QueryConstraint[] = [];
		if (this.sortBy && this.sortDir) {
			value.push(orderBy(this.sortBy as string, this.sortDir));
		}

		if (!this.includeDeleted) {
			value.push(where('deletedAt', '==', null));
		}

		return value;
	}

	update(paginated: Partial<PaginatedObjectType<T>>) {
		Object.assign(this, paginated);
		return this;
	}

	reset() {
		this.pages = {};
		this.currentPage = 0;
		this.totalElements = 0;
	}
}

export const PaginatedDefault: Partial<PaginatedObjectType<Partial<Entity>>> = {
	pages: {},
	currentPage: 0,
	pageSize: 10,
	totalElements: null,
	sortBy: 'createdAt',
	sortDir: 'asc',
	constraints: [],
};
