<section tuiContent>
	@if (form) {
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<label tuiLabel>
				{{ 'data.email' | transloco }}*

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="email"
						tuiTextfield
						type="email"
						autocomplete="email"
					/>
				</tui-textfield>

				<tui-error
					formControlName="email"
					[error]="[] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'data.name' | transloco }}

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="name"
						tuiTextfield
						autocomplete="name"
					/>
				</tui-textfield>

				<tui-error
					formControlName="name"
					[error]="['required'] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'data.phone' | transloco }}

				<tui-input-phone
					tuiTextfieldSize="m"
					countryCode="+1"
					phoneMaskAfterCountryCode="(###) ###-####"
					[tuiTextfieldLabelOutside]="true"
					formControlName="tel"
				>
					<input tuiTextfieldLegacy autocomplete="tel" />
				</tui-input-phone>

				<tui-error
					formControlName="tel"
					[error]="['required'] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'data.message' | transloco }}

				<tui-textarea
					tuiTextfieldSize="m"
					formControlName="message"
					[tuiTextfieldLabelOutside]="true"
					[expandable]="true"
					[maxLength]="maxLength"
				></tui-textarea>
			</label>
		</form>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="accent" [loading]="busy" (click)="onSubmit()">
		{{ 'action.send' | transloco }}
	</button>
</footer>
