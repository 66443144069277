import { Content } from '@context/shared/types/common';
import {
	ContentMedia,
	getPreferredThumbnail,
} from '@context/shared/types/media';
import {
	getName,
	getUserStatus,
	User
} from '@context/shared/types/user';
import {
	AlgoliaGlobalContentType,
	AlgoliaGlobalMediaType,
	AlgoliaGlobalUserType,
} from '../models';
import { AlgoliaGlobalDataType } from '../types/algolia-global-types.interface';

export const algoliaGlobalContentConverter = (
	content: Content,
	organization: string,
): AlgoliaGlobalContentType => ({
	name: content.name,
	type: content.type,
	organization,
	metadata: content.description
		? { description: content.description }
		: undefined,
	thumbnail: content.thumbnails.length
		? getPreferredThumbnail(content.thumbnails, {
				resolution: 256,
			})?.storagePath
		: undefined,
	_tags: content.shared.map((s) => access('user', s.id)),
});

export const algoliaGlobalUserConverter = (
	user: User,
	organization: string,
): AlgoliaGlobalUserType => ({
	name: getName(user),
	type: 'user',
	organization,
	metadata: {
		role: user.role,
		email: user.email,
		status: getUserStatus(user),
	},
	thumbnail: user.thumbnailUrl ?? undefined,
	// we do not want supers to be accessible by everyone
	_tags: user.role === 'super' ? [] : [access('all')],
});

export const algoliaGlobalMediaConverter = (
	media: ContentMedia,
	parent: Content,
	organization: string,
): AlgoliaGlobalMediaType => ({
	name: media.name,
	type: 'media',
	organization,
	metadata: {
		parentId: parent.id,
		parentName: parent.name,
		mediaType: media.type,
		logId: media.log?.id ?? null,
	},
	thumbnail: media.thumbnailPath ?? undefined,
	_tags: parent.shared.map((s) => access('user', s.id)),
});

/**
 * @param type essentially the method that the user could have access to this entity
 * @param id the id of the id granting access
 * @returns a formatted string to apply to the algolia object tags array for access
 */
export const access = (type: AlgoliaGlobalDataType | 'all', id?: string) => {
	const base = `access_${type}`;
	if (id && type !== 'all') return `${base}-${id}`;
	return base;
};
