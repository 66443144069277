import { Inject, Injectable } from '@angular/core';
import {
	deleteObject,
	getBlob,
	getDownloadURL,
	ref,
	Storage,
} from '@angular/fire/storage';
import { ORGANIZATION_ID } from '@context/frontend/common';
import {
	ImageCompressionOptions,
	PresetCompression,
	PresetCompressions,
} from '@context/shared/types/common';
import { isImage } from '@context/shared/types/media';
import imageCompression from 'browser-image-compression';
import { StorageReference, uploadBytes, UploadResult } from 'firebase/storage';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';

// config is the organization's additional configurations
type EntityType = 'users' | 'config' | 'files' | 'folders' | 'media';

@Injectable({ providedIn: 'root' })
export class StorageService {
	readonly imageCache = new Map<string, string>();

	constructor(
		private readonly storage: Storage,
		@Inject(ORGANIZATION_ID)
		private readonly organizationId: BehaviorSubject<string | null>,
	) {}

	/**
	 * Uploads the provided file to the angular firebase storage and returns a task to follow
	 * @param file The file to upload to the firebase storage
	 * @param options additional options to further configure how the file will be uploaded
	 * @returns A task observable to follow the progress
	 */
	async uploadFile(
		file: File,
		options: {
			id?: string;
			entityType: EntityType;
			compress?: boolean;
			compressPreset?: PresetCompression;
			compressionOptions?: ImageCompressionOptions;
		},
	): Promise<UploadResult> {
		if (isImage(file)) {
			const compress = options.compress ?? true;
			if (compress) {
				const compressionOptions =
					options.compressionOptions ??
					this.getDefaultCompressionOptions(options.compressPreset);

				file = await this.compressImage(
					file,
					compressionOptions ?? undefined,
				);
			}
		}

		const { entityType, id } = options;
		const path = this.path(id ?? v4(), this.getExtension(file), entityType);

		const storageRef = ref(this.storage, path);
		return uploadBytes(storageRef, file, {}).then((res) => {
			// this is incase the image was updated after it was already cached since we
			// use the entity id for
			this.imageCache.delete(res.metadata.fullPath);
			return res;
		});
	}

	getDownloadUrl = (
		ref: StorageReference,
		options?: { fromCache?: boolean },
	) => {
		const fromCache = options?.fromCache ?? true;

		if (fromCache && this.imageCache.has(ref.fullPath)) {
			return Promise.resolve(this.imageCache.get(ref.fullPath) as string);
		}

		return getDownloadURL(ref).then((url) => {
			this.imageCache.set(ref.fullPath, url);
			return url;
		});
	};

	getBlob = (ref: StorageReference) => getBlob(ref);

	getReference = (fullPath: string) => ref(this.storage, fullPath);

	getDefaultCompressionOptions = (preset?: PresetCompression) =>
		preset ? PresetCompressions[preset] : undefined;

	/**
	 * Deletes the file located at the provided url if located in firebase
	 * @param url the url to where the file is located
	 * @returns observable task of the deletion
	 */
	deleteFromURL = (url: string) => deleteObject(ref(this.storage, url));

	/**
	 * Deletes the file located at the provided path
	 */
	deleteFromPath = (path: string) => deleteObject(this.getReference(path));

	/**
	 * Determines what the extension of the file is and returns it
	 * @param file The file to get the type from
	 * @returns the extension of the file
	 */
	getExtension(file: File) {
		if (file.type) {
			return `.${file.type.split('/')[1]}`;
		} else if (file.name) {
			return `.${file.name.split('.')[1]}`;
		}
		return '.txt';
	}

	/**
	 * Compresses the provided image to a more performant storable file.
	 * Prevents users from uploading a very large image into the database.
	 * @param file The file to compress
	 * @returns The newly compressed file
	 */
	compressImage(file: File, options?: ImageCompressionOptions) {
		return imageCompression(file, {
			...PresetCompressions.max,
			...options,
		});
	}

	/**
	 * @param fileName the name of the file to append to back of the path
	 * @param ext the extension of the file to append to the file name
	 * @param id optional id based on where the path should organize the file
	 */
	path(fileName: string, ext: string, entityType?: EntityType) {
		const organizationId = this.organizationId?.value;
		if (!organizationId)
			throw new Error('No organization provided to determine path');

		let value = `/organizations/${organizationId}`;
		if (entityType) value += `/${entityType}`;
		return `${value}/${fileName}${ext}`;
	}
}
