<section tuiContent>
	@for (item of context.data.items; track item) {
		<button
			tuiButton
			type="button"
			class="item"
			appearance="ghost"
			[class.active]="context.data.activeItem === item"
			[iconStart]="context.data.activeItem === item ? '@tui.check' : ''"
			(click)="onClick(item)"
		>
			<span>
				{{ item.label | transloco }}
			</span>
		</button>
	}
</section>
