import { Component } from '@angular/core';
import { TuiLoader, tuiLoaderOptionsProvider } from '@taiga-ui/core';

@Component({
	selector: 'ctx-table-loader',
	standalone: true,
	imports: [TuiLoader],
	template: `<tui-loader />`,
	providers: [tuiLoaderOptionsProvider({ size: 'l' })],
	styles: `
		:host {
			padding: var(--spacing-12);
		}
	`,
})
export class TableLoaderComponent {}
