import { DocumentReference, Entity } from '../types';

export class EntityOption<T extends Entity> {
	data!: T;
	ref: DocumentReference<T> | null = null;

	constructor(data: T, ref?: DocumentReference<T> | null) {
		this.data = data;
		this.ref = ref ?? null;
	}

	protected toString() {
		return this.data.toString();
	}

	public valueOf() {
		return this.ref;
	}
}
