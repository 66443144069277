import { KeyValuePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
	DestroyService,
	DialogBase,
	DialogOptions,
} from '@context/frontend/common';
import { TablePlaceholderComponent } from '@context/frontend/table';
import { Activity } from '@context/shared/types/activity';
import { Entity } from '@context/shared/types/common';
import { getName, User } from '@context/shared/types/user';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiLoader, TuiTextfield } from '@taiga-ui/core';
import { TuiSearch } from '@taiga-ui/layout';
import { DocumentReference, getDoc } from 'firebase/firestore';
import { debounceTime, takeUntil } from 'rxjs';
import { ActivityRowComponent } from '../../components/activity-row/activity-row.component';
import { ActivityService } from '../../services';
import { getUaDevice } from '@context/frontend/native/platform';
import { PaginatedObject, PaginationPage } from '@context/frontend/pagination';

type UserActivity = Activity & { userData: User | null; label: string | null };

type ActivityGroup = {
	date: string;
	dateValue: number;
	activity: UserActivity[];
};

@Component({
	standalone: true,
	imports: [
		TuiIcon,
		TuiButton,
		TranslocoModule,
		ActivityRowComponent,
		KeyValuePipe,
		TuiSearch,
		TuiTextfield,
		ReactiveFormsModule,
		TablePlaceholderComponent,
		TuiLoader,
	],
	selector: 'ctx-view-activity-dialog',
	templateUrl: 'view-activity-dialog.component.html',
	styleUrl: 'view-activity-dialog.component.scss',
	providers: [DestroyService],
})
export class ViewActivityDialogComponent
	extends DialogBase<null, { entityRef: DocumentReference<Entity> }>
	implements OnInit
{
	static override DialogOptions = {
		label: 'navigation.view-history',
		size: getUaDevice() === 'desktop' ? 'm' : 'fullscreen',
	} as DialogOptions;

	/**
	 * string is the formattedDate to group the activities with
	 */
	totalActivityGroup: ActivityGroup[] = [];
	activityGroup: ActivityGroup[] = [];

	loading = false;

	readonly userMap = new Map<string, User>();
	readonly search = new FormGroup({ term: new FormControl('') });

	readonly activityService = inject(ActivityService);
	readonly transloco = inject(TranslocoService);
	readonly destroy$ = inject(DestroyService);

	ngOnInit() {
		this.fetchActivity();

		this.search
			.get('term')
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(250))
			.subscribe((search) => {
				this.activityGroup = this.filterActivity(search ?? '');
			});
	}

	fetchActivity() {
		this.loading = true;
		this.activityService
			.fetch(
				new PaginatedObject(
					{
						pageSize: null,
						parentRef: this.context.data.entityRef,
						sortBy: 'createdAt',
						sortDir: 'desc',
					},
					{ includeDeleted: true },
				),
			)
			.then((paginated) => this.organizationActivity(paginated.page))
			.finally(() => {
				this.loading = false;
			});
	}

	async organizationActivity(activity: PaginationPage<Activity> | null) {
		if (!activity) throw new Error('No activity loaded');

		const activityMap = new Map<string, UserActivity[]>();

		for (const item of activity.items) {
			const data = item.data;
			const userData = data.user
				? (this.userMap.get(data.user?.id) ??
					((
						await getDoc(data.user as DocumentReference<User>)
					).data() as User))
				: null;

			let label = null;
			if (userData) {
				this.userMap.set(userData?.id, userData);
				label = this.transloco.translate('activity.type.' + data.type, {
					name: getName(userData),
				});
			}

			const formattedDate = Intl.DateTimeFormat('en-US', {
				month: 'long',
				day: '2-digit',
				year: 'numeric',
			}).format(data.createdAt.toDate());

			if (activityMap.has(formattedDate)) {
				const items = activityMap.get(formattedDate) as UserActivity[];
				items.push({ ...data, userData, label });
				activityMap.set(formattedDate, items);
			} else {
				activityMap.set(formattedDate, [{ ...data, userData, label }]);
			}
		}

		this.orderActivity(activityMap);
	}

	orderActivity(activityMap: Map<string, UserActivity[]>) {
		const groups: ActivityGroup[] = [];
		activityMap.forEach((value, date) => {
			groups.push({
				date,
				dateValue: new Date(date).valueOf(),
				activity: value.sort((a, b) => {
					if (a.createdAt < b.createdAt) return 1;
					else if (a.createdAt > b.createdAt) return -1;
					return 0;
				}),
			});
		});

		this.totalActivityGroup = this.activityGroup = groups.sort((a, b) => {
			if (a.dateValue < b.dateValue) return 1;
			else if (a.dateValue > b.dateValue) return -1;
			return 0;
		});
	}

	filterActivity(search: string) {
		search = search.trim().toLowerCase();
		if (!search) return this.totalActivityGroup;

		return this.totalActivityGroup.reduce((value, current) => {
			if (current.date.toLowerCase().includes(search)) {
				value.push(current);
			} else {
				const filter = current.activity.filter((item) => {
					if (item.type?.toLowerCase().includes(search)) return true;
					if (item.label?.toLowerCase().includes(search)) return true;

					if (item.userData) {
						const name = getName(item.userData);
						if (name.toLowerCase().includes(search)) return true;
					}

					const created = item.createdAt.toDate().toLocaleString();
					if (created.includes(search)) return true;

					return false;
				});

				if (filter.length) {
					value.push({
						date: current.date,
						dateValue: current.dateValue,
						activity: filter,
					});
				}
			}

			return value;
		}, [] as ActivityGroup[]);
	}
}
