import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { DialogOptions } from '../dialogs';

@Injectable({ providedIn: 'root' })
export class DialogService {
	private readonly dialog = inject(TuiDialogService);
	private readonly transloco = inject(TranslocoService);

	open<T = undefined>(content: PolymorpheusContent, options: DialogOptions) {
		const label = options.label
			? this.transloco.translate(options.label)
			: undefined;

		return this.dialog.open<T>(
			content,
			Object.assign({}, options, { label }),
		);
	}
}
