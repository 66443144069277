import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../services';

@Pipe({
	standalone: true,
	name: 'getPathUrl',
})
export class GetPathUrlPipe implements PipeTransform {
	readonly storageService = inject(StorageService);
	readonly sanitizer = inject(DomSanitizer);

	async transform(path: string) {
		const url = await this.storageService.getDownloadUrl(
			this.storageService.getReference(path),
		);
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
