import { hasInheritedRolePermission } from '../utils';

/**
 * Omitting External for now
 */
const Roles = ['user', 'admin', 'owner', 'super'] as const;

export type Role = (typeof Roles)[number];

export const DefaultRole: Role = 'user';

/**
 * Reusable array for forms. To be used in conjunction with select components
 */
export function RoleItems(authUserRole?: Role) {
	// if no auth role is provided, return everything
	if (!authUserRole) {
		return Roles.map((role) => ({
			label: `user.roles.${role}`,
			value: role,
		}));
	}

	return Roles.filter((role) =>
		hasInheritedRolePermission(authUserRole, role),
	).map((role) => ({
		label: `user.roles.${role}`,
		value: role,
	}));
}
